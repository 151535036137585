import { Icon, IconButton, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import formatMoney from "../../helpers/numbersDot";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { initialManifest } from "../ManifestsTransfer/Graphql";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import { DELETE_MANIFEST_ENTRIES_MUTATION } from "./ManifestGraphQl";

const PREFIX = "useManifestDetails";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  barcode: `${PREFIX}-barcode`,

};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },

}));
const tableBody = (localDataKey, manifestShipment, toBeCollected) => {

  const body = [
    {
      key: "code",
      pathName: `/admin/shipments/${manifestShipment.shipment.id}`,
      tag: CellLink,
      value: manifestShipment.shipment.code,
    },
    {
      key: "status",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.status?.name,
    },
    {
      key: "customer",
      pathName: `/admin/customers/${manifestShipment?.shipment?.customer?.id}`,
      tag: CellLink,
      value: manifestShipment?.shipment?.customer?.name,
    },
    {
      key: "openable",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.openable?.code === "Y" ? (
        <Icon className={classes.iconColor}>done</Icon>
      ) : (
        <Icon color="error">close</Icon>
      ),
    },
    {
      key: "warehouseSection",
      pathName: `/admin/shipments/${manifestShipment?.warehouseSection?.id}`,
      tag: CellLink,
      value: manifestShipment?.warehouseSection?.name,
    },
    {
      key: "warehouseSections",
      pathName: `/admin/shipments/${manifestShipment?.warehouseSection?.id}`,
      tag: CellLink,
      value: manifestShipment?.shipment?.warehouseSection?.name,
    },
    {
      key: "recipientName",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.recipientName,
    },
    {
      key: "date",
      tag: FixedTableCell,
      value: dateFormatLL(manifestShipment?.shipment?.date)
    },
    {
      key: "lastTransactionDate",
      tag: FixedTableCell,
      value: dateFormatLL(manifestShipment.shipment?.lastTransaction?.date)
    },
    {
      key: "description",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.description,
    },
    {
      key: "type",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.type?.name
    },
    {
      key: "deliveredOrReturnedDate",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.deliveredOrReturnedDate
    },
    {
      key: "attempts",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.attempts
    },
    {
      key: "recipientPhone",
      tag: FixedTableCell,
      value: <> <span dir="ltr" style={{ display: "block" }}>
        {manifestShipment?.shipment?.recipientMobile}
      </span>
        <span dir="ltr">{manifestShipment?.shipment?.recipientPhone}</span>
      </>
    },
    {
      key: "senderPhone",
      tag: FixedTableCell,
      value:
        !manifestShipment?.shipment?.customer?.isMobileHidden && <>
          <span dir="ltr" style={{ display: "block" }}>
            {manifestShipment?.shipment?.senderMobile}
          </span>
          <span dir="ltr">{manifestShipment?.shipment?.senderPhone}</span>
        </>


    },
    {
      key: "sender",
      tag: FixedTableCell,
      value: <>
        <span style={{ display: "block" }} >
          {manifestShipment?.shipment?.senderName}
        </span>
        {!manifestShipment?.shipment?.customer?.isMobileHidden && <span dir="ltr" style={{ display: "block" }}>

          {manifestShipment?.shipment?.senderMobile}
        </span>}
        {!manifestShipment?.shipment?.customer?.isMobileHidden && <span dir="ltr">{manifestShipment?.shipment?.senderPhone}</span>}</>
    },
    {
      key: "recipient",
      tag: FixedTableCell,
      value: <> <span style={{ display: "block" }}>
        {manifestShipment?.shipment?.recipientName}
      </span>
        <span style={{ display: "block" }} dir="ltr">
          {manifestShipment?.shipment?.recipientMobile}
        </span>
        <span dir="ltr">{manifestShipment?.shipment?.recipientPhone}</span></>
    },
    {
      key: "recipientZone",
      pathName: `/admin/zones/${manifestShipment?.shipment?.recipientZone?.id}`,
      tag: ShipmentRegion,
      value: manifestShipment?.shipment?.recipientZone?.name,
      subValue: manifestShipment?.shipment?.recipientSubzone?.name,
    },
    {
      key: "senderZone",
      pathName: `/admin/zones/${manifestShipment?.shipment?.senderZone?.id}`,
      tag: ShipmentRegion,
      value: manifestShipment?.shipment?.senderZone?.name,
      subValue: manifestShipment?.shipment?.senderSubzone?.name,
    },
    {
      key: "recipientAddress",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.recipientAddress,
    },
    {
      key: "note",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.notes,
    },
    {
      key: "senderAddress",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.senderAddress,
    },
    {
      key: "paymentType",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.paymentType?.name,
    },
    {
      key: "deliveryType",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.deliveryType?.name ?? manifestShipment.shipment?.status?.name,
    },
    {
      key: "weight",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.weight,
    },
    {
      key: "piecesCount",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.piecesCount,
    },
    {
      key: "senderName",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.senderName,
    },

    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.collectedAmount),
    },


    {
      key: "originBranch",
      pathName: `/admin/branches/${manifestShipment.shipment?.originBranch?.id}`,
      tag: CellLink,
      value: manifestShipment.shipment?.originBranch?.name
    },

    {
      key: "status",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.status?.name,
    },
    {
      key: "deliveryType",
      tag: FixedTableCell,
      value: manifestShipment.shipment?.deliveryType?.name ??
        manifestShipment.shipment?.status?.name,
    },
    {
      key: "barcode",
      tag: FixedTableCell,
      auto: true,
      value: <div className={classes.barcode}>
        *{manifestShipment?.shipment?.code}*
      </div>,
    },
    {
      key: "totalAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.shipment?.type?.code === "RTS" ? 0 : manifestShipment?.shipment?.totalAmount),
    },
    {
      key: "deliveredAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.deliveredAmount),
    },
    {
      key: "collectedFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.collectedFees),
    },
    {
      key: "returningDueFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.returningDueFees),
    },
    {
      key: "collectedAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.collectedAmount),
    },
    {
      key: "price",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.shipment?.price),
    },
    {
      key: "paidAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.paidAmount),
    },
    {
      key: "customerDueAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.paidAmount),
    },
    {
      key: "pickupAmount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.pickupAmount),
    },
    {
      key: "deliveryFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.deliveryFees),
    },
    {
      key: "postFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.postFees),
    },
    {
      key: "tax",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.tax),
    },
    {
      key: "allFees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment.allFees),
    },
    {
      key: "amount",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.shipment?.amount),
    },
    {
      key: "amounts",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.shipment?.amount),
    },
    {
      key: "notes",
      tag: FixedTableCell,
      value: manifestShipment?.notes,
    },
    {
      key: "deliveryDate",
      tag: FixedTableCell,
      value: manifestShipment?.deliveryDate,
    },
    {
      key: "deliveryDateshipment",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.deliveryDate,
    },
    {
      key: "transactions",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.transactions?.find((i) => i.returnType !== null)?.returnType?.name,
    }
    ,

    {
      key: "returnTypes",
      tag: FixedTableCell,
      value: manifestShipment?.returnType?.name,
    },
    {
      key: "cancellationReason",
      tag: FixedTableCell,
      value: manifestShipment?.cancellationReason?.name,
    },
    {
      key: "cancellationReasonshipment",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.cancellationReason?.name,
    },
    {
      key: "fees",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.fees),
    },
    {
      key: "returnedValue",
      tag: FixedTableCell,
      value: formatMoney(manifestShipment?.shipment?.returnedValue),
    },
    {
      key: "returnType",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.returnType?.name,
    },
    {
      key: "refNumber",
      tag: FixedTableCell,
      value: manifestShipment?.shipment?.refNumber,
    },

  ]
  let selected = []
  localDataKey.map((e) => {
    const selectBody = body.filter((ele) => ele.key === e)
    if (selectBody.length > 0) {
      selected.push(selectBody[0])
    }
    return ''
  })
  return selected
}
const tablesum = (localDataKey, TotalData) => {
  const sumTable = [
    // {
    //   key: "collectedAmount",
    //   value: TotalData.sumEntries,

    // },
    {
      key: "weight",
      value: TotalData?.sumEntries?.weight,

    },
    {
      key: "piecesCount",
      value: TotalData?.sumEntries?.piecesCount,

    },
    {
      key: "totalAmount",
      value: TotalData?.sumEntries?.totalAmount,

    },
    // {
    //   key: "collectedFees",
    //   value: TotalData.sumEntries,
    // },
    {
      key: "returningDueFees",
      value: TotalData?.sumEntries?.returningDueFees,
    },
    // {
    //   key: "paidAmount",
    //   value: TotalData.sumEntries,
    // },
    {
      key: "price",
      value: TotalData?.sumEntries?.price,
    },
    // {
    //   key: "customerDueAmount",
    //   value: TotalData.sumEntries,
    // },
    {
      key: "deliveredAmount",
      value: TotalData?.sumEntries?.deliveredAmount,
    },
    // {
    //   key: "deliveryFees",
    //   value: TotalData.sumEntries,
    // },
    // {
    //   key: "postFees",
    //   value: TotalData.sumEntries,
    // },
    // {
    //   key: "tax",
    //   value: TotalData.sumEntries,
    // },
    // {
    //   key: "allFees",
    //   value: TotalData.sumEntries,
    // },
    {
      key: "pickupAmount",
      value: TotalData?.sumEntries?.pickupAmount,
    },
    {
      key: "amount",
      value: TotalData?.sumEntries?.amount,
    },
    {
      key: "fees",
      value: TotalData?.sumEntries?.fees,
    },
    {
      key: "returnedValue",
      value: TotalData?.sumEntries?.returnedValue,
    },
    // {
    //   key: "amounts",
    //   value: TotalData.sumEntries,
    // },

  ]
  let selected = {}
  localDataKey.map((e, index) => {
    const selectBody = sumTable.filter((ele, i) => ele.key === (e === "amounts" ? "amount" : e))
    // selected.push(selectBody[0] ? selectBody[0].value : "")
    selected[selectBody[0] ? selectBody[0].key : `${index}key`] = selectBody[0] ? selectBody[0].value : " "
    return ''
  })

  return selected
}
const tableHead = (localDataKey) => {
  const headCell = [
    {
      key: "paymentType",
      value: "paymentType"
    },
    {
      key: "returnTypes",
      value: "returnType",
    },
    {
      key: "returnType",
      value: "returnType",
    },
    {
      key: "returnedValue",
      value: "returnedValue",
    },
    {
      key: "cancellationReason",
      value: "CancellationReasonName",
    },
    {
      key: "cancellationReasonshipment",
      value: "CancellationReasonName",
    },
    {
      key: "attempts",
      value: "attempts",
    },
    {
      key: "fees",
      value: "feesAmount",
    },
    {
      key: "code",
      value: "code",
    },
    {
      key: "senderAddress",
      value: "senderAddress",
    },
    {
      key: "senderPhone",
      value: "senderPhone",
    },
    {
      key: "transactions",
      value: "returnType",
    },
    {
      key: "date",
      value: "date"
    },
    {
      key: "deliveredOrReturnedDate",
      value: "deliveryCanclingDate"
    },
    {
      key: "weight",
      value: "weight",
    },
    {
      key: "customer",
      value: "customer",
    },
    {
      key: "piecesCount",
      value: "pieceCount",
    },
    {
      key: "status",
      value: "shipmentStatus",
    },
    {
      key: "recipientName",
      value: "recipient",
    },
    {
      key: "recipientZone",
      value: "destination",
    },
    {
      key: "senderZone",
      value: "source",
    },
    {
      key: "originBranch",
      value: "originBranch",
    },
    {
      key: "collectedAmount",
      value: "collectedAmount",
    },

    {
      key: "description",
      value: "packageDescription",
    },

    {
      key: "recipientAddress",
      value: "recipientAddress",
    },
    {
      key: "senderName",
      value: "senderName",
    },
    {
      key: "recipient",
      value: "recipient",
    },
    {
      key: "sender",
      value: "senderName",
    },
    {
      key: "totalAmount",
      value: "total",
    },

    {
      key: "collectedAmount",
      value: "collectedAmount",
    },

    {
      key: "returningDueFees",
      value: "returningDueFees",
    },
    {
      key: "deliveryType",
      value: "deliveryType",
    },
    {
      key: "collectedFees",
      value: "collectedFees",
    },
    {
      key: "deliveredAmount",
      value: "deliveredAmount",
    },
    {
      key: "commission",
      value: "commission",
    },
    {
      key: "NetAmount",
      value: "NetAmount",
    },
    {
      key: "paidAmount",
      value: "deservedAmount",

    }, {
      key: "recipientPhone",
      value: "recipientPhone",
    }
    , {
      key: "customerDueAmount",
      value: "customerDueAmount",
    }
    , {
      key: "amount",
      value: "customerDueAmount",
    }
    , {
      key: "amounts",
      value: "totalPackagePrice",
    },
    {
      key: "deliveryFees",
      value: "deliveryFees",
    },
    {
      key: "warehouseSection",
      value: "wareHouse",
    },
    {
      key: "lastTransactionDate",
      value: "lastTransactionDate",
    },
    {
      key: "warehouseSections",
      value: "wareHouse",
    },
    {
      key: "postFees",
      value: "postFees",
    },
    {
      key: "tax",
      value: "tax",
    },
    {
      key: "allFees",
      value: "returningDueFees",
    },
    {
      key: "pickupAmount",
      value: "amountMustBeCollected",
    },
    {
      key: "price",
      value: "packagePrice",
    },
    {
      key: "type",
      value: "packageType",
    },
    {
      key: "openable",
      value: "packageOpen",
    },
    {
      key: "deliveryDate",
      value: "redeliveryDate",
    },
    {
      key: "deliveryDateshipment",
      value: "redeliveryDate",
    },
    {
      key: "note",
      value: "notes",
    },
    {
      key: "notes",
      value: "notes",
    },
    {
      key: "barcode",
      value: "barcode",
    },
    {
      key: "refNumber",
      value: "refNumber",
    },

  ]
  let selected = []
  localDataKey.map((e) => {
    const selectBody = headCell.filter((ele) => ele.key === e)
    if (selectBody.length > 0) {
      selected.push(selectBody[0].value)
    }
    return ''
  })
  return selected
}
const useManifestDetails = ({
  type,
  typeData,
  approved,
  manifestId,
  refetchShipmentDataQuery,
  setShipmentData: removeShipment,

  pickedShipment,
  saveManifestLoad,
}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [deleteManifestEntries, { loading: deleteManifestEntriesLoading }] = useMutation(
    gql`
      ${DELETE_MANIFEST_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const localDataKey = localStorage.getItem(`shipmentManifest${type}`) ? JSON.parse(localStorage.getItem(`shipmentManifest${type}`)) : initialManifest[type]

  const deleteShipment = (manifestShipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={approved || saveManifestLoad}
        onClick={() => {
          removeShipment(prev => {
            const shipments = prev.pickedShipment.map(e => ({
              ...e,
              shipment: {
                ...e.shipment,
                pendingDelete: e.shipment.id === manifestShipment.shipment.id ? true : e.shipment.pendingDelete
              }
            }));
            return {
              ...prev,
              pickedShipment: shipments
            }
          })
          deleteManifestEntries({
            variables: {
              input: {
                manifestId: manifestId,
                shipmentIds: [manifestShipment.shipment.id]
              }
            }
          }).then(() => {
            refetchShipmentDataQuery()
            enqueueSnackbar(t("successfullyDeletedRecord"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          }).catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          })
        }}
      >
        {deleteManifestEntriesLoading && manifestShipment.shipment?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );

  /* --------------------------------- PKM --------------------------------- */
  const shipmentTableBody = (manifestShipment, index) => {
    // let toBeCollected
    // if (manifestShipment?.shipment?.type?.code === "RTS") {
    //   toBeCollected = manifestShipment?.shipment?.totalAmount
    // } else if (manifestShipment?.shipment?.paymentType?.code === "CASH") {
    //   toBeCollected = manifestShipment?.shipment?.allDueFees
    // } else {
    //   toBeCollected = 0
    // }

    let table = tableBody(localDataKey, manifestShipment)
    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        {table.map((ele, i) => {
          return ele.subValue ? <ele.tag pathname={ele.pathName ? ele.pathName : ""} key={ele.key} zone={ele.value}
            subzone={ele.subValue} /> :
            <ele.tag pathname={ele.pathName ? ele.pathName : ""} key={ele.key} auto={ele?.auto}>{ele.value}</ele.tag>
        })}
        {removeShipment && deleteShipment(manifestShipment)}
      </StyledTableRow>
    );
  };


  const tableHeadCell = () => {
    if (localDataKey) {
      let table = tableHead(localDataKey)
      const removed = removeShipment ? "" : null;
      return ["#", ...table, removed]
    }
  }
  const TableCell = tableHeadCell()
  const tableSumArr = () => {
    if (localDataKey) {
      let table = tablesum(localDataKey, typeData)

      return table
    }
  }
  const tableSum = tableSumArr()




  /* --------------------------------- Manifests Object --------------------------------- */

  const manifestsObject = {
    PKM: {
      id: "1",
      customer: true,
      label: t("pickupLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    RITS: {
      id: "3",
      sumTableNumber: null,
      customer: false,
      label: t("receivedInStore"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    PRP: {
      id: "7",
      sumTableNumber: null,
      customer: false,
      label: t("packagingList"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    UPKBL: {
      id: "14",
      customer: false,
      label: t("unpackShipmentsLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    UPKBD: {
      id: "15",
      customer: false,
      label: t("shipmentsReadyToUnpackLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    BMT: {
      id: "4",
      customer: false,
      label: t("branchTransfareTo"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    BMR: {
      id: "5",
      sumTableNumber: null,
      customer: false,
      label: t("branchTransfareFrom"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    OTD: {
      id: "6",
      customer: false,
      label: t("outForDeliveryLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
        // Object.entries(tableTotal).forEach(([k, v]) => {
        //   if (shipment.shipment?.type?.code === "RTS" && k === "totalAmount") {
        //     shipment.shipment[k] = 0;
        //   }

        //   tableTotal[k] += shipment.shipment[k] ?? " ";
        // });
      },
    },
    STD: {
      id: "6",
      customer: false,
      label: t("outForDeliveryLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
        // Object.entries(tableTotal).forEach(([k, v]) => {
        //   if (shipment.shipment?.type?.code === "RTS" && k === "totalAmount") {
        //     shipment.shipment[k] = 0;
        //   }

        //   tableTotal[k] += shipment.shipment[k] ?? " ";
        // });
      },
    },

    DEX: {
      id: "8",
      sumTableNumber: null,
      customer: false,
      label: t("deliveryException"),
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    HTR: {
      id: "9",
      sumTableNumber: null,
      customer: false,
      label: t("retryDeliveryLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    RCV: {
      id: "9",
      sumTableNumber: null,
      customer: false,
      label: t("retryDeliveryLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    RTS: {
      id: "10",
      sumTableNumber: (shipment, tableTotal) => {
        // Object.entries(tableTotal).forEach(([k, v]) => {
        //   tableTotal[k] += shipment[k] ?? " ";
        // });
      },
      customer: false,
      label: t("returnToCustomer"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    OTR: {
      id: "11",
      sumTableNumber: null,
      customer: true,
      label: t("returnDelivery"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sum: false,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
    RTRN: {
      id: "12",
      sumTableNumber: null,
      customer: true,
      label: t("returnHandlingLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: tableSum,
      sumTableNumber: (shipment, tableTotal) => {
      },
    },
  };

  return {
    details: manifestsObject[type],
  };
};

export default useManifestDetails;
