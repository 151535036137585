import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";
import {
  Badge,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  AttachMoney,
  Clear,
  Done,
  DoneAll,
  Print,
} from "@mui/icons-material";
import { Alert } from "@mui/material";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  APPROVE_PAYMENT_MUTATION,
  CUSTOMER_ID,
  GL_APPROVE_PAYMENT_MUTATION,
  PAYMENT_BY_ID_QUERY,
  PAYMENT_VIEW_SHIPMENT_DATA,
  SAVE_PAYMENT_MUTATION,
  PAYMENT_VIEW_CONCIERGE_DATA,
  PAYMENT_VIEW_PICKUP_DATA
} from "./PaymentListQuary";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_REPRESENTATIVE_DROPDOWN,
  LIST_SAFES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
// import MUIDate from "../HOC/MUI/MUIDate";
import usePaymentsDetails, { CustomerPaymentMethod } from "./paymentDetails";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import usePaymentPickupsDetails from "./paymentPickupsDetails";
import TitleAppBar from "../../Layout/TitleAppBar";
// import BreadcrumbsWidget from "../../Layout/Breadcrumbs";
import LongMenu from "../../Layout/MenuAppBar";
import UploadFile from "../HOC/MUI/UploadFile";
import config from "../../config.json";

import { Globals } from "../HOC/Classes/Globals";
import useConciergeRequestsDetails from "./ConciergeRequestsDetails";
import FormTabs from "./Components/FormTaps";


const PREFIX = "PaymentForm";

const classes = {
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  shipmentList: `${PREFIX}-shipmentList`,
  button: `${PREFIX}-button`,
  glApprovedButton: `${PREFIX}-glApprovedButton`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,
  titles: `${PREFIX}-titles`,
  root: `${PREFIX}-root`,
  addIcon: `${PREFIX}-addIcon`,
  errorColor: `${PREFIX}-errorColor`,
  overlay: `${PREFIX}-overlay`,
  pagination: `${PREFIX}-pagination`,
  totalPaper: `${PREFIX}-totalPaper`,
  totalTitle: `${PREFIX}-totalTitle`,
  main: `${PREFIX}-main`,
  alertMessage: `${PREFIX}-alertMessage`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },
  [`& .${classes.totalPaper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    // minHeight: "327px",
    gridAutoRows: "max-content",
  },
  [`& .${classes.totalTitle}`]: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    // fontSize:
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.glApprovedButton}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },

  [`& .${classes.toolbar}`]: {
    backgroundColor: "#fff",
    border: "solid 1px #eee",
    zIndex: "1300",
    justify: "space-between",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.root}`]: {
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },

  [`& .${classes.alertMessage}`]: {
    flexGrow: 1,
    overflow: "hidden",
    alignItems: "center"
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

/////////////////DIALOG///////////////
const dateTimeFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");
const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD");
const PaymentForm = (props) => {
  const { t } = useTranslation();

  const [pathURL, setPathURL] = useState(props.match.path)

  const formType = props.match.params.type?.toUpperCase();

  const [paymentType, setPaymentType] = useState(formType ? formType : null);

  const theme = useTheme();
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    representative: null,
    safe: null,
    deliveryAgent: null,
    transactionType: null,
    images: []
  });
  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedShipment: [],
    noData: true
  });
  const [conciergeRequestsData, setConciergeRequestsData] = useState({
    page: 0,
    total: 0,
    noData: true,
    rowsPerPage: config.app.pageSize,
    pickedConciergeRequests: []
  });
  const [pickupData, setPickupData] = useState({
    page: 0,
    total: 0,
    noData: true,
    rowsPerPage: config.app.pageSize,
    pickedPickup: []
  });

  const [disableForm, setDisableForm] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
    glApprove: false,
    data: null
  });
  const [paymentData, setPaymentData] = useState();
  const [previewImage, setPreviewImage] = useState(null);

  const [fieldsState, setfieldsState] = useState({
    createdAt: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();


  const {
    formState: { errors },
    handleSubmit,
    setError,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm();

  const paymentId = props.match.params.id;

  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const [savePaymentMutation, { loading: savePaymentLoad }] = useMutation(
    gql`
      ${SAVE_PAYMENT_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setPaymentData(data["savePayment"]);
      },
    }
  );


  const [approvePaymentMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_PAYMENT_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setPaymentData(data["approvePayment"]),
    }
  );

  const [glApprovePaymentMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE_PAYMENT_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setPaymentData(data["glApprovePayment"]),
    }
  );
  const ALLOWED_CONCIERGE = Globals.settings.allowedCustomerTypes.includes("CONCIERGE")
  const ALLOWED_PICKUP = Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT")
  const ALLOWED_SHIPMENT = Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT")
  const paymentQueryBody = PAYMENT_BY_ID_QUERY(ALLOWED_CONCIERGE, ALLOWED_SHIPMENT, ALLOWED_PICKUP);
  const { data: paymentByIdQuery, loading: paymentByIdLoad, refetch: paymentRefetch } = useQuery(
    gql`
      ${paymentQueryBody.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !paymentId,
      variables: {
        id: paymentId ? parseInt(paymentId) : watch("id"),
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        setPaymentType(payment?.type?.code);

        setPaymentData(payment);
        setValue("id", parseInt(payment?.id));
        setValue("code", payment?.code);
        setValue("date", payment?.date);
        payment?.notes && setValue("notes", payment?.notes);

        setfieldsState((prev) => ({
          ...prev,
          createdAt: payment?.date,
        }));
        const pathIds = []
        payment.images.map(ele => pathIds.push(ele.path))
        setAutocompleteValues({
          branch: payment.branch,
          ...(payment?.customer && {
            customer: payment?.customer,
          }),
          ...(payment?.representative && {
            representative: payment?.representative,
          }),
          ...(payment?.deliveryAgent && {
            deliveryAgent: payment?.deliveryAgent,
          }),
          safe: payment.safe,
          transactionType: payment.transactionType,
          images: pathIds
        });

        if (ALLOWED_SHIPMENT) {
          setShipmentData(prev => ({
            ...prev,
            total: payment.entriesShipment.paginatorInfo.total
          }))
          shipmentData.noData && getShipmentData()
        }

        if (ALLOWED_CONCIERGE) {
          setConciergeRequestsData(prev => ({
            ...prev,
            total: payment.entriesConcierge.paginatorInfo.total
          }))
        }
        if (ALLOWED_PICKUP) {
          setPickupData(prev => ({
            ...prev,
            total: payment.entriesPickup.paginatorInfo.total
          }))
        }
        setDisableForm(true);
      },
    }
  );

  const [getShipmentData, { loading: loadingShipmentData, refetch: refetchShipmentDataQuery }] = useLazyQuery(
    gql`
        ${PAYMENT_VIEW_SHIPMENT_DATA().query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(watch("id")),
        typeCode: "SHIPMENT",
        page: shipmentData.page + 1,
        first: shipmentData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        const shipments = payment.entries.data.map(e => {
          return {
            ...e,
            shipment: {
              ...e.shipment,
              pendingDelete: false
            }
          };
        });
        setShipmentData(prev => {
          return {
            ...prev,
            pickedShipment: shipments,
            noData: false,
            total: payment.entries.paginatorInfo.total
          }
        })
        paymentRefetch()
      },
    }
  );

  const [getConciergeData, { loading: loadingConciergeData, refetch: refetchConciergeDataQuery }] = useLazyQuery(
    gql`
        ${PAYMENT_VIEW_CONCIERGE_DATA.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(watch("id")),
        typeCode: "CONCIERGE_REQUEST",
        page: conciergeRequestsData.page + 1,
        first: conciergeRequestsData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        const conciergeRequests = payment.entries.data.map(e => {
          return {
            ...e,
            conciergeRequest: {
              ...e.conciergeRequest,
              pendingDelete: false
            }
          };
        });
        setConciergeRequestsData(prev => {
          return {
            ...prev,
            total: payment.entries.paginatorInfo.total,
            noData: false,
            pickedConciergeRequests: conciergeRequests
          }
        })
        paymentRefetch()
      },
    }
  );

  const [getPickupData, { loading: loadingPickupData, refetch: refetchPickupDataQuery }] = useLazyQuery(
    gql`
        ${PAYMENT_VIEW_PICKUP_DATA.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(watch("id")),
        typeCode: "PICKUP",
        page: pickupData.page + 1,
        first: pickupData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        const pickups = payment.entries.data.map(e => {
          return {
            ...e,
            pickup: {
              ...e.pickup,
              pendingDelete: false
            }
          };
        });
        setPickupData(prev => {
          return {
            ...prev,
            total: payment.entries.paginatorInfo.total,
            noData: false,
            pickedPickup: pickups
          }
        })
        paymentRefetch()
      },
    }
  );
  const { data: customerData } = useQuery(
    gql`
      ${CUSTOMER_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("customerId") || paymentType === "DLVBY",
      variables: {
        id: parseInt(watch("customerId")),
      },
    }
  );

  const { details: paymentDetails } = usePaymentsDetails({
    typeData: paymentData,
    type: paymentType,
    approved: !paymentData?.approved,
    setShipmentData,
    refetchShipmentDataQuery,
  });
  const { details: paymentPickupsDetails } = usePaymentPickupsDetails({
    typeData: paymentData,
    type: paymentType,
    approved: !paymentData?.approved,
    setPickupData,
    refetchPickupDataQuery
  });

  const { details: conciergeRequestsDetails } = useConciergeRequestsDetails({
    typeData: paymentData,
    type: paymentType,
    approved: !paymentData?.approved,
    setConciergeRequestsData,
    refetchConciergeDataQuery,
  });


  const shipLength = shipmentData.pickedShipment.length === 0;
  const pickupLength = pickupData?.pickedPickup.length === 0;
  const conciergeRequestLength = conciergeRequestsData.pickedConciergeRequests.length === 0;
  const entriesData = shipmentData.total > 0 || pickupData.total > 0 || conciergeRequestsData.total > 0;

  ////////////////////END DRAWER////////////////
  const history = useHistory();
  const setClear = () => {
    setAutocompleteValues((prev) => ({
      ...prev,
      images: []
    }));
    setPreviewImage(null);
    setValue("fileName", "")
    setValue("images", [])
  }
  const onSubmit = (data) => {
    const formChange =
      paymentData &&
      ((data?.deliveryAgentId &&
        data?.deliveryAgentId !== parseInt(paymentData?.deliveryAgent?.id)) ||
        (data?.customerId &&
          data?.customerId !== parseInt(paymentData?.customer?.id)) ||
        (data?.branchId &&
          data?.branchId !== parseInt(paymentData?.branch?.id)));
    if (data.hasOwnProperty("fileName")) {
      delete data["fileName"];
    }
    // if (typeof (data["images"]) === "string") {
    //   delete data["images"]
    // }
    if (data["images"] === null || typeof (data["images"]) === "string") {
      delete data["images"]
    }
    if (data["representativeId"] === "") {
      delete data["representativeId"]
    }
    // const image = data?.images?.[0];
    data["date"] = dateTimeFormat(data["date"]);
    if ((shipmentData.total !== 0 || pickupData.total !== 0 || conciergeRequestsData.total !== 0) && formChange) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true, data: data }));
    } else {
      data["date"] = dateTimeFormat(data["date"]);
      savePaymentMutationFun(data)
    }
  };



  const savePaymentMutationFun = (data, paymentRefetch) => {
    const image = data?.images?.[0];
    savePaymentMutation({
      variables: {
        input: {
          ...data,
          ...(image && { images: [{ file: image }] }),
          type: paymentType,
        },
      },
    })
      .then((data) => {
        setDisableForm(true);
        setValue("code", data?.data?.savePayment?.code);
        setValue("id", parseInt(data?.data?.savePayment?.id));
        const url = history.createHref({
          pathname: `/admin/payments/${data?.data?.savePayment?.id}/edit`,
        });
        setPathURL(url)
        windowReplaceUrl(url);
        paymentRefetch && paymentRefetch()
        paymentRefetch && refetchShipmentDataQuery()
        paymentRefetch && !conciergeRequestsData.noData && refetchConciergeDataQuery()
        paymentRefetch && !pickupData.noData && refetchPickupDataQuery()
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError);
      });
  }
  const handleDialogClose = () => {
    setOpenDialog({ edit: false, open: false, approve: false });
  };

  const approvePayment = () => {
    approvePaymentMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/payments/${watch("id")}`);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const glApprovePayment = () => {
    glApprovePaymentMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };
  const onGlApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, glApprove: true }));
  };
  // const disabledAddIcon = () => {
  //   const value = manifestType === "PKM" ? ["customerId", "branchId"] : ["deliveryAgentId", "branchId"]
  //   return !value.every(val => Object.keys(queryVariables.payment).includes(val))
  // }

  function currentValueMatchingNewValues(data, fieldName) {

    const currentValue = watch(`${fieldName}`);

    if (typeof currentValue === "number") {
      const isValueValid = selectedIdIsValid(data, currentValue);

      if (!isValueValid && !disableForm) {
        setValue(`${fieldName}`, "");
      }
    }
  }

  const parseData = (data) => {
    return data;
  };

  const customerField = (type) => {
    if (type === "CUSTM") {
      return (
        <>
          <Grid sm={4} xs={12}>
            <CustomAutocomplete
              control={control}
              name={"customerId"}
              label={t("customer")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => parseData(data)}
              query={LIST_CUSTOMERS_DROPDOWN.query}
              skip={!watch("branchId")}
              variables={{
                input: {
                  withDue: "PAY",
                  branchId: {
                    value: watch("branchId"),
                    includeNull: true,
                  },
                  paymentCycleIn: dateFormat(watch("date"))
                },
              }}
              onCompleted={(data) => {
                currentValueMatchingNewValues(
                  data,
                  "customerId"
                );
              }}
              onChangeValue={(e) => {
                setValue("representativeId", "");
              }}
              defaultValue={autocompleteValues.customer}
            />
          </Grid>
          <Grid sm={4} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"representativeId"}
              label={t("financialRepresentative")}
              rules={watch("customerId") ? { required: t("fieldIsRequired") } : null}
              disabled={!watch("customerId")}
              parseData={(data) => parseData(data)}
              query={LIST_REPRESENTATIVE_DROPDOWN.query}
              skip={!watch("customerId")}
              variables={{
                input: {
                  typeCode: "FINANCE",
                  customerId: watch("customerId"),

                },
              }}
              defaultValue={autocompleteValues.representative}
            />
          </Grid>
        </>)
    } else if (type === "DLVBY") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            rules={{ required: t("fieldIsRequired") }}
            disabled={!watch("branchId")}
            onChangeValue={(e) => {
              if (e?.id !== "") {
                clearErrors("deliveryAgentId");
              }
            }}
            parseData={(data) => parseData(data)}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                withDue: "PAY",
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    }
  };

  let formBody = null;
  /////////////////////// Customer Shipments Table /////////////////////////////

  const formCondition = !formType
    ? Boolean(watch("id")) && !paymentByIdLoad
    : !paymentByIdLoad;
  formBody = (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      justifyContent={"center"}
      sx={{ width: "100%" }}
    >
      {formCondition ? (
        <Paper component={Stack} spacing={1} p={1}>
          {watch("id") && disableForm && (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !paymentData?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisableForm(false);
                        // fireMutaion([])
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {paymentData?.approved
                  ? t("updateRecordsForbidden")
                  : t("updateRecord")}
              </Alert>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {(paymentData?.approved || disableForm) && (
              <Box
                className={classes.overlay}

              >
                {/* <Typography color="textPrimary" variant="body1" >لا يمكن التعديل</Typography> */}
              </Box>
            )}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}

              // defaultValue={queryVariables?.payment?.code}
              // disabled={paymentByIdQuery?.payment?.approved || disableForm}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDateTime
                name="date"
                label={t("date")}
                control={control}
                defaultValue={paymentByIdQuery?.payment?.date}
                value={fieldsState.createdAt}
                onChange={(e) => {
                  setfieldsState((prev) => ({ ...prev, createdAt: e }))
                  // paymentType === "CUSTM" && setValue("customerId", " ");
                }}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                skip={paymentType === null}
                defaultValue={autocompleteValues.branch}
                onChangeValue={(e) => {
                  paymentType === "DLVBY" && setValue("deliveryAgentId", "");
                  paymentType === "CUSTM" && setValue("customerId", "");
                  setValue("safeId", "");
                  setValue("transactionTypeId", "");
                  setValue("representativeId", "");
                  // setPickedShipment({ shipments: [] })
                }}
                skipDefaultBranch={Boolean(paymentId)}
              />
            </Grid>
            {customerField(paymentType)}


            <Grid sm={4} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"transactionTypeId"}
                label={t("transactionType")}
                selectFirst={true}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                skip={paymentType === null || !watch("branchId")}
                variables={{
                  input: {
                    type: "CSHP",
                    ...(watch("branchId") && {
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>
            <Grid sm={paymentType === "CUSTM" ? 6 : 4} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"safeId"}
                disabled={!watch("branchId")}
                label={t("safe")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_SAFES_DROPDOWN.query}
                skip={!watch("branchId")}
                variables={{
                  input: {
                    branchId: watch("branchId"),
                  },
                }}
                defaultValue={autocompleteValues.safe}
              />
            </Grid>
            {paymentType === "CUSTM" &&
              <Grid sm={autocompleteValues.images[0] || previewImage ? 4 : 6} xs={autocompleteValues.images[0] || previewImage ? 10 : 12}>
                <UploadFile
                  control={control}
                  setValue={setValue}
                  name="images"
                  icon={"add_photo_alternate"}
                  label={t("uploadImage")}
                  accept=".png,.jpg"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setPreviewImage(reader.result);
                      };
                      reader.readAsDataURL(file);
                    } else {
                      setPreviewImage(null);
                    }
                    setAutocompleteValues((prev) => ({
                      ...prev,
                      images: []
                    }));
                  }}
                />
              </Grid>
            }
            {paymentType === "CUSTM" && (autocompleteValues.images[0] || previewImage) &&
              <Grid xs={2} sm={2} textAlign="center">
                {previewImage ? (
                  <Badge
                    style={{ padding: 0 }}
                    // sx={{ padding: 0  }}
                    onClick={setClear}
                    badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                    color="error"
                  >
                    <img src={previewImage} alt="Preview" style={{ width: "60px", height: "60px", borderRadius: "1%" }} onClick={(event) => event.stopPropagation()} />
                  </Badge>
                )
                  : autocompleteValues.images[0] &&
                  <Badge
                    onClick={() => { setClear() }}
                    badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                    color="error"
                  >
                    <Box
                      sx={{ width: "60px", height: "60px", borderRadius: "1%" }}
                      component="img" alt="signature"
                      src={getBackendUri(autocompleteValues.images[0])}
                      onClick={(event) => event.stopPropagation()}
                    />
                  </Badge>}
              </Grid>}
            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
            {Globals.user.hasPermission("shipping.customer_merchant.list") &&
              customerData &&
              <Grid sm={12} xs={12}>
                <CustomerPaymentMethod classes={classes} customerData={customerData} />
              </Grid>
            }
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                disabled={
                  paymentData?.approved || disableForm || savePaymentLoad
                }
                variant="contained"
                size="medium"
                loading={savePaymentLoad}
                // className={classes.button}
                startIcon={!savePaymentLoad && <Done />}
              >
                {!savePaymentLoad && t("save")}
              </CustomButton>
            )}
            {disableForm && (
              <Button
                disabled={
                  (shipLength && pickupLength && conciergeRequestLength) || paymentData?.approved || savePaymentLoad
                }
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
            {paymentData?.approved && (
              <Button
                disabled={paymentData?.glApproved}
                className={classes.glApprovedButton}
                variant="contained"
                size="medium"
                onClick={onGlApproved}
                name="glApproved"
                startIcon={<AttachMoney />}
              >
                {t("glApprove")}
              </Button>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Box>
  );

  const icons = [
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/payment/${watch(
            "id"
          )}/${paymentType}`
        ),
      icon: Print,
      disabled: paymentByIdLoad,
      permission: entriesData,
    },
  ]

  return paymentByIdLoad ? <StyledLoading container item justifyContent="center" className={classes.main}>
    <FullScreenLoading height={"100%"} />
  </StyledLoading> :
    !paymentByIdQuery?.payment && paymentId ? (
      <NotFound />
    ) : (
      <Root>
        <TitleAppBar path={pathURL} type={paymentType}>
          <LongMenu icons={icons} />
        </TitleAppBar >

        <Dialog
          open={openDialog.open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-title" color={"text.primary"}>
            {openDialog.edit && t("updateRecord")}
            {openDialog.approve && t("approveRecord")}
            {openDialog.glApprove && t("glApprove")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {openDialog.edit && t("updateRecordMessage")}
              {openDialog.approve && t("approveRecordConfirmationMessage")}
              {openDialog.glApprove && t("glApproveRecordMessage")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {t("cancel")}
            </Button>
            <Button
              disabled={approveLoad || glApproveLoad}
              onClick={() => {
                openDialog.edit && handleDialogClose();
                openDialog.edit && setDisableForm(true);
                openDialog.edit && savePaymentMutationFun(openDialog.data, paymentRefetch);

                openDialog.approve && approvePayment();
                openDialog.glApprove && glApprovePayment();
              }}
              color="primary"
              autoFocus
            >
              {openDialog.edit && t("update")}
              {openDialog.approve && !approveLoad && t("approve")}
              {openDialog.glApprove && !glApproveLoad && t("glApprove")}
              {(approveLoad || glApproveLoad) && <ButtonLoading />}
            </Button>
          </DialogActions>
        </Dialog>

        <Stack spacing={2} margin={2}>
          {props.children}
          {formBody}
          {formCondition && paymentType === 'DLVBY' &&
            <Paper sx={{ py: 2 }}>
              <Stack direction={"row"} spacing={1} flexWrap={"wrap"} justifyContent={"center"}>
                <Typography
                  className={classes.totalTitle}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  sx={{ textAlign: "end" }}
                >
                  {t("totalDeservedAmount")}
                </Typography>
                <Typography

                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  sx={{ textAlign: "start" }}
                >
                  {paymentData?.sumEntries?.paymentAmount}
                </Typography>
              </Stack>
            </Paper>
          }

          <FormTabs
            classes={classes}
            watch={watch}
            disableForm={disableForm}
            paymentType={paymentType}
            paymentData={paymentData}
            paymentDetails={paymentDetails}
            conciergeRequestsDetails={conciergeRequestsDetails}
            paymentPickupsDetails={paymentPickupsDetails}
            // collectionDetails={collectionDetails}
            shipmentData={shipmentData}
            setShipmentData={setShipmentData}
            loadingShipmentData={loadingShipmentData}
            refetchShipmentDataQuery={refetchShipmentDataQuery}

            conciergeRequestsData={conciergeRequestsData}
            getConciergeData={getConciergeData}
            setConciergeRequestsData={setConciergeRequestsData}
            loadingConciergeData={loadingConciergeData}
            refetchConciergeDataQuery={refetchConciergeDataQuery}


            pickupData={pickupData}
            getPickupData={getPickupData}
            setPickupData={setPickupData}
            loadingPickupData={loadingPickupData}
            refetchPickupDataQuery={refetchPickupDataQuery}
          />

        </Stack>
      </Root >
    );
};

export default withRouter(PaymentForm);
