import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { gql, useQuery } from "@apollo/client";
import {
    TableBody,
    TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
// import { LIST_CUSTOMER_WITH_NEW_SHIPMENT } from "./Graphql";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import * as gqlb from "gql-query-builder";
import config from "../../config.json";

const PREFIX = "CustomerList";

const classes = {
    tableBodyRow: `${PREFIX}-tableBodyRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
    [`& .${classes.tableBodyRow}`]: {
        "&:hover": {
            "& .MuiTableCell-root": {
                backgroundColor: theme.palette.background.hover,
            },
        },
    },
}));

export const LIST_CUSTOMER_WITH_NEW_SHIPMENT = (fromDate, toDate) => gqlb.query([
    {
        operation: "listCustomerWithNewShipments",
        fields: [
            {
                operation: "paginatorInfo",
                fields: [
                    "count",
                    "currentPage",
                    "firstItem",
                    "hasMorePages",
                    "lastItem",
                    "lastPage",
                    "perPage",
                    "total",
                ],
                variables: {},
            },
            {
                operation: "data",
                fields: [
                    "id",
                    "name",
                    // "newShipmentCount",
                    {
                        operation: `newShipmentCount(fromDate: ${fromDate ? `"${fromDate}"` : null},toDate: ${toDate ? `"${toDate}"` : null})`,
                        fields: [],
                        variables: {
                        },
                    },
                ],
                variables: {},
            },
        ],
        variables: {
            page: {
                type: "Int",
            },
            first: {
                type: "Int",
            },
            fromDate: {
                type: "Date"
            },
            toDate: {
                type: "Date"
            }
        },
    }
]);

const CustomerNewShipment = (props) => {
    const { t } = useTranslation();
    const urlQuery = urlParameters(window.location.search);

    const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
    const initSearch = () => {
        let searchParameters = {
            page: 0,
            refetch: true,
        };
        delete urlQuery["rowsPerPage"];
        return {
            ...searchParameters,
        };
    };
    const [search, setSearch] = useState(initSearch());

    const history = useHistory();
    const pushUrlSearch = (param) => {
        const queryParams = [];
        for (const i in param) {
            encodeURIComponent(param[i]) &&
                queryParams.push(
                    encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
                );
        }
        const queryString = queryParams.join("&");

        const url = history.createHref({
            pathname: "/admin/customer-new-shipment",
            search: "?" + queryString,
        });
        windowUrl(url);
        //this will not effect on history.location.search
    };

    const handleChangePage = (event, newPage) => {
        pushUrlSearch({
            ...urlQuery,

            page: newPage,
            rowsPerPage: rowsPerPage,
        });

        setSearch((prev) => ({ ...prev, page: newPage }));
    };

    const handleChangeRowsPerPage = (event) => {
        pushUrlSearch({
            ...urlQuery,
            page: 0,
            rowsPerPage: +event.target.value,
        });

        setRowsPerPage(+event.target.value);
        setSearch((prev) => ({ ...prev, page: 0 }));
    };
    // ...(urlQuery['fromDate'] && { fromDate: urlQuery['fromDate'] }),
    //             ...(urlQuery['toDate'] && { toDate: urlQuery['toDate'] }),

    const listQuery = LIST_CUSTOMER_WITH_NEW_SHIPMENT(urlQuery['fromDate'], urlQuery['toDate'])
    const { data, loading } = useQuery(
        gql`
      ${listQuery.query}
    `,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            variables: {
                first: rowsPerPage,
                page: search?.page + 1,
                fromDate: urlQuery['fromDate'] ?? null,
                toDate: urlQuery['toDate'] ?? null,
            },
            onCompleted: (data) => {
            },
        }
    );

    const customerNewShipment =
        data?.listCustomerWithNewShipments?.data !== null ? data?.listCustomerWithNewShipments?.data : null;

    return (
        <Root>
            <ListWrapper
                drawerState={false}
                icons={false}
                path={props.location.pathname}
                empty={customerNewShipment?.length === 0}
                loading={loading}
                filters={false}
                tableHeaders={
                    [
                        {
                            name: "customer"
                        },
                        {
                            name: "shipmentsNumber"
                        },
                        {
                            name: " "
                        },
                    ]
                }
                tableBody={
                    <TableBody>
                        {customerNewShipment &&
                            customerNewShipment?.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                        className={classes.tableBodyRow}
                                    >
                                        <CellLink pathname={`/admin/customers/${row?.id}`}>
                                            {`(${row?.id}) ${row?.name}`}
                                        </CellLink>
                                        <FixedTableCell>{row.newShipmentCount}</FixedTableCell>
                                        <CellLink pathname={`/admin/shipments`}
                                            search={`new=true&customerId=${row?.id}${urlQuery['fromDate'] ? `&fromDate=${urlQuery['fromDate']}&toDate=${urlQuery['toDate']}` : ''}`}
                                        >
                                            {t('viewShipments')}
                                        </CellLink>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                }
                pagination={
                    <MUITablePagination
                        count={data?.listCustomerWithNewShipments?.paginatorInfo?.total}
                        rowsPerPage={rowsPerPage}
                        page={!data?.listCustomerWithNewShipments ? 0 : search?.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            />

        </Root>
    );
};

export default CustomerNewShipment;
