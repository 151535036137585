import * as gqlb from "gql-query-builder";

export const LIST_VOUCHER_QUERY = gqlb.query({
  operation: "listVouchers",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "total",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "code",
        "id",
        "date",
        "approved",
        "glApproved",
        "notes",
        "amount",

        {
          payeeType: ["code", "name"],
        },
        {
          type: ["code", "name"],
        },
        {
          branch: ["id", "name"],
        },
        {
          glAccount: ["id", "name"],
        },
        {
          safe: ["id", "name"],
        },
        {
          toSafe: ["id", "name"],
        },
        {
          deliveryAgent: ["id", "name"],
        },
        {
          customer: ["id", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListVoucherFilterInput",
      required: true,
    },
    first: {
      type: "Int",
      required: true,
      value: 15,
    },
    page: {
      type: "Int",
      value: 1,
    },
  },
});

const voucherFields = [
  "code",
  "id",
  "date",
  "approved",
  "glApproved",
  "notes",
  "amount",

  {
    payeeType: ["code", "name"],
  },
  {
    type: ["code", "name"],
  },
  {
    branch: ["id", "name"],
  },
  {
    glAccount: ["id", "name"],
  },
  {
    safe: ["id", "name"],
  },
  {
    toSafe: ["id", "name"],
  },
  {
    deliveryAgent: ["id", "name"],
  },
  {
    customer: ["id", "name"],
  },
];

export const VOUCHER_BY_ID_QUERY = gqlb.query({
  operation: "voucher",
  fields: voucherFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const VOUCHER_ID_AND_PRINT = (permission) =>
  gqlb.query([
    {
      operation: "voucher",
      fields: [
        "code",
        "id",
        "date",
        "approved",
        "glApproved",
        "notes",
        "amount",
        {
          payeeType: ["code", "name"],
        },
        {
          operation: `journalEntry @include(if: ${permission})`,
          fields: ["code", "id"],
          variables: {},
        },
        {
          type: ["code", "name"],
        },
        {
          branch: ["id", "name"],
        },
        {
          glAccount: ["id", "name"],
        },
        {
          safe: ["id", "name"],
        },
        {
          toSafe: ["id", "name"],
        },
        {
          deliveryAgent: ["id", "name"],
        },
        {
          customer: ["id", "name"],
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  ]);

export const SAVE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "saveVoucher",
  fields: voucherFields,
  variables: {
    input: {
      type: "VoucherInput",
      required: true,
    },
  },
});

export const DELETE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "deleteVoucher",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "approveVoucher",
  fields: voucherFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DISAPPROVE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "disapproveVoucher",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const GL_APPROVE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "glApproveVoucher",
  fields: voucherFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
