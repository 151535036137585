import React, { Fragment, useState } from "react";
import {
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  Typography,
  IconButton,
} from "@mui/material";
import { Print } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Globals } from "../HOC/Classes/Globals";
import RemoveIcon from '@mui/icons-material/Remove';
import { printLink } from "../../helpers/defaultPrintList";


const PrintComponent = (props) => {
  const { shipmentsId, hidden = [], oneShipment, batch, printType, afterSaveFun, setOpenPrint, openPrint, onClosePrintDialog } = props;
  const { t, i18n } = useTranslation();
  const defaultPrint = Globals.settings.waybillCode;

  const icon = !Boolean(setOpenPrint)
  const [openPrintDialog, setOpenPrintDialog] = useState(false)

  const handleClosePrint = () => {
    onClosePrintDialog && onClosePrintDialog()
    printType === 'R&P' && afterSaveFun()
    icon ? setOpenPrintDialog(false) : setOpenPrint(false);
  };

  const handleOpenPrint = () => {
    defaultPrint ? printLink(defaultPrint, false, shipmentsId) : setOpenPrintDialog(true);
  };

  const hideItem = (name) => hidden.includes(name);

  const printTypes = [
    {
      id: 1,
      hide: "1c",
      oneShipment: !oneShipment,
      printLink: "SHEET_SAME_1",
      title: t("OneShipment")
    },
    {
      id: 2,
      oneShipment: oneShipment ?? false,
      hide: "2d",
      printLink: "copy",
      title: i18n.language === "en" ? t("copy", { count: 1 }) : t("copy")
    },
    {
      id: 3,
      hide: "2c",
      oneShipment: oneShipment,
      printLink: "SHEET_SAME_2",
      title: oneShipment
        ? t("copy", { count: 2 })
        : t("twoCopyForOneShipment")
    },
    {
      id: 4,
      hide: "3c",
      printLink: "SHEET_SAME_3",
      title: oneShipment
        ? t("copy", { count: 3 })
        : t("threeCopyForOneShipment")
    },
    {
      id: 5,
      hide: "2d",
      oneShipment: !oneShipment,
      printLink: "SHEET_DIFF_2",
      title: t("twoDifferentShipments")
    },
    {
      id: 6,
      hide: "3d",
      printLink: "SHEET_DIFF_3",
      title: t("threeDifferentShipments")
    },
    {
      id: 7,
      hide: "sticky",
      printLink: "STICKER_4X5",
      title: t("barcodeSticker4x5")
    },
    {
      id: 11,
      hide: "STICKER_2X5",
      printLink: "STICKER_2X5",
      title: t("barcodeSticker2X5")
    },
    {
      id: 8,
      hide: "sticky7X9",
      printLink: "STICKER_7X9",
      title: t("barcodeSticker7x9")
    },
    {
      id: 12,
      hide: "sticky10X10",
      printLink: "STICKER_10X10",
      title: t("barcodeSticker10X10")
    },
    {
      id: 9,
      hide: "sticky10X15",
      printLink: "STICKER_10X15",
      title: t("barcodeSticker10X15")
    },
    {
      id: 10,
      hide: "sticky17X10",
      printLink: "STICKER_17X10",
      title: t("barcodeSticker17X10")
    },
  ]

  return (
    <Fragment>
      {icon &&
        <IconButton
          aria-label="print"
          onClick={handleOpenPrint}
          disabled={shipmentsId?.length === 0}>
          <Print fontSize="inherit" />
        </IconButton>
      }
      <Dialog
        open={icon ? openPrintDialog : openPrint}
        onClose={handleClosePrint}
      >
        <DialogTitle color={'text.primary'} sx={{ display: "flex", alignItems: "center", pb: 1 }}>
          <ListItemIcon sx={{ minWidth: 35 }}>
            <Print fontSize="medium" />
          </ListItemIcon>
          {t("print")}
        </DialogTitle>
        <DialogContent sx={{ p: 0, pb: 2 }}>
          <List>
            {
              printTypes.map((e) =>
                !hideItem(e.hide) && (e?.oneShipment || e.oneShipment === undefined) && (
                  <ListItem
                    sx={{ px: 5 }}
                    key={e.id}
                    button
                    onClick={() => {
                      printLink(e.printLink, batch, shipmentsId)
                      handleClosePrint();
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <RemoveIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1">
                      {e.title}
                    </Typography>
                  </ListItem>
                )
              )
            }
          </List>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default PrintComponent;