import { useTranslation } from "react-i18next";
import { Chip, Paper, Typography } from "@mui/material";
import CustomTab from "../../HOC/FunctionComponents/CustomTab";
import { ProductTableForm } from "./FormTables";


function FormTabs(props) {
    const {
        classes,
        productsSelected,
        filter,
        setChange,
        setChangeIN,
        EmptyTextFields,
        EmptyTextFieldsIN,
        EmptyTextFieldsRTS,
        setPickedProduct,
        CRDTShipment,
        setChangeDes,
        unEditable,
        errors,
        control,
        productsSelectedIN,
        setPickedProductIN,
        productDrawerHandler,
        productDrawerHandlerIN,
        setPrice,
        clearErrors
    } = props
    const { t } = useTranslation()
    const tapsArray = [];

    const shipments = (
        <ProductTableForm
            classes={classes}
            productsSelected={productsSelected}
            setChange={setChange}
            EmptyTextFields={EmptyTextFields}
            setPickedProduct={setPickedProduct}
            unEditable={unEditable}
            errors={errors}
            control={control}
            CRDTShipment={CRDTShipment}
            setChangeDes={setChangeDes}
            productDrawerHandler={productDrawerHandler}
            clearErrors={clearErrors}
        />
    );

    const product = (
        <ProductTableForm
            classes={classes}
            productsSelected={productsSelectedIN}
            setChange={setChangeIN}
            setPrice={setPrice}
            EmptyTextFields={EmptyTextFieldsIN}
            setPickedProduct={setPickedProductIN}
            EmptyTextFieldsRTS={EmptyTextFieldsRTS}
            unEditable={unEditable}
            errors={errors}
            control={control}
            filter={true}
            productIN={true}
            CRDTShipment={CRDTShipment}
            setChangeDes={setChangeDes}
            productDrawerHandler={productDrawerHandlerIN}
            clearErrors={clearErrors}
        // type={filter}
        />
    );

    // Logic for FDP
    if (filter === "FDP") {
        tapsArray.push({
            tabHead:
                <Typography color="inherit" variant="subtitle1" component="div">
                    {t("deliveryProduct")}
                    <Chip color="primary" size="small" label={productsSelected.length} sx={{ mx: 1 }} />
                </Typography>,
            panel: <Paper>{shipments}</Paper>,
        });
    }

    // Logic for RTS
    if (filter === "RTS") {
        tapsArray.push({
            tabHead:
                <Typography color="inherit" variant="subtitle1" component="div">
                    {t("returnProduct")}
                    <Chip color="primary" size="small" label={productsSelectedIN.length} sx={{ mx: 1 }} />
                </Typography>,
            panel: <Paper>{product}</Paper>,
        });
    }

    // Logic for PTP
    if (filter === "PTP") {
        tapsArray.push({
            tabHead:
                <Typography color="inherit" variant="subtitle1" component="div">
                    {t("deliveryProduct")}
                    <Chip color="primary" size="small" label={productsSelected.length} sx={{ mx: 1 }} />
                </Typography>,
            panel: <Paper>{shipments}</Paper>,
        });

        tapsArray.push({
            tabHead:
                <Typography color="inherit" variant="subtitle1" component="div">
                    {t("returnProduct")}
                    <Chip color="primary" size="small" label={productsSelectedIN.length} sx={{ mx: 1 }} />
                </Typography>,
            panel: <Paper>{product}</Paper>,
        });
    }
    return (
        <CustomTab tapDetails={tapsArray} />
    )

}
export default FormTabs