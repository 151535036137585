import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
    Typography,
    Paper,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableHead,
    IconButton,
    Toolbar,
    Icon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Edit } from "@mui/icons-material";
import clsx from "clsx";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";

const PREFIX = "RepresentativeTable";

const classes = {
    p: `${PREFIX}-p`,
    tap: `${PREFIX}-tap`,
    table: `${PREFIX}-table`,
    tableContainer: `${PREFIX}-tableContainer`,
    title: `${PREFIX}-title`,
    toolbar: `${PREFIX}-toolbar`,
    focus: `${PREFIX}-focus`,
    destinations: `${PREFIX}-destinations`,
    iconColor: `${PREFIX}-iconColor`,
    deleteIcon: `${PREFIX}-deleteIcon`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.tap}`]: {
        margin: 0,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        // flexDirection: "column",
        textTransform: "capitalize",
    },

    [`& .${classes.table}`]: {
        display: "grid",
    },

    [`& .${classes.tableContainer}`]: {
        minHeight: "250px",
    },

    [`& .${classes.title}`]: {
        flex: 1,
    },

    [`& .${classes.toolbar}`]: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderBottom: "1px solid " + theme.palette.divider,
        borderRight: "1px solid " + theme.palette.divider,
    },

    [`& .${classes.focus}`]: {
        backgroundColor: theme.palette.action.hover,
    },

    [`& .${classes.destinations}`]: {
        [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(2),
        },
    },

    [`& .${classes.iconColor}`]: {
        color: theme.palette.success.main,
    },

    [`& .${classes.deleteIcon}`]: {
        marginLeft: theme.spacing(1),
    },
}));

const RepresentativeTable = (props) => {
    const { representatives = [], addRepresentatives, typeCode } = props;
    const { t } = useTranslation();
    const [representativesIndex, setRepresentativesIndex] = useState(0);
    const [representativesPage, setRepresentativesPage] = useState(0);
    const [representativesRowsPerPage, setRepresentativesRowsPerPage] =
        useState(20);

    const handleRepresentativesPage = (event, newPage) => {
        setRepresentativesPage(newPage);
    };
    const handleRepresentativesRowsPerPage = (event) => {
        setRepresentativesRowsPerPage(parseInt(event.target.value));
        setRepresentativesPage(0);
    };
    const type = representatives.map((row) => row.typeCode);
    return (
        <StyledGrid container alignItems="flex-start">
            <Grid container item sm={12} component={Paper} minHeight={"100%"}>
                <Toolbar className={classes.toolbar} variant="dense">
                    <Typography className={classes.title} color="inherit" variant="h6">
                        {t(typeCode)}
                    </Typography>

                    {addRepresentatives ? (
                        <IconButton
                            color="primary"
                            onClick={() => {
                                addRepresentatives();
                                setRepresentativesIndex(0);
                            }}
                            aria-label="Add Representative"
                            size="large"
                        >
                            <Add />
                        </IconButton>
                    ) : null}
                </Toolbar>
                <Grid container item className={classes.table}>
                    {representatives.length <= 0 ? (
                        <EmptyTableMessage loading={false} message={t("noResult")} />
                    ) : (
                        <TableFixedHeaderWraper className={classes.tableContainer}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <FixedTableCell>
                                            {t(typeCode)}
                                        </FixedTableCell>
                                        <FixedTableCell>{t("active")}</FixedTableCell>
                                        {addRepresentatives ? (
                                            <FixedTableCell></FixedTableCell>
                                        ) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {representatives.length > 0 &&
                                        (representativesRowsPerPage > 0
                                            ? representatives.slice(
                                                representativesPage * representativesRowsPerPage,
                                                representativesPage * representativesRowsPerPage +
                                                representativesRowsPerPage
                                            )
                                            : representatives
                                        ).map((row, index) => (
                                            <TableRow
                                                className={clsx({
                                                    [classes.focus]:
                                                        representativesPage * representativesRowsPerPage +
                                                        index ===
                                                        representativesIndex,
                                                })}
                                                hover
                                                key={index}
                                                onClick={() => {
                                                    setRepresentativesPage(0);
                                                    setRepresentativesIndex(
                                                        representativesPage * representativesRowsPerPage +
                                                        index
                                                    );
                                                }}
                                            >
                                                <FixedTableCell>{row?.name}</FixedTableCell>
                                                <FixedTableCell>
                                                    {row.active ? (
                                                        <Icon className={classes.iconColor}>
                                                            check_circle_outline
                                                        </Icon>
                                                    ) : (
                                                        <Icon color="error">highlight_off</Icon>
                                                    )}
                                                </FixedTableCell>
                                                {addRepresentatives ? (
                                                    <FixedTableCell>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                addRepresentatives(
                                                                    representativesPage *
                                                                    representativesRowsPerPage +
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </FixedTableCell>
                                                ) : null}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableFixedHeaderWraper>
                    )}
                    <MUITablePagination
                        count={representatives?.length ?? 0}
                        rowsPerPage={representativesRowsPerPage}
                        page={representativesPage}
                        onPageChange={handleRepresentativesPage}
                        onRowsPerPageChange={handleRepresentativesRowsPerPage}
                    />
                </Grid>
            </Grid>
        </StyledGrid>
    );
};

export default RepresentativeTable;
