import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear, Search } from "@mui/icons-material";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MUItextField from "../../HOC/MUI/MUItextField";
import { memo, useEffect, useRef } from "react";

const PREFIX = "shipmentListFilters";

const classes = {
    button: `${PREFIX}-button`,
    btnMargin: `${PREFIX}-btnMargin`,
    searchForm: `${PREFIX}-searchForm`,
    searchField: `${PREFIX}-searchField`,
    overlay: `${PREFIX}-overlay`,
    searchPadding: `${PREFIX}-searchPadding`,
    icon: `${PREFIX}-icon`,
};

const Root = styled(Grid)(({ theme }) => ({
    [`& .${classes.button}`]: {
        position: "sticky",
        bottom: 0,
        zIndex: 3,
        backgroundColor: "#fff",
        marginTop: 10,
        padding: 7,
    },

    [`& .${classes.btnMargin}`]: {
        width: "100%",
    },

    [`&.${classes.searchForm}`]: {
        width: "100%",
        margin: 0,
        [theme.breakpoints.down("md")]: {
            flex: " 1 0 100%",
        },
    },

    [`& .${classes.searchPadding}`]: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },

    [`& .${classes.searchPadding}`]: {
        "& span": {
            margin: 0,
        },
    },
}));
function SearchProduct({
    checkProductsFun,
    setChange,
    change
}) {
    const { handleSubmit, register, errors, watch, setValue } =
        useForm();
    const { t } = useTranslation()
    const onSubmit = (data) => {
        if (!data.search) return;
        setChange(!change)
        if (checkProductsFun(data.search)) {
            setValue("search", ``)
        } else {
            setValue("search", `${data.search}`);
        }
    };
    const clearCode = () => {
        setValue("search", "");
    };
    const searchInputRef = useRef(null);

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);
    return (
        <Root
            container
            item
            justifyContent="center"
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.searchForm}
            alignContent="flex-start"
        >
            <Grid
                container
                item
                sm={6}
                justifyContent="flex-start"
                spacing={1}
                className={classes.searchPadding}
                flexWrap={"nowrap"}
            >
                <Grid container item xs={10} md={10} alignItems="flex-start" >
                    <MUItextField
                        label={t("barcode")}
                        name={"search"}
                        variant="outlined"
                        inputProps={{
                            dir: "ltr",
                            ref: searchInputRef,
                        }}
                        // onPaste={(e) => {
                        //     e.preventDefault();
                        //     const input = e.target;
                        //     const inputValue = e.clipboardData
                        //         .getData("Text")
                        //         .replace(/\n/g, ",");

                        //     const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                        //         window.HTMLInputElement.prototype,
                        //         "value"
                        //     ).set;
                        //     nativeInputValueSetter.call(input, input.value + inputValue);

                        //     input.dispatchEvent(new Event("input", { bubbles: true }));
                        // }}
                        // onKeyPress={(e) => {
                        //     const value = e.target.value.trim();
                        //     if (value.slice(-1) !== ',' && value !== '') {
                        //         if (e.key === "Enter") {
                        //             setChange(!change)
                        //             if (checkProductsFun(value)) {
                        //                 setValue("search", ``)
                        //             } else {
                        //                 setValue("search", `${value}`);
                        //             }
                        //         }
                        //     }
                        // }}
                        formType={"requireFalse"}
                        register={register}
                        errors={errors}
                        InputProps={{
                            endAdornment: Boolean(watch("search")) && (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="code"
                                        onClick={clearCode}
                                        edge="end"
                                        size="large"
                                    >
                                        <Clear color="error" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        watch={watch("search")}
                    />
                </Grid>
                <Grid container item xs={2} md={2} alignItems="flex-start">
                    <Button
                        className={clsx(classes.btnMargin)}
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        sx={{
                            boxShadow: 0, padding: 0, height: "100%", minWidth: "100%"
                        }}
                        startIcon={<Search className={clsx(classes.icon)} />}
                    >
                    </Button>
                </Grid>
            </Grid>
        </Root>
    )
}

export default memo(SearchProduct, (prevProps, nextProps) => prevProps.change === nextProps.change)