/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { SACK_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import BranchesTable from "./BranchesTable";
import moment from "moment";
const PREFIX = "SacksView";

const classes = {
  paper: `${PREFIX}-paper`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const SacksView = (props) => {
  const { t } = useTranslation();
  const sackId = props.match.params.id?.trim();
  const { loading, data } = useQuery(
    gql`
      ${SACK_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(sackId) },
    }
  );
  const user = Globals.user;
  const branchId = data?.sack?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canEdit = user.hasPermission("shipping.sack.update")
    ? canAccessBranch
    : false

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/sacks/${sackId}/edit`),
      icon: Edit,
      permission: canEdit,
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    : !data?.sack ? (
      <NotFound />
    ) : (
      <StyledGrid
        container
        justifyContent="center"
        className={classes.mainTracking}
        sx={{ margin: "0", width: "100%" }}
      >
        <Fragment>
          <TitleAppBar path={props.match.path}>
            <LongMenu icons={icons} />
          </TitleAppBar>
        </Fragment>

        <Grid
          container
          justifyContent="center"
          sx={{ width: "100%", p: 2, gap: 2 }}
        >
          <Paper container className={classes.paper} component={Grid}>
            {/* <KeyValuePair title={t("code")} value={data?.sack?.id} /> */}
            <KeyValuePair title={t("name")} value={data?.sack?.name} />
            <KeyValuePair title={t("createdAt")} value={data?.sack?.createdAt ? moment(data?.sack?.createdAt).format("ll") : null} />
            <KeyValuePair title={t("updatedAt")} value={data?.sack?.updatedAt ? moment(data?.sack?.updatedAt).format("ll") : null} />

            <KeyValuePair
              title={t("active")}
              value={
                data?.sack?.active ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            <KeyValuePair
              title={t("totalQuantity")}
              value={data?.sack?.quantity}
            />
            <KeyValuePair
              title={t("createdBy")}
              value={<SpanLink
                pathname={`/admin/users/${data?.sack?.createdBy?.id}`}
              >
                {data?.sack?.createdBy?.username}
              </SpanLink>}
            />
            <KeyValuePair
              title={t("updatedBy")}
              value={<SpanLink
                pathname={`/admin/users/${data?.sack?.updatedBy?.id}`}
              >
                {data?.sack?.updatedBy?.username}
              </SpanLink>}
            />
          </Paper>
        </Grid>
        {
          data?.sack?.sackBranches && (
            <BranchesTable branches={data?.sack?.sackBranches} />
          )
        }
      </StyledGrid >
    );
};

export default SacksView;
