import { AppBar, Badge, Grid, IconButton, Toolbar } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { memo, useContext, useState } from "react";

import { Fragment } from "react";
import { Link } from "react-router-dom";
// import GlobalSearch from "../Component/GlobalSearch/GlobalSearch";
import LogoImg from "./LogoImg";
import config from "../config.json"
import GlobalSearch from "../Component/GlobalSearch/GlobalSearch";
import NotificationContainer from "./Notifications/NotificationsContainer";
import MessageContainer from "./Message/MessagesContainer";
import AvatarComponent from "./Avatar";
import useWidth, { isWidthDown } from "../Hooks/useWidth";
import { Message, Notifications } from "@mui/icons-material";
import { ModeContext } from "../Context/ModeContext";
import { Globals } from "../Component/HOC/Classes/Globals";

import HeaderIcons from './HeaderIcons';
import { BiPhoneCall } from "react-icons/bi";

import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { GoPackageDependencies } from 'react-icons/go';
import { VscPackage } from 'react-icons/vsc';
import { MdOutlinePendingActions, MdOutlineReportProblem } from 'react-icons/md';
import { RiEBike2Line, RiMoneyDollarCircleLine } from "react-icons/ri";

const PREFIX = 'Header';

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  lang: `${PREFIX}-lang`,
  centerItems: `${PREFIX}-centerItems`,
  logoImg: `${PREFIX}-logoImg`
};


const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.appBar}`]: {
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0.5)
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.lang}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.centerItems}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.logoImg}`]: {
    maxHeight: 48,
    marginLeft: theme.spacing(1),
  }
}));

const Header = (props) => {
  const { MenuButton } = props;

  const user = Globals?.user;
  const support = Globals?.settings?.support;

  const complaintsPermission =
    support &&
    (Globals.user.member.teams.length > 0 ||
      user.hasPermission("crm.ticket.list"));
  const returnRequestsPermission =
    support && user.hasPermission("shipping.customer_request_rtrn.list");
  const paymentRequestsPermission =
    support && user.hasPermission("shipping.customer_request_pmnt.list");
  const materialRequestsPermission =
    support && user.hasPermission("shipping.customer_request_mtrl.list");
  const listPickupsPermission = support && user.hasPermission("shipping.pickup.list");
  const shipmentsPermission = support && user.hasPermission("shipping.shipment.list");

  const screenWidth = useWidth();
  const addDashboardIcons = isWidthDown("sm", screenWidth);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [badge, setBadge] = useState({
    notification: "true",
    message: "true"
  })

  // notification content
  const [newArrival, setNewArrival] = useState(false)
  const isScreenSmall = isWidthDown("xs", screenWidth);
  const drawerAnchor = isScreenSmall ? "bottom" : "right";

  const [notificationDrawerState, setNotificationDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const notificationDrawer = (anchor, open) => {
    setNotificationDrawerState({ ...notificationDrawerState, [anchor]: open });
  };




  const notificationIcon = (
    <IconButton
      sx={{ p: 1 }}
      onClick={() => {
        notificationDrawer(drawerAnchor, true);
        localStorage.setItem("notifications", new Date())
        handleCloseMenu()
      }}
      size="large"
    >
      <Badge
        color="primary"
        variant="dot"
        invisible={!newArrival}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Notifications color="action" />
      </Badge>
    </IconButton>
  );

  // message content
  const { hasMessagesPermission } = useContext(ModeContext)
  const [newMessageArrival, setNewMessageArrival] = useState(false)
  const [messageDrawerState, setMessageDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const messageDrawer = (anchor, open) => {
    setMessageDrawerState({ ...messageDrawerState, [anchor]: open });
  };


  const messageIcon = hasMessagesPermission && (
    <IconButton
      sx={{ p: 1 }}
      onClick={() => {
        messageDrawer(drawerAnchor, true);
        localStorage.setItem("messages", new Date())
        handleCloseMenu()
      }}
      size="large"
    >
      <Badge
        color="primary"
        variant="dot"
        invisible={!newMessageArrival}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Message color="action" />
      </Badge>
    </IconButton>
  );



  const PaymentRequestsPermission = () => {

    return paymentRequestsPermission ?
      <HeaderIcons
        icon={MonetizationOnOutlinedIcon}
        title={"paymentRequests"}
        pathname={"/admin/customer-requests/pmnt?statusCode=NEW"}
        type={"PMNT"}
        operation={"listCustomerRequests"}
        inputType={"ListCustomerRequestFilterInput!"}
        statusCode={true}
      />
      : null
  }
  const ReturnRequestsPermission = () => {

    return returnRequestsPermission ? (
      <HeaderIcons
        icon={GoPackageDependencies}
        title={"returnRequests"}
        pathname={"/admin/customer-requests/rtrn?statusCode=NEW"}
        type={"RTRN"}
        operation={"listCustomerRequests"}
        inputType={"ListCustomerRequestFilterInput!"}
        statusCode={true}
      />
    ) : null
  }
  const MaterialRequestsPermission = () => {

    return materialRequestsPermission ? (
      <HeaderIcons
        icon={VscPackage}
        pathname={"/admin/customer-requests/mtrl?statusCode=NEW"}
        title={"materialRequests"}
        type={"MTRL"}
        operation={"listCustomerRequests"}
        inputType={"ListCustomerRequestFilterInput!"}
        statusCode={true}
      />
    ) : null
  }
  const ComplaintsPermission = () => {

    return complaintsPermission ? (
      <HeaderIcons
        icon={MdOutlineReportProblem}
        pathname={"/admin/crm/tickets?statusCode=NEW"}
        title={"complaints"}
        type={"TICKET"}
        operation={"listTickets"}
        inputType={"ListTicketsFilterInput"}
        statusCode={true}
      />
    ) : null
  }
  const PickupsPermission = () => {

    return listPickupsPermission ? (
      <HeaderIcons
        icon={BiPhoneCall}
        title={"pickupRequests"}
        pathname={"/admin/pickups?new=true&statusCode=NEW"}
        type={"PICKUP"}
        operation={"listPickups"}
        inputType={"ListPickupsFilterInput"}
        statusCode={true}
      />
    ) : null
  }
  const ShipmentsWithProducts = () => {
    const inputProps = { hasProducts: true }
    return shipmentsPermission ? (
      <HeaderIcons
        icon={VscPackage}
        title={"shipmentsWithProducts"}
        pathname={"/admin/shipments?new=true&hasProducts=true"}
        type={"PRODUCTS"}
        operation={"listShipments"}
        inputType={"ListShipmentsFilterInput"}
        inputProps={inputProps}
      />
    ) : null
  }
  const ShipmentsWithRTS = () => {
    const inputProps = { typeCode: "RTS" }
    return shipmentsPermission ? (
      <HeaderIcons
        icon={VscPackage}
        title={"shipmentsWithRTS"}
        pathname={"/admin/shipments?new=true&typeCode=RTS"}
        type={"RTS"}
        operation={"listShipments"}
        inputType={"ListShipmentsFilterInput"}
        inputProps={inputProps}
      />
    ) : null
  }
  const ShipmentsWithCLC = () => {
    const inputProps = { typeCode: "CLC" }
    return shipmentsPermission ? (
      <HeaderIcons
        icon={VscPackage}
        title={"shipmentsWithCLC"}
        pathname={"/admin/shipments?new=true&typeCode=CLC"}
        type={"CLC"}
        operation={"listShipments"}
        inputType={"ListShipmentsFilterInput"}
        inputProps={inputProps}
      />
    ) : null
  }

  return (
    <Root className={classes.root}>
      <Fragment>
        <NotificationContainer
          newArrival={newArrival}
          setNewArrival={setNewArrival}
          notificationDrawer={notificationDrawer}
          notificationDrawerAnchor={drawerAnchor}
          notificationDrawerState={notificationDrawerState}
          setBadge={setBadge}
        />

        {hasMessagesPermission && <MessageContainer
          newArrival={newMessageArrival}
          setNewArrival={setNewMessageArrival}
          messageDrawer={messageDrawer}
          messageDrawerAnchor={drawerAnchor}
          messageDrawerState={messageDrawerState}
          setBadge={setBadge}
        />}

        <AppBar position="fixed" className={classes.appBar} sx={{ top: props.top }}>
          <Toolbar sx={{ padding: 0 }}>
            <Grid container justifyContent="space-between">
              <Grid item xs={5} className={classes.centerItems}>
                {MenuButton && <MenuButton />}
                <Link to={config.app.landingPage ? "/" : "/admin"}>
                  <LogoImg className={classes.logoImg} />
                </Link>
              </Grid>

              <Grid
                item
                xs={7}
                container
                justifyContent="flex-end"
                className={classes.centerItems}
              >
                <Grid>
                  {!addDashboardIcons && ShipmentsWithRTS && <ShipmentsWithRTS />}
                  {!addDashboardIcons && ShipmentsWithCLC && <ShipmentsWithCLC />}
                  {!addDashboardIcons && ShipmentsWithProducts && <ShipmentsWithProducts />}
                  {!addDashboardIcons && PaymentRequestsPermission && <PaymentRequestsPermission />}
                  {!addDashboardIcons && ReturnRequestsPermission && <ReturnRequestsPermission />}
                  {!addDashboardIcons && MaterialRequestsPermission && <MaterialRequestsPermission />}
                  {!addDashboardIcons && ComplaintsPermission && <ComplaintsPermission />}
                  {!addDashboardIcons && PickupsPermission && <PickupsPermission />}


                  {!addDashboardIcons && <GlobalSearch props={props.props} />}
                  {!addDashboardIcons && notificationIcon}
                  {!addDashboardIcons && messageIcon}
                  <AvatarComponent
                    addDashboardIcons={addDashboardIcons}
                    notificationIcon={notificationIcon}
                    messageIcon={messageIcon}
                    PaymentRequestsPermission={PaymentRequestsPermission}
                    ReturnRequestsPermission={ReturnRequestsPermission}
                    MaterialRequestsPermission={MaterialRequestsPermission}
                    ComplaintsPermission={ComplaintsPermission}
                    PickupsPermission={PickupsPermission}
                    ShipmentsWithRTS={ShipmentsWithRTS}
                    ShipmentsWithCLC={ShipmentsWithCLC}
                    ShipmentsWithProducts={ShipmentsWithProducts}
                    badge={badge}
                    open={open}
                    handleClick={handleClick}
                    handleCloseMenu={handleCloseMenu}
                    anchorEl={anchorEl}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {/* <div className={classes.drawerHeader} /> */}
      </Fragment>
    </Root>
  );
};

export default memo(Header);
