import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Paper,
  Switch,
  FormControlLabel,
  Collapse,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  Typography,
} from "@mui/material";
import { SACK_ID, SAVE_BRANCH, SAVE_SACK } from "./Graphql";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import BranchesTable from "./BranchesTable";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = "SacksForm";

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 2, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const SacksForm = (props) => {
  const [branchesIndex, setBranchesIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState(false);
  const [sackId, setSackId] = useState(parseInt(props.match.params.id));
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;
  // const [treasurersErrorMessage, setTreasurersErrorMessage] = useState(false);
  const user = Globals.user;
  const canAddBranch = user.hasPermission("shipping.sack.update")
  const {
    control: branchesControl,
    formState: { errors: branchesErrors },
    handleSubmit: branchesHandleSubmit,
    setError: branchesSetError,
    setValue: branchesSetValue,
    watch: branchesWatch,
    reset: branchesReset,
  } = useForm();

  const { data, loading, refetch } = useQuery(
    gql`
      ${SACK_ID.query}
    `,
    {
      skip: !sackId,
      variables: { id: sackId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const sacksData = data.sack;
        setBranchTable(sacksData.sackBranches ?? [])
        setActiveUser(sacksData?.active);
        const saveSafesParams = ["id", "name", "active"];
        saveSafesParams.forEach((param) => {
          sacksData[param] && setValue(param, sacksData[param]);
        });
      },
    }
  );

  const branchTableArr = data?.sack.sackBranches ?? [];
  const [branchTable, setBranchTable] = useState(branchTableArr)
  const onSubmitBranch = (data) => {
    const sackBranches = [
      {
        branchId: data.branchId,
        quantity: parseInt(data.sackBranches0quantity),
      },
    ];
    saveBranch({
      variables: {
        input: {
          ...(sackId && { sackId }),
          sackBranches,
        },
      },
    });
  };

  const [saveSack, { loading: saveSackLoading }] = useMutation(
    gql`
      ${SAVE_SACK.query}
    `
  );

  const [saveBranch, { loading: saveBranchLoading }] = useMutation(
    gql`
      ${SAVE_BRANCH.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        handelCloseDialog();
        setBranchTable(data.saveSackBranches)
        // refetch();
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          TransitionComponent: Collapse,
        });
      },
      onError: ({ graphQLErrors }) => {
        setValidationError(graphQLErrors, branchesSetError, null, true);
        // enqueueSnackbar(error.message, {
        //   variant: 'error',
        //   anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        //   TransitionComponent: Collapse,
        // });
      },
    }
  );
  const [pathURL, setPathURL] = useState(props.match.path)
  const history = useHistory();

  const [activeUser, setActiveUser] = useState(true);

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };

  const onSubmit = (data) => {
    saveSack({
      variables: {
        input: {
          ...(sackId && { id: sackId }),
          name: data.name,
          active: activeUser,
        },
      },
    })
      .then((response) => {
        const url = history.createHref({
          pathname: `/admin/sacks/${response.data.saveSack.id}/edit`,
        });
        setPathURL(url)
        setValue("id", response.data.saveSack.id)
        setSackId(response.data.saveSack.id)
        windowReplaceUrl(url);
        // pushUrl(props, `/admin/sacks/${response.data.saveSack.id}/edit`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };

  const handelCloseDialog = () => {
    setDialog(false);
  };

  const addBranchesDialog = (index) => {
    branchesReset();
    if (index || index === 0) {
      setBranchesIndex({
        index,
        update: true,
      });
    } else {
      setBranchesIndex({
        index,
        update: false,
      });
    }
    setDialog(true);
  };

  const branchesForm = (
    <BranchesForm
      handleSubmit={branchesHandleSubmit}
      onSubmitBranch={onSubmitBranch}
      control={branchesControl}
      errors={branchesErrors}
      setValue={branchesSetValue}
      watch={branchesWatch}
      handelCloseDialog={handelCloseDialog}
      branchesIndex={branchesIndex}
      setBranchesIndex={setBranchesIndex}
      branches={branchTable}

    />
  );
  const formCondition = loading && sackId
  return <>
    {formCondition ? <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height="100%" />
    </StyledLoading> :
      <><Dialog fullWidth maxWidth="xs" open={dialog} onClose={handelCloseDialog}>
        {branchesForm}
      </Dialog>
        <Root onSubmit={handleSubmit(onSubmit)}>
          <TitleAppBar path={pathURL} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            className={clsx(classes.mainGrid)}
            spacing={2}
          >
            <Paper container component={Grid} className={clsx(classes.spacing)}>
              <Grid container justifyContent="end" xs={12}>
                <FormControlLabel
                  checked={activeUser}
                  control={<Switch color="primary" />}
                  label={t("active")}
                  labelPlacement="start"
                  onChange={handelActivate}
                />
              </Grid>
              {/* <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name="id"
                  label={t("code")}
                />
              </Grid> */}
              <Grid xs={12} sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name="name"
                  label={t("name")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
            </Paper>
            <Grid container justifyContent="flex-end" className={classes.spacing}>
              <FormButton disabled={saveSackLoading || (sackId && !canAddBranch)}>
                {saveSackLoading ? <ButtonLoading /> : t("save")}
              </FormButton>
            </Grid>
            {(
              <BranchesTable
                branches={branchTable}
                setBranches={setBranchTable}
                addBranches={addBranchesDialog}
                sackId={sackId}
                refetch={refetch}
                loading={loading}
                permission={canAddBranch}
              // setBranchesList={setBranchesList}
              />
            )}
          </Grid>
        </Root></>}

  </>

};

export default SacksForm;

function BranchesForm({
  handleSubmit,
  onSubmitBranch,
  control,
  errors,
  handelCloseDialog,
  setValue,
  branchesIndex,
  branches,
  setBranchesIndex,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
  });
  useEffect(() => {
    if (branchesIndex?.update) {
      const update = branches[branchesIndex?.index];
      setValue("sackBranches0quantity", update?.quantity);
      setAutocompleteValues({
        branch: update?.branch,
      });
      setBranchesIndex();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchesIndex]);
  return (
    <form onSubmit={handleSubmit(onSubmitBranch)}>
      <DialogTitle id="scroll-dialog-title">
        <Grid container justifyContent="space-between" xs={12}>
          <Typography variant="h6" color={"text.primary"}>
            {t("addBranch")}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ListBranches
          control={control}
          errors={errors}
          name="branchId"
          disabled={autocompleteValues.branch ? true : false}
          skipDefaultBranch={true}
          branches={branches}
          rules={{ required: t("fieldIsRequired") }}
          defaultValue={autocompleteValues.branch}
        />
        <ControlMUItextField
          control={control}
          errors={errors}
          name={`sackBranches0quantity`}
          label={t("quantity")}
          margin="normal"
          rules={{ required: t("fieldIsRequired") }}
          type="number"
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handelCloseDialog}>
          {t("cancel")}
        </Button>
        <Button color="primary" type="submit">
          {t("confirm")}
        </Button>
      </DialogActions>
    </form>
  );
}
