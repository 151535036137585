import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
import CellLink from "../HOC/CustomComponents/CellLink";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { DELETE_SACK_BRANCHES } from "./Graphql";
import { gql, useMutation } from "@apollo/client";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";

const PREFIX = "BranchesTable";

const classes = {
  p: `${PREFIX}-p`,
  tap: `${PREFIX}-tap`,
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  destinations: `${PREFIX}-destinations`,
  iconColor: `${PREFIX}-iconColor`,
  deleteIcon: `${PREFIX}-deleteIcon`,
  main: `${PREFIX}-main`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.p}`]: {
    fontSize: "14px",
    margin: "2px 1px",
    width: 100,
  },

  [`& .${classes.tap}`]: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // flexDirection: "column",
    textTransform: "capitalize",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.destinations}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.deleteIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const BranchesTable = (props) => {
  const { branches, addBranches, setBranches, permission } = props;
  const sackId = props?.sackId;

  const { t } = useTranslation();
  const [branchesIndex, setBranchesIndex] = useState(0);
  const [branchesPage, setBranchesPage] = useState(0);
  const [branchesRowsPerPage, setBranchesRowsPerPage] = useState(20);
  const [loadingBranches, setLoadingBranches] = useState({});

  const handleBranchesPage = (event, newPage) => {
    setBranchesPage(newPage);
  };
  const handleBranchesRowsPerPage = (event) => {
    setBranchesRowsPerPage(parseInt(event.target.value));
    setBranchesPage(0);
  };
  const [deleteSackBranches, { loading: deleteLoading }] = useMutation(
    gql`
      ${DELETE_SACK_BRANCHES.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const handleDelete = (branchId) => {
    setLoadingBranches(prevState => ({ ...prevState, [branchId]: true }));
    deleteSackBranches({
      variables: {
        input: {
          sackId: sackId,
          branchIds: [branchId]
        }
      },
    }).then(() => {
      setLoadingBranches(prevState => ({ ...prevState, [branchId]: false }));
      const updatedBranches = branches.filter(branch => branch.branch.id !== branchId);
      setBranches(updatedBranches);
    }).catch((error) => {
      setLoadingBranches(prevState => ({ ...prevState, [branchId]: false }));
      console.error("Error deleting branch:", error);
    });
    // refetch()
  };

  return (
    <StyledGrid container alignItems="flex-start">
      {<Grid container item sm={12} component={Paper}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("branches")}
          </Typography>

          {addBranches ? (
            <IconButton
              color="primary"
              onClick={() => {
                addBranches();
              }}
              aria-label="Add branches"
              size="large"
              disabled={!sackId || !permission}
            >
              <Add />
            </IconButton>
          ) : null}
        </Toolbar>
        <Grid container item className={classes.table}>
          {branches?.length === 0 || !branches ? (
            <EmptyTableMessage loading={false} message={t("noBranches")} />
          ) : (
            <TableFixedHeaderWraper className={classes.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{'#'}</FixedTableCell>
                    <FixedTableCell>{t("branch")}</FixedTableCell>
                    <FixedTableCell>{t("availableQuantity")}</FixedTableCell>
                    {addBranches ? <FixedTableCell></FixedTableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branches?.length > 0 &&
                    (branchesRowsPerPage > 0
                      ? branches.slice(
                        branchesPage * branchesRowsPerPage,
                        branchesPage * branchesRowsPerPage +
                        branchesRowsPerPage
                      )
                      : branches
                    ).map((row, index) => (
                      <TableRow
                        className={clsx({
                          [classes.focus]:
                            branchesPage * branchesRowsPerPage + index ===
                            branchesIndex,
                        })}
                        hover
                        key={index}
                        onClick={() => {
                          setBranchesPage(0);
                          setBranchesIndex(
                            branchesPage * branchesRowsPerPage + index
                          );
                        }}
                      >
                        <FixedTableCell>{index + 1}</FixedTableCell>
                        <CellLink
                          pathname={`/admin/branches/${row?.branch?.id}`}
                          key={index}
                        >
                          {row.branch.name}
                        </CellLink>
                        <FixedTableCell>{row?.quantity}</FixedTableCell>
                        {addBranches ? (
                          <FixedTableCell>
                            <IconButton
                              size="small"
                              onClick={() =>
                                addBranches(
                                  branchesPage * branchesRowsPerPage + index
                                )
                              }
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              size="small"
                              className={classes.deleteIcon}
                              onClick={() => handleDelete(row?.branch?.id)}
                              disabled={deleteLoading}
                            >
                              {loadingBranches[row?.branch?.id] ? <CustomSpinner name="ClockLoader" size={20} /> : <Delete />}
                            </IconButton>
                          </FixedTableCell>
                        ) : null}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          )}
          <MUITablePagination
            count={branches?.length ?? 0}
            rowsPerPage={branchesRowsPerPage}
            page={branchesPage}
            onPageChange={handleBranchesPage}
            onRowsPerPageChange={handleBranchesRowsPerPage}
          />
        </Grid>
      </Grid>}


    </StyledGrid>
  );
};

export default BranchesTable;
