import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Icon,
  Button,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Add, FilterList, Search } from "@mui/icons-material";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_SACKS } from "./Graphql";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import MUIselect from "../HOC/MUI/MUIselect";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_BRANCHES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";

const PREFIX = "ListSacks";

const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline"
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

const ListSacks = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [refetch, setRefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const filterAnchor = props.width === "xs" ? "bottom" : "left";
  const validUrlParameters = Object.keys(urlQuery).length !== 0
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const [showBranch, setShowBranch] = useState(null);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
  });
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: props.width === "xs" ? false : true,
    bottom: props.width === "xs" ? false : true,
    right: props.width === "xs" ? false : true,
  });

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      // if (urlQuery["code"]) urlQuery["name"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    urlQuery["withAvailableStock"] !== undefined && setValue("withAvailableStock", urlQuery["withAvailableStock"]);
    urlQuery["withAvailableStock"] !== undefined && setShowBranch(urlQuery["withAvailableStock"]);
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    // urlQuery["code"] && setValue("code", urlQuery["code"]);

    return () => { };
  }, []);

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/sacks",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setPage(newPage)
    setSearch((prev) => ({ ...prev }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });
    setPage(0)
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev }));
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const parseData = (data) => {
    return data;
  };
  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    setRefetch((prev) => !prev)

    let handledData = {
      active: data.active,
      withAvailableStock: data.withAvailableStock,
      branchId: data.branchId,
      // customerId: data.customerId,
      name: data.name,
      code: data.code,
    };
    data["active"] === "" && delete handledData["active"];
    data["withAvailableStock"] === "" && delete handledData["withAvailableStock"];
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_SACKS(search.branchId).query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...(search.name && { name: search.name }),
          active: search.active,
          withAvailableStock: search.withAvailableStock,
          ...(search.branchId && {
            branchId: search.branchId,
          })
        },
      },
      onCompleted: (data) => { },
    }
  );

  const listSacks = data?.listSacks?.data !== null ? data?.listSacks?.data : null;


  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => pushUrl(props, `/admin/sacks/create`),
      icon: Add,
      permission: "shipping.sack.create",
    },
  ]
  const tabHead = [

    {
      name: "name"
    },
    // {
    //   name: "code"
    // },
    {
      name: "totalQuantity"
    },
    {
      name: "active"
    },
  ]
  if (search.withAvailableStock === true && search.branchId) {
    tabHead.splice(2, 0, {
      name: "availableQuantity"
    })
  }
  return (
    <Root>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listSacks?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"withAvailableStock"}
                  label={t("branchAvailableStock")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notAvailable"), value: false },
                    { key: t("available"), value: true },
                  ]}
                  onChanges={(e) => {
                    const value = e.target.value === '' ? null : e.target.value
                    e.target.value === '' && setValue("branchId", "")
                    setShowBranch(value)
                  }}
                />
              </Grid>
              {showBranch !== null && (<Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  label={t("branch")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>)}
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          tabHead
        }
        tableBody={
          <TableBody>
            {listSacks &&
              listSacks?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <CellLink pathname={`/admin/sacks/${row?.id}`}>
                      {row?.name}
                    </CellLink>
                    {/* <FixedTableCell>{row?.id}</FixedTableCell> */}
                    <FixedTableCell>{row?.quantity}</FixedTableCell>
                    {search.withAvailableStock === true && search.branchId && <FixedTableCell>{row?.availableQuantity}</FixedTableCell>}

                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }

        pagination={
          <MUITablePagination
            count={data?.listSacks?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listSacks ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default ListSacks;
