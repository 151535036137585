import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  TableBody,
  TableHead,
  TableRow,
  Button,

  Icon,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { useForm } from "react-hook-form";
import { FilterList, Add, Search } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import MUIselect from "../HOC/MUI/MUIselect";
import moment from "moment";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Can } from "../HOC/CustomComponents/Secured";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import CellLink from "../HOC/CustomComponents/CellLink";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import useWidth from "../../Hooks/useWidth";
import { dateTimeFormatA } from "../../helpers/dateFunctions";
import { GetManifestPermissionSlug } from "../../helpers/getPermissionSlug";
import formatMoney from "../../helpers/numbersDot";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import { LIST_MANIFESTS_QUERY, SUM_MANIFEST_QUERY } from "./ManifestGraphQl";

const PREFIX = "ManifestList";

const classes = {
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    marginTop: 10,
    padding: 7,
  },


  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

///////////////////////////////////////////////////

//////////////////////////////////////////////////
//*********Table Function*********

function EnhancedTableHead(props) {
  // const {  numSelected, rowCount, } = props;
  const { type } = props;
  const { t } = useTranslation();
  const headCells = [
    { id: "1", numeric: true, disablePadding: false, label: t("manifestCode") },
    { id: "2", numeric: true, disablePadding: false, label: t("date") },
    {
      id: "3",
      numeric: true,
      disablePadding: false,
      label: t("branch"),
    },
    {
      id: "4",
      numeric: true,
      disablePadding: false,
      label: t("shippingAgent"),
    },
    {
      id: "5",
      numeric: true,
      disablePadding: false,
      label: t("transactionTypeName"),
    },
    { id: "6", numeric: true, disablePadding: false, label: t("shipments") },
    { id: "7", numeric: true, disablePadding: false, label: t("approved") },
  ];
  if (['prp', 'upkbd', 'upkbl'].includes(type)) {
    delete headCells[3];
  }
  if (type === "bmt" || type === "bmr") {
    const referenceBranch = {
      id: "8",
      numeric: true,
      disablePadding: false,
      label: type === "bmt" ? t("toBranch") : t("fromBranch"),
    };
    headCells.splice(3, 0, referenceBranch);
  }
  if (type === "pkm" || type === "otr" || type === "rtrn") {
    const customer = {
      id: "9",
      numeric: true,
      disablePadding: false,
      label: t("customer"),
    };
    headCells.splice(3, 0, customer);
  }
  if (type === "otr" || type === "rtrn") {
    const representative = {
      id: "12",
      numeric: true,
      disablePadding: false,
      label: t("representative"),
    };
    headCells.splice(4, 0, representative);
  }
  if (type === "rtrn") {
    const customer = {
      id: "10",
      numeric: true,
      disablePadding: false,
      label: t("returnedValue"),
    };
    headCells.splice(7, 0, customer);
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <FixedTableCell
            className={classes.headerTable}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </FixedTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/////////////////DIALOG///////////////

const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const ManifestList = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const { t } = useTranslation();

  const [refetch, setRefetch] = useState(true);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters ? null : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters ? null : initDate;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);
  const [filterLoading, setFilterLoading] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);

  delete urlQuery["rowsPerPage"];
  delete urlQuery["page"];

  const [autocompleteValues, setAutocompleteValues] = useState({
    deliveryAgent: null,
    customer: null,
    toZone: null,
    toSubzone: null,
    fromZone: null,
    fromSubzone: null,
  });

  const screenWidth = useWidth();

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const listType = props.match.params.type;

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm();

  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const initSearch = () => {
    let searchParameters = {
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };

  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: `/admin/manifests/${listType}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const { data, loading } = useQuery(
    gql`
      ${LIST_MANIFESTS_QUERY(listType).query}
    `,
    {
      partialRefetch: refetch,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          transactionTypeCode: listType.toUpperCase(),
          approved: search.approved,
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.code && { code: search.code }),
          ...(search.withAvailableShipments && { withAvailableShipments: search.withAvailableShipments }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.deliveryAgentId && { deliveryAgentId: search.deliveryAgentId }),
          ...(search.fromZoneId && { fromZoneId: search.fromZoneId }),
          ...(search.fromSubzoneId && { fromSubzoneId: search.fromSubzoneId }),
          ...(search.toZoneId && { toZoneId: search.toZoneId }),
          ...(search.priceListId && { priceListId: search.priceListId }),
          ...(search.withDue && { withDue: search.withDue }),
          ...(search.branchId && { branchId: search.branchId }),
        },
      },
    }
  );

  const { data: sum } = useQuery(
    gql`
      ${SUM_MANIFEST_QUERY(listType).query}
    `,
    {
      partialRefetch: refetch,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          transactionTypeCode: listType.toUpperCase(),
          approved: search.approved,
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.code && { code: search.code }),
          ...(search.transactionTypeCode && { transactionTypeCode: search.transactionTypeCode }),
          ...(search.withAvailableShipments && { withAvailableShipments: search.withAvailableShipments }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.deliveryAgentId && { deliveryAgentId: search.deliveryAgentId }),
          ...(search.fromZoneId && { fromZoneId: search.fromZoneId }),
          ...(search.fromSubzoneId && { fromSubzoneId: search.fromSubzoneId }),
          ...(search.toZoneId && { toZoneId: search.toZoneId }),
          ...(search.toSubzoneId && { toSubzoneId: search.toSubzoneId }),
          ...(search.priceListId && { priceListId: search.priceListId }),
          ...(search.withDue && { withDue: search.withDue }),
          ...(search.branchId && { branchId: search.branchId }),
        },
      },
    }
  );

  const manifestListData =
    data?.listManifests?.data !== null ? data?.listManifests?.data : null;
  const emptyManifestListData = data?.listManifests?.data?.length === 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({ ...search, page: newPage, rowsPerPage: rowsPerPage, });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSearch((prev) => ({ ...prev }));
    pushUrlSearch({ ...search, page: 0, rowsPerPage: +event.target.value });
  };
  const resetDate = () => {
    setDateRange([null, null]);
  };

  const parseData = (data) => {
    return data;
  };

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    setRefetch(prev => !prev)
    setFilterLoading(false)
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      code: data.code,
      ...data,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      page: page,
      rowsPerPage: rowsPerPage,
    });
  };

  const addURL = () => {
    pushUrl(props, `/admin/manifests/create/${listType}`);
  }

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !state[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: GetManifestPermissionSlug(listType, 'create'),
    },
  ]

  const branchTransferCondition = listType === "bmt" || listType === "bmr";
  const customerCondition = listType === "pkm" || listType === "otr" || listType === "rtrn";

  return (
    <Root>
      <ListWrapper
        drawerState={state[filterAnchor]}
        icons={icons}
        path={props.match.path}
        type={listType}
        empty={emptyManifestListData}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} xs={12}>
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  resetDate={resetDate}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  name={"branchId"}
                  label={t("currentBranch")}
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  parseData={(data) => parseData(data)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <MUIselect
                  control={control}
                  errors={errors}
                  name={"approved"}
                  label={t("status")}
                  data={[
                    { key: t("approved"), value: true },
                    { key: t("notApproved"), value: false },
                  ]}
                  defaultValue={urlQuery?.approved}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  name={"deliveryAgentId"}
                  label={t("shippingAgent")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["deliveryAgentId"],
                      "deliveryAgent"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                  variables={{
                    input: {
                      active: true,
                    },
                  }}
                  defaultValue={autocompleteValues.deliveryAgent}
                />
              </Grid>
              <Can showException permission={customerCondition}>
                <Grid item sm={12} xs={12}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    skip={loading && filterLoading}
                    name={"customerId"}
                    label={t("customer")}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["customerId"],
                        "customer"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_CUSTOMERS_DROPDOWN.query}
                    variables={{
                      input: {
                        active: true,
                      },
                    }}
                    defaultValue={autocompleteValues.customer}
                  />
                </Grid>
              </Can>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  name={"fromZoneId"}
                  label={t("fromZone")}
                  onChangeValue={(e) => {
                    setValue("fromSubzoneId", "");
                    delete urlQuery["fromSubzoneId"];

                    pushUrlSearch({ ...urlQuery });
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["fromZoneId"],
                      "fromZone"
                    )
                  }
                  variables={{ input: { parentId: null } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.fromZone}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"fromSubzoneId"}
                  label={t("fromSubzone")}
                  skip={!watch("fromZoneId") || loading}
                  disabled={!watch("fromZoneId")}
                  variables={{ input: { parentId: watch("fromZoneId") } }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["fromSubzoneId"],
                      "fromSubzone"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.fromSubzone}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  name={"toZoneId"}
                  label={t("toZone")}
                  onChangeValue={(e) => {
                    delete urlQuery["toSubzoneId"];
                    setValue("toSubzoneId", "");

                    pushUrlSearch({ ...urlQuery });
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["toZoneId"],
                      "toZone"
                    )
                  }
                  variables={{ input: { parentId: null } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.toZone}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"toSubzoneId"}
                  label={t("toSubzone")}
                  skip={!watch("toZoneId") || loading}
                  disabled={!watch("toZoneId")}
                  variables={{ input: { parentId: watch("toZoneId") } }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["toSubzoneId"],
                      "toSubzone"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.toSubzone}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          <EnhancedTableHead
            type={listType}
            rowCount={manifestListData?.length}
          />
        }
        tableBody={
          <TableBody>
            {manifestListData &&
              manifestListData?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   pushUrl(props, `/admin/manifests/${row?.id}/edit`);
                  // }}
                  >
                    <CellLink
                      component="th"
                      scope="row"
                      pathname={`/admin/manifests/${row?.id}`}
                    >
                      {row.code}
                    </CellLink>
                    <FixedTableCell>
                      {dateTimeFormatA(row?.date)}
                    </FixedTableCell>
                    <CellLink
                      pathname={`/admin/branches/${row?.branch?.id}`}
                    >
                      {row?.branch?.name}
                    </CellLink>

                    {branchTransferCondition ? (
                      <CellLink
                        pathname={`/admin/branches/${row?.referenceBranch?.id}`}
                      >
                        {row?.referenceBranch?.name}
                      </CellLink>
                    ) : null}
                    {customerCondition ? (
                      <CellLink
                        pathname={`/admin/customers/${row?.customer?.id}`}
                      >
                        {row?.customer?.name}
                      </CellLink>
                    ) : null}
                    {['rtrn', "otr"].includes(listType) ? (
                      <FixedTableCell >
                        {row?.representative?.name}
                      </FixedTableCell>
                    ) : null}
                    {!['prp', 'upkbd', 'upkbl'].includes(listType) ? (
                      <CellLink
                        pathname={`/admin/delivery-agents/${row?.deliveryAgent?.id}`}
                      >
                        {row?.deliveryAgent?.name}
                      </CellLink>
                    ) : null}
                    {row?.transactionType ? (
                      <CellLink
                        pathname={`/admin/transaction-types/${row?.transactionType?.id}`}
                      >
                        {row?.transactionType?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}

                    <FixedTableCell>{row?.entries.paginatorInfo.total}</FixedTableCell>
                    {listType === "rtrn" ? (
                      <FixedTableCell>{formatMoney(row?.sumEntries?.returnedValue)}</FixedTableCell>
                    ) : null}
                    <FixedTableCell>
                      {row?.approved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
            {sum?.sumManifests && (
              <TableRow hover>
                <FixedTableCell
                  colSpan={
                    (['prp', 'upkbl', 'upkbd'].includes(listType) && 3) ||
                    (['rtrn', 'otr'].includes(listType) && 6) ||
                    (branchTransferCondition || customerCondition
                      ? 5
                      : 4)
                  }
                />
                <FixedTableCell>{t("total")}</FixedTableCell>
                <FixedTableCell>
                  {sum?.sumManifests?.shipmentsCount}
                </FixedTableCell>
                {listType === "rtrn" && <FixedTableCell>
                  {formatMoney(sum?.sumManifests?.returnedValue)}
                </FixedTableCell>}
                <FixedTableCell />
              </TableRow>
            )}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listManifests?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listManifests ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default ManifestList;
