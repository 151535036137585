import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Route, Switch } from "react-router";
import NotFound from "../../Error/NotFound";
import HomePageLayout from "../../Layout/HomePageLayout";
import Layout from "../../Layout/Layout";
import LayoutWithDrawer from "../../Layout/LayoutWithDrawer";
import BranchList from "../Branch/BranchList";
import BranchView from "../Branch/BranchView";
import BranchForm from "../Branch/CreateBranch";
import CancellationReasonForm from "../CancellationReasons/CancellationReasonForm";
import CancellationReasonsList from "../CancellationReasons/CancellationReasonsList";
import { ChangePassword } from "../ChangePassword/ChangePassword";
import CommissionListForm from "../CommissionList/CommissionListForm";
import CommissionListView from "../CommissionList/CommissionListView";
import ListCommissions from "../CommissionList/ListCommissionList";
import CustomerList from "../Customers/CustomerList";
import CustomerForm from "../Customers/Customers";
import CustomerView from "../Customers/CustomerView";
import OrganizationDataForm from "../OrganizationData/UpdateOrganizationData";
import OrganizationDataView from "../OrganizationData/OrganizationDataView";
import ProductList from "../Product/ProductList";
import ProductsForm from "../Product/Product";
import ProductView from "../Product/ProductView";
import ProductsTransactions from "../Product/ProductsTransactions";
import ManifestProductList from "../ManifestsProducts/ManifestList";
import ManifestProductView from "../ManifestsProducts/ManifestView";
import manifestsProductsForm from "../ManifestsProducts/ManifestForm";
import DeliveryAgent from "../DeliveryAgents/DeliveryAgent";
import DeliveryAgentView from "../DeliveryAgents/DeliveryAgentView";
import ListDeliveryAgents from "../DeliveryAgents/ListDeliveryAgents";
import TrackDeliveryAgent from "../DeliveryAgents/TrackDeliveryAgent/TrackDeliveryAgent";
import CollectionForm from "../Collection/CollectionForm";
import CollectionList from "../Collection/CollectionList";
import CollectionView from "../Collection/CollectionView";
import DeliveryListActions from "../DeliveryListActions/DeliveryListActions";
import EInvoiceList from "../EInvoice/EInvoiceList";
import FinanceStatement from "../FinanceStatement/FinanceStatement";
import MainFinanceStatement from "../FinanceStatement/MainFinanceStatement";
import TrialBalance from "../FinanceStatement/TrialBalance";
import AccountStatementPrint from "../FinanceStatement/PrintFinanceStatement";
import MainAccountStatementPrint from "../FinanceStatement/PrintMainFinanceStatement";
import GlAccountsTree from "../GlAccountsTree/GlAccountsTree";
import Home from "../Home/Home";
import HomePage from "../HomePage";
import InvoicesForm from "../Invoices/InvoicesForm";
import InvoicesList from "../Invoices/InvoicesList";
import InvoicesView from "../Invoices/InvoicesView";
import JornalEntryForm from "../JournalEntry/JournalEntryForm";
import JournalEntryView from "../JournalEntry/JournalEntryView";
import ListJournalEntry from "../JournalEntry/ListJournalEntry";
import JournalTypeList from "../JournalType/JournalTypeList";
import Login from "../Login/Login";
import LogList from "../LogList/LogList";
import ManifestPrint from "../ManifestPrint/ManifestPrint";
import ManifestForm from "../Manifests/ManifestForm";
import ManifestList from "../Manifests/ManifestList";
import PaymentForm from "../Payment/PaymentForm";
import PaymentList from "../Payment/PaymentList";
import PaymentView from "../Payment/PaymentView";
import ListPickups from "../Pickup/ListPickups";
import PickupForm from "../Pickup/PickupForm";
import PickupView from "../Pickup/PickupView";
import ListWarehousePickups from "../WarehousePickup/ListWarehousePickups";
import WarehousePickupForm from "../WarehousePickup/WarehousePickupForm";
import WarehousePickupView from "../WarehousePickup/WarehousePickupView";
import PriceListForm from "../PriceList/CreatePriceList";
import ListPriceList from "../PriceList/ListPriceList";
import PriceListView from "../PriceList/PriceListView";
import Profile from "../Profile/Profile";
import ReceiveUndeliveredShipments from "../ReceiveUndeliveredShipments/ReceiveUndeliveredShipments";
import UnpackedShipments from "../UnpackedShipments/UnpackedShipments";
import Registration from "../Registration/Registration";
import Registrations from "../Registrations/Registrations";
import ListRoles from "../Roles/ListRoles";
import RoleForm from "../Roles/RoleForm";
import RoleView from "../Roles/RoleView";
import ListRoutes from "../Routes/ListRoutes";
import RouteForm from "../Routes/RouteForm";
import RouteView from "../Routes/RouteView";
import ListSafes from "../Safes/ListSafes";
import SafeForm from "../Safes/SafeForm";
import SafeView from "../Safes/SafeView";
import Stocktake from "../Stocktake/Stocktake";
import Sheets from "../Sheets/Sheets";
import ImportShipments from "../Shipments/ImportShipments";
import SaveShipment from "../Shipments/SaveShipment";
import ShipmentPrint from "../Shipments/ShipmentPrint";
import Shipments from "../Shipments/Shipments";
import ShipmentsTransactions from "../Shipments/ShipmentsTransactions";
import ShipmentStickyPrint from "../Shipments/Waybill/ShipmentStickyPrint";
import ShipmentStickyPrint7X9 from "../Shipments/Waybill/ShipmentStickyPrint7X9";
import ShipmentStickyPrint10X15 from "../Shipments/Waybill/ShipmentStickyPrint10X15";
import ShipmentsView from "../Shipments/ShipmentsView";
import NotPaidToCustomer from "../ShipmentsQuery/NotPaidToCustomer";
import ShipmentsInWarehouse from "../ShipmentsQuery/ShipmentsInWarehouse";
import UncollectedFromDeliveryAgents from "../ShipmentsQuery/UncollectedFromDeliveryAgents";
import ListTransactionTypes from "../TransactionTypes/ListTransactionTypes";
import TransactionType from "../TransactionTypes/TransactionType";
import TransactionTypeView from "../TransactionTypes/TransactionTypeView";
import ShippingService from "../ShippingServices/ShippingServiceForm";
import ShippingServicesList from "../ShippingServices/ShippingServicesList";
import ShippingServiceView from "../ShippingServices/ShippingServiceView";
import SubsidiariesList from "../Subsidiary/SubsidiariesList";
import SubsidiaryForm from "../Subsidiary/SubsidiaryForm";
// import GlobalTrack from "../TrackShipment/GlobalTrack";
import LandingPageTrackShipment from "../TrackShipment/LandingPageTrackShipment";
import ListUsers from "../Users/ListUsers";
import UserForm from "../Users/UserForm";
import UserView from "../Users/UserView";
import ListZones from "../Zones/ListZones";
import ZoneForm from "../Zones/ZoneForm";
import ZoneView from "../Zones/ZoneView";
import WareHouse from "../WareHouse/WareHouseList";
import WareHouseView from "../WareHouse/WareHouseView";
import WareHouseForm from "../WareHouse/WareHouseForm";
import Lookup from "../Lookup/LookupList";
import LookupView from "../Lookup/LookupView";
import LookupEntriesForm from "../Lookup/LookupEntriesForm";
import InvoicePrint from "../Invoices/printInvoice";
import VoucherList from "../Vouchers/VoucherList";
import VoucherView from "../Vouchers/VoucherView";
import VoucherForm from "../Vouchers/VoucherForm";
import VoucherPrint from "../Vouchers/VoucherPrint";
import PickupPrint from "../Pickup/printPickup";
import ConciergePrint from "../ConciergeRequest/PrintConciergeList";
import Forgotpassword from "../Forgotpassword/Forgotpassword";
import PaymentPrint from "../Payment/printPaymentList";
import CollectionPrint from "../Collection/printCollectionList";
import { Globals } from "./Classes/Globals";
import GenrateBarcode from "../GenrateBarcode/GenrateBarcodesForm";
import GenrateBarcodesList from "../GenrateBarcode/GenrateBarcodesList";
import GenrateBarcodesView from "../GenrateBarcode/GenrateBarcodesView";
import { GetPermissionSlugWithArray } from "../../helpers/getPermissionSlug";
import Renewal from "../Renewal/Renewal";
import SheetsPrint from "../Sheets/PrintSheets";
import Categories from "../Categories/CategoriesList";
import Teams from "../Teams/TeamsList";
import Tickets from "../Tickets/TicketsList";
import TicketsView from "../Tickets/TicketsView";
import CustomerNewShipment from "../Shipments/CustomerNewShipment";
import CustomerRequestsList from "../CustomerRequests/CustomerRequestsList";
import CustomerRequestsForm from "../CustomerRequests/CustomerRequestsForm";
import CustomerRequestsView from "../CustomerRequests/CustomerRequestsView";
import ManifestTransferList from "../ManifestsTransfer/ManifestTransferList";
import ManifestTransferForm from "../ManifestsTransfer/ManifestTransferForm";
import ViewRouteManifest from "../ManifestRoute/ViewRouteManifest";
import ShipmentStickyPrint10X10 from "../Shipments/Waybill/ShipmentStickyPrint10X10";
import ShipmentStickyPrint2X5 from "../Shipments/Waybill/ShipmentStickyPrint2X5";
import PrintShipmentList from "../Shipments/PrintShipmentList";
import TotalsPerZones from "../Reports/TotalsPerZones";
import PrintTotalsPerZones from "../Reports/PrintTotalsPerZones";
import TotalsPerFees from "../Reports/TotalsPerFees";
import PrintTotalsPerFees from "../Reports/PrintTotalsPerFees";
import Review from "../Review/Review";
import UserLocation from "../UserLocation/userLocation";
// import ShipmentStickyPrint2X5 from "../Shipments/ShipmentStickyPrint2X5";
import AdminAlert from "../AdminAlerts/AdminAlertList";
import DeliveryAgentReviews from "../DeliveryAgentReviews/DeliveryAgentReviews";
import ShipmentStickyPrint17X10 from "../Shipments/Waybill/ShipmentStickyPrint17X10";
import DeliveryAgentClearanceForm from "../DeliveryAgentClearance/DeliveryAgentClearanceForm";
import DeliveryAgentClearance from "../DeliveryAgentClearance/DeliveryAgentClearance";
import DeliveryAgentIntegrationForm from "../DeliveryAgents/DeliveryAgentsIntegration/DeliveryAgentIntegrationForm";
import CustomerDueInquiry from "../Customers/CustomerDueInquiry";
import PrintCustomerDueInquiry from "../Customers/PrintCustomerDueInquiry";
import ProductStickyPrint from "../Shipments/Waybill/ProductStickyPrint";
import CurrencyList from "../Currency/CurrencyList";
import ConciergeRequestList from "../ConciergeRequest/ConciergeRequestList";
import ConciergeRequestForm from "../ConciergeRequest/ConciergeRequestForm";
import ConciergeRequestView from "../ConciergeRequest/ConciergeRequestView";
import FinancialYear from "../FinancialYear/FinancialYear";
import FinancialYearList from "../FinancialYear/ListFinancialYear";
import ConciergeProvidersList from "../ConciergeProviders/ConciergeProvidersList";
import FinancialYearView from "../FinancialYear/FinancialYearView";
import UncollectedFromDeliveryAgentsConcierge from "../ConciergeRequest/UncollectedFromDeliveryAgentsConcierge";
import IdleShipments from "../ShipmentsQuery/IdleShipments";
import ImportShipmentsMessages from "../Shipments/ImportShipmentsMessages";
import BankList from "../Banks/BankList";
import PrintShipmentListFilter from "../ShipmentsQuery/PrintShipmentListFilter";
import ListSacks from "../Sacks/ListSacks";
import SacksForm from "../Sacks/SacksForm";
import SacksView from "../Sacks/SacksView";
// import SafesTransferPrint from "../SafesTransfer/SafesTransferPrint";
// import SafesTransferList from "../SafesTransfer/SafesTransferList";
// import SafesTransferForm from "../SafesTransfer/SafesTransferForm";
// import SafesTransferView from "../SafesTransfer/SafesTransferView";
const Settings = React.lazy(() => import("../Setting/Settings"));

const AccountingSettings = React.lazy(() =>
  import("../AccountingSettings/AccountingSettings")
);

const Routes = (props) => {
  return (
    <Fragment>
      <Switch>
        {/* --------------------------------- Public --------------------------------- */}
        <Route path="/" exact component={HomePage} />
        <RouteWrapper path="/login" component={Login} layout={Layout} />
        <RouteWrapper path="/renewal" component={Renewal} layout={Layout} />
        <RouteWrapper path="/review/:token" component={Review} layout={Layout} />
        <RouteWrapper path="/delivery-info/:token" component={UserLocation} layout={Layout} />
        <RouteWrapper
          path="/register"
          component={Registration}
          layout={Layout}
        />
        <RouteWrapper
          path="/track/:code"
          component={LandingPageTrackShipment}
          layout={HomePageLayout}
        />
        {/* <RouteWrapper
          path="/global-track/:code"
          component={GlobalTrack}
          layout={Layout}
        /> */}
        <RouteWrapper
          path="/Forgotpassword"
          component={Forgotpassword}
          layout={Layout}
        />

        {/* --------------------------------- Print --------------------------------- */}

        {/* ------------------ Print List ------------------ */}
        <Route path="/invoices/print" component={InvoicePrint} />
        <Route path="/payments/print/:user?" component={PaymentPrint} />
        <Route path="/collections/print" component={CollectionPrint} />
        <Route path="/reports/totals-per-zones/print" component={PrintTotalsPerZones} />
        <Route path="/reports/totals-per-fees/print" component={PrintTotalsPerFees} />
        <Route path="/pickup/print" component={PickupPrint} />
        <Route path="/concierge-request/print" component={ConciergePrint} />
        <Route path="/shipment-list/print" component={PrintShipmentList} />
        <Route path="/list-shipments-filter/print" component={PrintShipmentListFilter} />
        <Route path="/customer-due-inquiry-list/print" component={PrintCustomerDueInquiry} />
        <Route path="/shipment/print/:id/:size" component={ShipmentPrint} />
        <Route path="/batch/print/:id/:size" component={ShipmentPrint} />
        <Route path="/voucher/print/:id" component={VoucherPrint} />
        <Route path="/safes-transfer/print/:id" component={VoucherPrint} />
        <Route
          path="/product/sticky-print/:size/:id"
          component={ProductStickyPrint}
        />
        <Route
          path="/shipment/sticky-print/:id"
          component={ShipmentStickyPrint}
        />
        <Route
          path="/batch/sticky-print/:id"
          component={ShipmentStickyPrint}
        />
        <Route
          path="/shipment/sticky2X5-print/:id"
          component={ShipmentStickyPrint2X5}
        />
        <Route
          path="/batch/sticky2X5-print/:id"
          component={ShipmentStickyPrint2X5}
        />
        <Route
          path="/shipment/sticky7X9-print/:id"
          component={ShipmentStickyPrint7X9}
        />
        <Route
          path="/batch/sticky7X9-print/:id"
          component={ShipmentStickyPrint7X9}
        />
        <Route
          path="/shipment/sticky10X15-print/:id"
          component={ShipmentStickyPrint10X15}
        />
        <Route
          path="/shipment/sticky10X10-print/:id"
          component={ShipmentStickyPrint10X10}
        />
        <Route
          path="/batch/sticky10X10-print/:id"
          component={ShipmentStickyPrint10X10}
        />
        <Route
          path="/batch/sticky10X15-print/:id"
          component={ShipmentStickyPrint10X15}
        />
        <Route
          path="/shipment/sticky17X10-print/:id"
          component={ShipmentStickyPrint17X10}
        />
        <Route
          path="/batch/sticky17X10-print/:id"
          component={ShipmentStickyPrint17X10}
        />
        <Route
          path="/finance/statement/sub"
          component={AccountStatementPrint}
        />
        <Route
          path="/sheets/:id?"
          component={SheetsPrint}
        />
        <Route
          path="/finance/statement/main"
          component={MainAccountStatementPrint}
        />
        <Route
          path="/finance/statement/trialBalance"
          component={MainAccountStatementPrint}
        />

        <Route
          exact
          path="/report/print/:type/:id?/:trxType?/:transfer?"
          component={ManifestPrint}
        />

        {/* --------------------------------- Admin --------------------------------- */}
        <RouteWrapper
          path="/admin"
          exact
          component={Home}
          layout={LayoutWithDrawer}
        />

        <RouteWrapper
          path="/admin/shipping-settings"
          component={Settings}
          layout={LayoutWithDrawer}
          permission="shipping.settings.update"
        />
        <RouteWrapper
          path="/admin/accounting-settings"
          component={AccountingSettings}
          layout={LayoutWithDrawer}
          permission="accounting.settings.update"
        />
        <RouteWrapper
          path="/admin/change-password"
          component={ChangePassword}
          layout={LayoutWithDrawer}
        />


        {/* --------------------------------- Admin Alert --------------------------------- */}
        <RouteWrapper
          path="/admin/alert"
          exact
          component={AdminAlert}
          layout={LayoutWithDrawer}
          permission="core.alert.list"
        />
        {/* --------------------------------- Shipments --------------------------------- */}

        <RouteWrapper
          path="/admin/shipments"
          key={props.history.location.key}
          component={Shipments}
          exact
          layout={LayoutWithDrawer}
          permission="shipping.shipment.list"
        />
        <RouteWrapper
          path="/admin/shipments-transactions"
          key={props.history.location.key}
          component={ShipmentsTransactions}
          exact
          layout={LayoutWithDrawer}
          permission="shipping.shipment_transaction.list"
        />
        <RouteWrapper
          path="/admin/shipments/create"
          key={props.history.location.key}
          component={SaveShipment}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.create"
        />
        <RouteWrapper
          path="/admin/shipments/:id/edit"
          key={props.history.location.key}
          component={SaveShipment}
          layout={LayoutWithDrawer}
        />
        <RouteWrapper
          path="/admin/shipments/import"
          key={props.history.location.key}
          component={ImportShipments}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.import"
        />
        <RouteWrapper
          path="/admin/shipments/import-messages"
          key={props.history.location.key}
          component={ImportShipmentsMessages}
          layout={LayoutWithDrawer}
          permission="shipping.shipment_message.bulk_create"
        />

        <RouteWrapper
          path="/admin/shipments/:id"
          key={props.history.location.key}
          component={ShipmentsView}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.list"
        />
        <RouteWrapper
          path="/admin/shipments-in-warehouse"
          key={props.history.location.key}
          component={ShipmentsInWarehouse}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.list"
        />
        <RouteWrapper
          path="/admin/idle-shipments"
          key={props.history.location.key}
          component={IdleShipments}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.list"
        />
        <RouteWrapper
          path="/admin/uncollected-from-delivery-agents"
          key={props.history.location.key}
          component={UncollectedFromDeliveryAgents}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.list_delivery_agent_custody"
        />
        <RouteWrapper
          path="/admin/not-paid-to-customer"
          key={props.history.location.key}
          component={NotPaidToCustomer}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.list"
        />
        <RouteWrapper
          path="/admin/stocktake"
          key={props.history.location.key}
          component={Stocktake}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.list"
        />

        <RouteWrapper
          path="/admin/barcode-batches"
          key={props.history.location.key}
          component={GenrateBarcodesList}
          exact
          layout={LayoutWithDrawer}
          permission="shipping.barcode_batch.list"
        />
        <RouteWrapper
          path="/admin/barcode-batches/create"
          key={props.history.location.key}
          component={GenrateBarcode}
          layout={LayoutWithDrawer}
          permission="shipping.barcode_batch.create"
        />
        <RouteWrapper
          path="/admin/barcode-batches/:id/edit"
          component={GenrateBarcode}
          layout={LayoutWithDrawer}
          permission="shipping.barcode_batch.create"
        />
        <RouteWrapper
          path="/admin/barcode-batches/:id"
          key={props.history.location.key}
          component={GenrateBarcodesView}
          layout={LayoutWithDrawer}
          permission="shipping.barcode_batch.list"
        />
        <RouteWrapper
          path="/admin/customer-new-shipment"
          key={props.history.location.key}
          component={CustomerNewShipment}
          layout={LayoutWithDrawer}
          permission="shipping.customer_merchant.list_with_new_shipments"
        />

        <RouteWrapper
          path="/admin/receive-undelivered-shipments"
          key={props.history.location.key}
          exact
          component={ReceiveUndeliveredShipments}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.receive_in_warehouse"
        />
        <RouteWrapper
          path="/admin/unpacked-shipments"
          key={props.history.location.key}
          exact
          component={UnpackedShipments}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.unpack"
        />


        {/* --------------------------------- conciergeRequest --------------------------------- */}
        <RouteWrapper
          path="/admin/concierge-request/:id(\d+)"
          key={props.history.location.key}
          exact
          component={ConciergeRequestView}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping", "concierge_request", ["top", "wdr"], "list")}
        />
        <RouteWrapper
          path="/admin/concierge-request/:type"
          exact
          key={props.history.location.key}
          component={ConciergeRequestList}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping", "concierge_request", ["top", "wdr"], 'list')}
        />
        <RouteWrapper
          path="/admin/concierge-request/create/:type"
          exact
          key={props.history.location.key}
          component={ConciergeRequestForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping", "concierge_request", ["top", "wdr"], "create")}
        />
        <RouteWrapper
          path="/admin/concierge-request/:id/edit"
          component={ConciergeRequestForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping", "concierge_request", ["top", "wdr"], "update")}
        />
        <RouteWrapper
          path="/admin/concierge-providers"
          component={ConciergeProvidersList}
          layout={LayoutWithDrawer}
          permission={"shipping.concierge_provider.list"}
        />
        <RouteWrapper
          path="/admin/uncollected-from-delivery-agents-concierge"
          key={props.history.location.key}
          component={UncollectedFromDeliveryAgentsConcierge}
          layout={LayoutWithDrawer}
          permission="shipping.concierge_request.list_delivery_agent_custody"
        />
        {/* --------------------------------- Manifest --------------------------------- */}
        <RouteWrapper
          path="/admin/manifests/:id(\d+)"
          component={ViewRouteManifest}
          layout={LayoutWithDrawer}
          exact
          permission={GetPermissionSlugWithArray(
            "shipping",
            "manifest",
            [
              "pkm",
              "rits",
              "bmt",
              "bmr",
              "otd",
              "htr",
              "rts",
              "otr",
              "rtrn",
              "prp",
              "std",
              "upkbl",
              "upkbd",
              "rcv"
            ],
            'list'
          )}
        />
        <RouteWrapper
          path="/admin/manifests/:type"
          exact
          key={props.history.location.key}
          component={ManifestList}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "manifest",
            [
              "pkm",
              "rits",
              "bmt",
              "bmr",
              "otd",
              "htr",
              "rts",
              "otr",
              "rtrn",
              "prp",
              "std",
              "upkbl",
              "upkbd",
              "rcv"
            ],
            'list'
          )}
        />
        <RouteWrapper
          path="/admin/manifests/:id/edit"
          exact
          component={ManifestForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "manifest",
            [
              "pkm",
              "rits",
              "bmt",
              "bmr",
              "otd",
              "htr",
              "rts",
              "otr",
              "rtrn",
              "prp",
              "std",
              "upkbl",
              "upkbd",
              "rcv"
            ],
            'update'
          )}
        />
        <RouteWrapper
          path="/admin/manifests/create/:type"
          exact
          component={ManifestForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "manifest",
            [
              "pkm",
              "rits",
              "bmt",
              "bmr",
              "otd",
              "htr",
              "rts",
              "otr",
              "rtrn",
              "prp",
              "std",
              "upkbl",
              "upkbd",
              "rcv"
            ],
            'create'
          )}
        />

        {/* --------------------------------- Manifest Transfer --------------------------------- */}
        <RouteWrapper
          path="/admin/manifests/:type/:transfertype(rts|dlv)"
          exact
          key={props.history.location.key}
          component={ManifestTransferList}
          layout={LayoutWithDrawer}
        // permission={GetPermissionSlugWithArray(
        //   "shipping",
        //   "manifest",
        //   [
        //     "dlv",
        //     "rts",
        //   ],
        //   'list'
        // )}
        />
        <RouteWrapper
          path="/admin/manifests/:transfertype(rts|dlv)/:id/edit"
          exact
          component={ManifestTransferForm}
          layout={LayoutWithDrawer}
        // permission={GetPermissionSlugWithArray(
        //   "shipping",
        //   "manifest",
        //   [
        //     "dlv",
        //     "rts",
        //   ],
        //   'update'
        // )}
        />
        <RouteWrapper
          path="/admin/manifests/create/:type/:transfertype(rts|dlv)"
          exact
          component={ManifestTransferForm}
          layout={LayoutWithDrawer}
        // permission={GetPermissionSlugWithArray(
        //   "shipping",
        //   "manifest",
        //   [
        //     "dlv",
        //     "rts",
        //   ],
        //   'create'
        // )}
        />

        {/* --------------------------------- sacks --------------------------------- */}
        <RouteWrapper
          path="/admin/sacks"
          key={props.history.location.key}
          exact
          component={ListSacks}
          layout={LayoutWithDrawer}
          permission="shipping.sack.list"
        />
        <RouteWrapper
          path="/admin/sacks/create"
          exact
          key={props.history.location.key}
          component={SacksForm}
          layout={LayoutWithDrawer}
          permission="shipping.sack.create"
        />
        <RouteWrapper
          path="/admin/sacks/:id/edit"
          key={props.history.location.key}
          component={SacksForm}
          layout={LayoutWithDrawer}
          permission="shipping.sack.update"
        />
        <RouteWrapper
          path="/admin/sacks/:id"
          key={props.history.location.key}
          component={SacksView}
          layout={LayoutWithDrawer}
          permission="shipping.sack.list"
        />
        {/* --------------------------------- Finance --------------------------------- */}
        <RouteWrapper
          path="/admin/finance/statement/sub/:id?"
          component={FinanceStatement}
          layout={LayoutWithDrawer}
          key={props.history.location.key}
          permission="accounting.journal_entry_record.list"
        />
        <RouteWrapper
          path="/admin/finance/statement/main/:id?"
          component={MainFinanceStatement}
          layout={LayoutWithDrawer}
          key={props.history.location.key}
          permission="accounting.gl_account.list"
        />
        <RouteWrapper
          path="/admin/finance/statement/trialBalance/:id?"
          component={TrialBalance}
          layout={LayoutWithDrawer}
          key={props.history.location.key}
          permission="accounting.gl_account.list"
        />
        <RouteWrapper
          path="/admin/finance/journal-types"
          component={JournalTypeList}
          layout={LayoutWithDrawer}
          key={props.history.location.key}
          permission="accounting.journal_type.list"
        />
        <RouteWrapper
          path="/admin/finance/currency"
          component={CurrencyList}
          layout={LayoutWithDrawer}
          key={props.history.location.key}
          permission="cash.currency.list"
        />
        <RouteWrapper
          path="/admin/finance/financial-year"
          component={FinancialYearList}
          layout={LayoutWithDrawer}
          key={props.history.location.key}
          exact
          permission="cash.financial_year.list"
        />
        <RouteWrapper
          path="/admin/finance/financial-year/create"
          component={FinancialYear}
          layout={LayoutWithDrawer}
          key={props.history.location.key}
          permission="cash.financial_year.create"
        />
        <RouteWrapper
          path="/admin/finance/financial-year/:id/edit"
          component={FinancialYear}
          layout={LayoutWithDrawer}
          key={props.history.location.key}
          exact
          permission="cash.financial_year.update"
        />
        <RouteWrapper
          path="/admin/finance/financial-year/:id"
          component={FinancialYearView}
          layout={LayoutWithDrawer}
          key={props.history.location.key}
          permission="cash.financial_year.list"
        />


        {/* --------------------------------- Delivery collection --------------------------------- */}

        <RouteWrapper
          path="/admin/collections/:id(\d+)"
          exact
          key={props.history.location.key}
          component={CollectionView}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "collection",
            [
              "dlvby",
              "custm"
            ],
            'list'
          )}
        />
        <RouteWrapper
          path="/admin/collections/:type"
          exact
          key={props.history.location.key}
          component={CollectionList}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "collection",
            [
              "dlvby",
              "custm"
            ],
            'list'
          )}
        />
        <RouteWrapper
          path="/admin/collections/create/:type"
          component={CollectionForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "collection",
            [
              "dlvby",
              "custm"
            ],
            'create'
          )}
        />
        <RouteWrapper
          path="/admin/collections/:id/edit"
          component={CollectionForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "collection",
            [
              "dlvby",
              "custm"
            ],
            'update'
          )}
        />
        {/* --------------------------------- Payment --------------------------------- */}

        <RouteWrapper
          path="/admin/payments/:id(\d+)"
          exact
          key={props.history.location.key}
          component={PaymentView}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "payment",
            [
              "dlvby",
              "custm"
            ],
            'list'
          )}
        />
        <RouteWrapper
          path="/admin/payments/:type"
          exact
          key={props.history.location.key}
          component={PaymentList}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "payment",
            [
              "dlvby",
              "custm"
            ],
            'list'
          )}
        />
        <RouteWrapper
          path="/admin/payments/create/:type"
          key={props.history.location.key}
          component={PaymentForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "payment",
            [
              "dlvby",
              "custm"
            ],
            'create'
          )}
        />
        <RouteWrapper
          path="/admin/payments/:id/edit"
          key={props.history.location.key}
          component={PaymentForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "payment",
            [
              "dlvby",
              "custm"
            ],
            'update'
          )}
        />
        {/* --------------------------------- Invoice --------------------------------- */}
        <RouteWrapper
          path="/admin/invoices/:id(\d+)"
          exact
          key={props.history.location.key}
          component={InvoicesView}
          layout={LayoutWithDrawer}
          permission="shipping.invoice.list"
        />
        <RouteWrapper
          path="/admin/invoices"
          exact
          key={props.history.location.key}
          component={InvoicesList}
          layout={LayoutWithDrawer}
          permission="shipping.invoice.list"
        />
        <RouteWrapper
          path="/admin/invoices/create"
          key={props.history.location.key}
          component={InvoicesForm}
          layout={LayoutWithDrawer}
          permission="shipping.invoice.create"
        />
        <RouteWrapper
          path="/admin/invoices/:id/edit"
          key={props.history.location.key}
          component={InvoicesForm}
          layout={LayoutWithDrawer}
          permission="shipping.invoice.update"
        />
        {/* --------------------------------- Delivery List Actions --------------------------------- */}

        <RouteWrapper
          path="/admin/delivery-list-actions"
          key={props.history.location.key}
          component={DeliveryListActions}
          layout={LayoutWithDrawer}
          permission="shipping.shipment.update_status"
        />
        {/* --------------------------------- Customers --------------------------------- */}

        <RouteWrapper
          path="/admin/customers/:type(merchant|individual|concierge)"
          exact
          key={props.history.location.key}
          component={CustomerList}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "customer",
            [
              "individual",
              "merchant",
              "concierge"
            ],
            'list'
          )}
        />
        <RouteWrapper
          path="/admin/customer-due-inquiry"
          exact
          key={props.history.location.key}
          component={CustomerDueInquiry}
          layout={LayoutWithDrawer}
          permission="shipping.customer_merchant.list"
        />
        <RouteWrapper
          path="/admin/customers/create/:type"
          key={props.history.location.key}
          component={CustomerForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "customer",
            [
              "individual",
              "merchant",
              "concierge"
            ],
            'create'
          )}
        />
        <RouteWrapper
          path="/admin/customers/:id/edit"
          key={props.history.location.key}
          component={CustomerForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "customer",
            [
              "merchant",
              "individual",
              "concierge"
            ],
            'update'
          )}
        />
        <RouteWrapper
          path="/admin/customers/:id(\d+)"
          key={props.history.location.key}
          component={CustomerView}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "customer",
            [
              "merchant",
              "individual",
              "concierge"
            ],
            'list'
          )}
        />
        {/* --------------------------------- Organization Data --------------------------------- */}
        <RouteWrapper
          path="/admin/organization/edit"
          key={props.history.location.key}
          component={OrganizationDataForm}
          layout={LayoutWithDrawer}
          permission="core.organization.update"
        />
        <RouteWrapper
          path="/admin/organization"
          key={props.history.location.key}
          component={OrganizationDataView}
          layout={LayoutWithDrawer}
          permission="core.organization.view"
        />
        {/* --------------------------------- Prouducts --------------------------------- */}

        <RouteWrapper
          path="/admin/products"
          exact
          key={props.history.location.key}
          component={ProductList}
          layout={LayoutWithDrawer}
          permission={() => Globals.user.warehousing}
        />
        <RouteWrapper
          path="/admin/products/create"
          key={props.history.location.key}
          component={ProductsForm}
          layout={LayoutWithDrawer}
          permission={() => Globals.user.warehousing && Globals.user.hasPermission('shipping.product.create')}
        />
        <RouteWrapper
          path="/admin/products-transactions"
          key={props.history.location.key}
          component={ProductsTransactions}
          layout={LayoutWithDrawer}
          permission={() => Globals.user.warehousing && Globals.user.hasPermission('shipping.product_transaction.list')}
        />
        <RouteWrapper
          path="/admin/products/:id/edit"
          key={props.history.location.key}
          component={ProductsForm}
          layout={LayoutWithDrawer}
          permission="shipping.product.update"
        />
        <RouteWrapper
          path="/admin/products/:id"
          key={props.history.location.key}
          component={ProductView}
          layout={LayoutWithDrawer}
          permission="shipping.product.list"
        />
        {/* --------------------------------- Manifests Product --------------------------------- */}
        <RouteWrapper
          path="/admin/warehouse-manifests/:id(\d+)"
          component={ManifestProductView}
          layout={LayoutWithDrawer}
          exact
          permission="shipping.warehouse_manifest.list"
        />
        <RouteWrapper
          path="/admin/warehouse-manifests/:type"
          exact
          key={props.history.location.key}
          component={ManifestProductList}
          layout={LayoutWithDrawer}
          permission="shipping.warehouse_manifest.list"
        />
        <RouteWrapper
          path="/admin/warehouse-manifests/create/:type"
          exact
          component={manifestsProductsForm}
          layout={LayoutWithDrawer}
          permission="shipping.warehouse_manifest.create"
        />
        <RouteWrapper
          path="/admin/warehouse-manifests/:id/edit"
          exact
          component={manifestsProductsForm}
          layout={LayoutWithDrawer}
          permission="shipping.warehouse_manifest.update"
        />
        {/* --------------------------------- Vouchers --------------------------------- */}

        <RouteWrapper
          path="/admin/vouchers"
          exact
          key={props.history.location.key}
          component={VoucherList}
          layout={LayoutWithDrawer}
          permission="cash.voucher.list"
        />
        <RouteWrapper
          path="/admin/vouchers/create"
          key={props.history.location.key}
          component={VoucherForm}
          layout={LayoutWithDrawer}
          permission="cash.voucher.create"
        />
        <RouteWrapper
          path="/admin/vouchers/:id/edit"
          key={props.history.location.key}
          component={VoucherForm}
          layout={LayoutWithDrawer}
          permission="cash.voucher.update"
        />
        <RouteWrapper
          path="/admin/vouchers/:id"
          key={props.history.location.key}
          component={VoucherView}
          layout={LayoutWithDrawer}
          permission="cash.voucher.list"
        />
        {/* --------------------------------- SafesTransfer ---------------------------------*/}

        <RouteWrapper
          path="/admin/safes-transfer"
          exact
          key={props.history.location.key}
          component={VoucherList}
          layout={LayoutWithDrawer}
          permission="cash.cash_voucher.list"
        />
        <RouteWrapper
          path="/admin/safes-transfer/create"
          key={props.history.location.key}
          component={VoucherForm}
          layout={LayoutWithDrawer}
          permission="cash.cash_voucher.create"
        />
        <RouteWrapper
          path="/admin/safes-transfer/:id/edit"
          key={props.history.location.key}
          component={VoucherForm}
          layout={LayoutWithDrawer}
          permission="cash.cash_voucher.update"
        />
        <RouteWrapper
          path="/admin/safes-transfer/:id"
          key={props.history.location.key}
          component={VoucherView}
          layout={LayoutWithDrawer}
          permission="cash.cash_voucher.list"
        />
        {/* --------------------------------- Price List --------------------------------- */}

        <RouteWrapper
          path="/admin/price-list/:id/edit"
          key={props.history.location.key}
          component={PriceListForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "price_list",
            [
              "merchant",
              "individual",
              "concierge"
            ],
            'update'
          )}
        />
        <RouteWrapper
          path="/admin/price-list/:copyId/copy"
          key={props.history.location.key}
          component={PriceListForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "price_list",
            [
              "merchant",
              "individual",
              "concierge"
            ],
            'create'
          )}
        />
        <RouteWrapper
          path="/admin/price-list/create/:type"
          key={props.history.location.key}
          component={PriceListForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "price_list",
            [
              "merchant",
              "individual",
              "concierge"
            ],
            'create'
          )}
        />
        <RouteWrapper
          path="/admin/price-list/:id(\d+)"
          key={props.history.location.key}
          component={PriceListView}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "price_list",
            [
              "merchant",
              "individual",
              "concierge"
            ],
            'list'
          )}
        />
        <RouteWrapper
          path="/admin/price-list/:type(merchant|individual|concierge)"
          key={props.history.location.key}
          component={ListPriceList}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "price_list",
            [
              "merchant",
              "individual",
              "concierge"
            ],
            'list'
          )}
        />
        {/* --------------------------------- Delivery Agents --------------------------------- */}
        <RouteWrapper
          path="/admin/delivery-agents-integration/:id/create"
          key={props.history.location.key}
          exact
          component={DeliveryAgentIntegrationForm}
          layout={LayoutWithDrawer}
        // permission="shipping.delivery_agent.list"
        />
        <RouteWrapper
          path="/admin/delivery-agents-integration/:id/edit"
          key={props.history.location.key}
          exact
          component={DeliveryAgentIntegrationForm}
          layout={LayoutWithDrawer}
        // permission="shipping.delivery_agent.list"
        />
        <RouteWrapper
          path="/admin/delivery-agents"
          key={props.history.location.key}
          exact
          component={ListDeliveryAgents}
          layout={LayoutWithDrawer}
          permission="shipping.delivery_agent.list"
        />
        <RouteWrapper
          path="/admin/delivery-agents/create"
          exact
          key={props.history.location.key}
          component={DeliveryAgent}
          layout={LayoutWithDrawer}
          permission="shipping.delivery_agent.create"
        />
        <RouteWrapper
          path="/admin/delivery-agents/:id/edit"
          key={props.history.location.key}
          component={DeliveryAgent}
          layout={LayoutWithDrawer}
          permission="shipping.delivery_agent.update"
        />
        <RouteWrapper
          path="/admin/delivery-agents/track"
          exact
          key={props.history.location.key}
          component={TrackDeliveryAgent}
          layout={LayoutWithDrawer}
          permission="shipping.delivery_agent_position.list"
        />
        <RouteWrapper
          path="/admin/delivery-agents/:id"
          key={props.history.location.key}
          component={DeliveryAgentView}
          layout={LayoutWithDrawer}
          permission="shipping.delivery_agent.list"
        />

        <RouteWrapper
          path="/admin/delivery-agent-reviews"
          key={props.history.location.key}
          exact
          component={DeliveryAgentReviews}
          layout={LayoutWithDrawer}
          permission="shipping.agent_review.list"
        />

        <RouteWrapper
          path="/admin/delivery-agent-clearance"
          key={props.history.location.key}
          exact
          component={DeliveryAgentClearance}
          layout={LayoutWithDrawer}
          permission="shipping.delivery_agent_clearance.list"
        />

        <RouteWrapper
          path="/admin/delivery-agent-clearance/create"
          key={props.history.location.key}
          exact
          component={DeliveryAgentClearanceForm}
          layout={LayoutWithDrawer}
          permission={["shipping.delivery_agent_clearance.create", "shipping.manifest_rits.create", "shipping.manifest_rcv.create", "shipping.collection_dlvby.create"]}
        />

        <RouteWrapper
          path="/admin/delivery-agent-clearance/:id"
          key={props.history.location.key}
          exact
          component={DeliveryAgentClearanceForm}
          layout={LayoutWithDrawer}
          permission="shipping.delivery_agent_clearance.update"
        />

        {/* --------------------------------- Cancellation Reasons--------------------------------- */}
        <RouteWrapper
          path="/admin/cancellation-reasons"
          key={props.history.location.key}
          exact
          component={CancellationReasonsList}
          layout={LayoutWithDrawer}
          permission="shipping.cancellation_reason.list"
        />
        <RouteWrapper
          path="/admin/cancellation-reasons/create"
          exact
          key={props.history.location.key}
          component={CancellationReasonForm}
          layout={LayoutWithDrawer}
          permission="shipping.cancellation_reason.create"
        />
        <RouteWrapper
          path="/admin/cancellation-reasons/:id/edit"
          key={props.history.location.key}
          component={CancellationReasonForm}
          layout={LayoutWithDrawer}
          permission="shipping.cancellation_reason.update"
        />
        {/* --------------------------------- Routes --------------------------------- */}
        <RouteWrapper
          path="/admin/routes"
          key={props.history.location.key}
          exact
          component={ListRoutes}
          layout={LayoutWithDrawer}
          permission="shipping.route.list"
        />
        <RouteWrapper
          path="/admin/routes/create"
          exact
          key={props.history.location.key}
          component={RouteForm}
          layout={LayoutWithDrawer}
          permission="shipping.route.create"
        />
        <RouteWrapper
          path="/admin/routes/:id/edit"
          key={props.history.location.key}
          component={RouteForm}
          layout={LayoutWithDrawer}
          permission="shipping.route.update"
        />
        <RouteWrapper
          path="/admin/routes/:id"
          key={props.history.location.key}
          component={RouteView}
          layout={LayoutWithDrawer}
          permission="shipping.route.list"
        />
        {/* --------------------------------- Branches --------------------------------- */}
        <RouteWrapper
          path="/admin/branches"
          key={props.history.location.key}
          exact
          component={BranchList}
          layout={LayoutWithDrawer}
          permission="core.branch.list"
        />
        <RouteWrapper
          path="/admin/branches/create"
          exact
          key={props.history.location.key}
          component={BranchForm}
          layout={LayoutWithDrawer}
          permission="core.branch.create"
        />
        <RouteWrapper
          path="/admin/branches/:id/edit"
          key={props.history.location.key}
          component={BranchForm}
          layout={LayoutWithDrawer}
          permission="shipping.route.update"
        />
        <RouteWrapper
          path="/admin/branches/:id"
          key={props.history.location.key}
          component={BranchView}
          layout={LayoutWithDrawer}
          permission="shipping.route.list"
        />
        {/* --------------------------------- commission list --------------------------------- */}
        <RouteWrapper
          path="/admin/commission-list"
          key={props.history.location.key}
          exact
          component={ListCommissions}
          layout={LayoutWithDrawer}
          permission="shipping.commission_list.list"
        />
        <RouteWrapper
          path="/admin/commission-list/create"
          exact
          key={props.history.location.key}
          component={CommissionListForm}
          layout={LayoutWithDrawer}
          permission="shipping.commission_list.create"
        />
        <RouteWrapper
          path="/admin/commission-list/:id/edit"
          key={props.history.location.key}
          component={CommissionListForm}
          layout={LayoutWithDrawer}
          permission="shipping.commission_list.update"
        />
        <RouteWrapper
          path="/admin/commission-list/:id"
          key={props.history.location.key}
          component={CommissionListView}
          layout={LayoutWithDrawer}
          permission="shipping.commission_list.list"
        />
        {/* --------------------------------- Shipping Services --------------------------------- */}
        <RouteWrapper
          path="/admin/shipping-services"
          key={props.history.location.key}
          exact
          component={ShippingServicesList}
          layout={LayoutWithDrawer}
          permission="shipping.shipping_service.list"
        />
        <RouteWrapper
          path="/admin/shipping-services/create"
          exact
          key={props.history.location.key}
          component={ShippingService}
          layout={LayoutWithDrawer}
          permission="shipping.shipping_service.create"
        />
        <RouteWrapper
          path="/admin/shipping-services/:id/edit"
          key={props.history.location.key}
          component={ShippingService}
          layout={LayoutWithDrawer}
          permission="shipping.shipping_service.update"
        />
        <RouteWrapper
          path="/admin/shipping-services/:id"
          key={props.history.location.key}
          component={ShippingServiceView}
          layout={LayoutWithDrawer}
          permission="shipping.shipping_service.list"
        />
        {/* --------------------------------- Zones --------------------------------- */}
        <RouteWrapper
          path="/admin/zones"
          key={props.history.location.key}
          exact
          component={ListZones}
          layout={LayoutWithDrawer}
          permission="shipping.zone.list"
        />
        <RouteWrapper
          path="/admin/zones/create"
          exact
          key={props.history.location.key}
          component={ZoneForm}
          layout={LayoutWithDrawer}
          permission="shipping.zone.create"
        />
        <RouteWrapper
          path="/admin/zones/:id/edit"
          key={props.history.location.key}
          component={ZoneForm}
          layout={LayoutWithDrawer}
          permission="shipping.zone.update"
        />
        <RouteWrapper
          path="/admin/zones/:id"
          key={props.history.location.key}
          component={ZoneView}
          layout={LayoutWithDrawer}
          permission="shipping.zone.list"
        />
        {/* --------------------------------- WareHouse --------------------------------- */}
        <RouteWrapper
          path="/admin/warehouse"
          key={props.history.location.key}
          exact
          component={WareHouse}
          layout={LayoutWithDrawer}
          permission="shipping.warehouse_section.list"
        />
        <RouteWrapper
          path="/admin/warehouse/create"
          exact
          key={props.history.location.key}
          component={WareHouseForm}
          layout={LayoutWithDrawer}
          permission="shipping.warehouse_section.create"
        />
        <RouteWrapper
          path="/admin/warehouse/:id/edit"
          key={props.history.location.key}
          component={WareHouseForm}
          layout={LayoutWithDrawer}
          permission="shipping.warehouse_section.update"
        />
        <RouteWrapper
          path="/admin/warehouse/:id"
          key={props.history.location.key}
          component={WareHouseView}
          layout={LayoutWithDrawer}
          permission="shipping.warehouse_section.list"
        />
        {/* --------------------------------- Reports --------------------------------- */}
        <RouteWrapper
          path="/admin/reports/totals-per-zones"
          key={props.history.location.key}
          exact
          component={TotalsPerZones}
          layout={LayoutWithDrawer}
          permission="shipping.report.shipments_by_zone"
        />
        <RouteWrapper
          path="/admin/reports/totals-per-fees"
          key={props.history.location.key}
          exact
          component={TotalsPerFees}
          layout={LayoutWithDrawer}
          permission="shipping.report.shipments_by_fees"
        />
        {/* --------------------------------- TransactionTypes --------------------------------- */}
        <RouteWrapper
          path="/admin/transaction-types"
          key={props.history.location.key}
          exact
          component={ListTransactionTypes}
          layout={LayoutWithDrawer}
          permission="shipping.transaction_type.list"
        />
        <RouteWrapper
          path="/admin/transaction-types/create"
          exact
          key={props.history.location.key}
          component={TransactionType}
          layout={LayoutWithDrawer}
          permission="shipping.transaction_type.create"
        />
        <RouteWrapper
          path="/admin/transaction-types/:id/edit"
          key={props.history.location.key}
          component={TransactionType}
          layout={LayoutWithDrawer}
          permission="shipping.transaction_type.update"
        />
        <RouteWrapper
          path="/admin/transaction-types/:id"
          key={props.history.location.key}
          component={TransactionTypeView}
          layout={LayoutWithDrawer}
          permission="shipping.transaction_type.list"
        />
        {/* --------------------------------- Safes --------------------------------- */}
        <RouteWrapper
          path="/admin/safes"
          key={props.history.location.key}
          exact
          component={ListSafes}
          layout={LayoutWithDrawer}
          permission="cash.safe.list"
        />
        <RouteWrapper
          path="/admin/safes/create"
          exact
          key={props.history.location.key}
          component={SafeForm}
          layout={LayoutWithDrawer}
          permission="cash.safe.create"
        />
        <RouteWrapper
          path="/admin/safes/:id/edit"
          key={props.history.location.key}
          component={SafeForm}
          layout={LayoutWithDrawer}
          permission="cash.safe.update"
        />
        <RouteWrapper
          path="/admin/safes/:id"
          key={props.history.location.key}
          component={SafeView}
          layout={LayoutWithDrawer}
          permission="cash.safe.list"
        />
        {/* --------------------------------- users --------------------------------- */}
        <RouteWrapper
          path="/admin/users"
          key={props.history.location.key}
          exact
          component={ListUsers}
          layout={LayoutWithDrawer}
          permission="core.user.list"
        />
        <RouteWrapper
          path="/admin/users/create"
          exact
          key={props.history.location.key}
          component={UserForm}
          layout={LayoutWithDrawer}
          permission="core.user.create"
        />
        <RouteWrapper
          path="/admin/users/:id/edit"
          key={props.history.location.key}
          component={UserForm}
          layout={LayoutWithDrawer}
          permission="core.user.update"
        />
        <RouteWrapper
          path="/admin/users/:id"
          key={props.history.location.key}
          component={UserView}
          layout={LayoutWithDrawer}
          permission="core.user.list"
        />
        {/* --------------------------------- Lookup --------------------------------- */}
        <RouteWrapper
          path="/admin/lookup"
          key={props.history.location.key}
          exact
          component={Lookup}
          layout={LayoutWithDrawer}
          permission="core.lookup.list"
        />
        <RouteWrapper
          path="/admin/lookup/:id/edit"
          key={props.history.location.key}
          component={LookupEntriesForm}
          layout={LayoutWithDrawer}
          permission="core.lookup_entry.update"
        />
        <RouteWrapper
          path="/admin/lookup/:id"
          key={props.history.location.key}
          component={LookupView}
          layout={LayoutWithDrawer}
          permission="core.lookup.list"
        />
        {/* --------------------------------- profile --------------------------------- */}
        <RouteWrapper
          path="/admin/profile"
          key={props.history.location.key}
          component={Profile}
          layout={LayoutWithDrawer}
        />
        {/* --------------------------------- approving registration --------------------------------- */}
        <RouteWrapper
          path="/admin/registrations/:type"
          key={props.history.location.key}
          component={Registrations}
          layout={LayoutWithDrawer}
        />
        {/* --------------------------------- Pickups --------------------------------- */}
        <RouteWrapper
          path="/admin/pickups"
          exact
          key={props.history.location.key}
          component={ListPickups}
          layout={LayoutWithDrawer}
          permission="shipping.pickup.list"
        />
        <RouteWrapper
          key={props.history.location.key}
          path="/admin/pickups/create"
          component={PickupForm}
          layout={LayoutWithDrawer}
          permission="shipping.pickup.create"
        />
        <RouteWrapper
          path="/admin/pickups/:id/edit"
          component={PickupForm}
          layout={LayoutWithDrawer}
        />
        <RouteWrapper
          path="/admin/pickups/:id"
          exact
          key={props.history.location.key}
          component={PickupView}
          layout={LayoutWithDrawer}
          permission="shipping.pickup.list"
        />
        {/* --------------------------------- Warehouse Pickups --------------------------------- */}
        <RouteWrapper
          path="/admin/warehouse-pickups"
          exact
          key={props.history.location.key}
          component={ListWarehousePickups}
          layout={LayoutWithDrawer}
          permission="shipping.pickup.list"
        />
        <RouteWrapper
          key={props.history.location.key}
          path="/admin/warehouse-pickups/create"
          component={WarehousePickupForm}
          layout={LayoutWithDrawer}
          permission="shipping.pickup.create"
        />
        <RouteWrapper
          path="/admin/warehouse-pickups/:id/edit"
          component={WarehousePickupForm}
          layout={LayoutWithDrawer}
        />
        <RouteWrapper
          path="/admin/warehouse-pickups/:id"
          exact
          key={props.history.location.key}
          component={WarehousePickupView}
          layout={LayoutWithDrawer}
          permission="shipping.pickup.list"
        />
        {/* --------------------------------- Journal Entry --------------------------------- */}
        <RouteWrapper
          path="/admin/finance/journal-entry"
          exact
          key={props.history.location.key}
          component={ListJournalEntry}
          layout={LayoutWithDrawer}
          permission="accounting.journal_entry.list"
        />
        <RouteWrapper
          path="/admin/finance/journal-entry/create"
          component={JornalEntryForm}
          layout={LayoutWithDrawer}
          permission="accounting.journal_entry.create"
        />
        <RouteWrapper
          path="/admin/finance/journal-entry/:id/edit"
          component={JornalEntryForm}
          layout={LayoutWithDrawer}
          permission="accounting.journal_entry.update"
        />
        <RouteWrapper
          path="/admin/finance/journal-entry/:id"
          exact
          key={props.history.location.key}
          component={JournalEntryView}
          layout={LayoutWithDrawer}
          permission="accounting.journal_entry.list"
        />

        {/* --------------------------------- GL Account --------------------------------- */}
        <RouteWrapper
          path="/admin/finance/gl-account"
          exact
          key={props.history.location.key}
          component={GlAccountsTree}
          layout={LayoutWithDrawer}
          permission="accounting.gl_account.list"
        />

        {/* <RouteWrapper
          path="/admin/finance/gl-account/create"
          component={CreateGlAccount}
          layout={LayoutWithDrawer}
          permission="accounting.gl_account.create"
        />
        <RouteWrapper
          path="/admin/finance/gl-account/:id/edit"
          component={CreateGlAccount}
          layout={LayoutWithDrawer}
          permission="accounting.gl_account.update"
        /> */}
        {/* --------------------------------- roles --------------------------------- */}
        <RouteWrapper
          path="/admin/roles"
          key={props.history.location.key}
          exact
          component={ListRoles}
          layout={LayoutWithDrawer}
          permission="core.role.list"
        />
        <RouteWrapper
          path="/admin/roles/create"
          exact
          key={props.history.location.key}
          component={RoleForm}
          layout={LayoutWithDrawer}
          permission="core.role.create"
        />
        <RouteWrapper
          path="/admin/roles/:copyId/copy"
          key={props.history.location.key}
          component={RoleForm}
          layout={LayoutWithDrawer}
          permission="core.role.create"
        />
        <RouteWrapper
          path="/admin/roles/:id/edit"
          key={props.history.location.key}
          component={RoleForm}
          layout={LayoutWithDrawer}
          permission="core.role.update"
        />
        <RouteWrapper
          path="/admin/roles/:id"
          key={props.history.location.key}
          component={RoleView}
          layout={LayoutWithDrawer}
          permission="core.role.list"
        />
        <RouteWrapper
          path="/admin/logs"
          component={LogList}
          layout={LayoutWithDrawer}
          permission="core.log.view"
        />
        {/* --------------------------------- Subsidiaries--------------------------------- */}
        <RouteWrapper
          path="/admin/subsidiaries"
          key={props.history.location.key}
          exact
          component={SubsidiariesList}
          layout={LayoutWithDrawer}
          permission="accounting.subsidiary.list"
        />
        <RouteWrapper
          path="/admin/subsidiaries/create"
          exact
          key={props.history.location.key}
          component={SubsidiaryForm}
          layout={LayoutWithDrawer}
          permission="accounting.subsidiary.create"
        />
        <RouteWrapper
          path="/admin/subsidiaries/:id/edit"
          key={props.history.location.key}
          component={SubsidiaryForm}
          layout={LayoutWithDrawer}
          permission="accounting.subsidiary.update"
        />
        {/* --------------------------------- E-Invoice --------------------------------- */}
        <RouteWrapper
          path="/admin/e-invoice"
          key={props.history.location.key}
          exact
          component={EInvoiceList}
          layout={LayoutWithDrawer}
          permission="invoicing.item.list"
        />
        <RouteWrapper
          path="/admin/e-invoice/:id"
          exact
          key={props.history.location.key}
          component={SubsidiaryForm}
          layout={LayoutWithDrawer}
          permission="accounting.subsidiary.create"
        />
        <RouteWrapper
          path="/admin/e-invoice/create"
          exact
          key={props.history.location.key}
          component={SubsidiaryForm}
          layout={LayoutWithDrawer}
          permission="accounting.subsidiary.create"
        />
        <RouteWrapper
          path="/admin/e-invoice/:id/edit"
          key={props.history.location.key}
          component={SubsidiaryForm}
          layout={LayoutWithDrawer}
          permission="accounting.subsidiary.update"
        />

        {/* <RouteWrapper
          path="/admin/gpc-tree"
          key={props.history.location.key}
          component={GPCTree3}
          layout={LayoutWithDrawer}
          permission="accounting.subsidiary.update"
        /> */}

        {/* --------------------------------- Sheets --------------------------------- */}
        <RouteWrapper
          path="/admin/create-sheets"
          key={props.history.location.key}
          component={Sheets}
          layout={LayoutWithDrawer}
          permission="accounting.sheet.create"
        />

        {/* --------------------------------- Customer Requests --------------------------------- */}
        <RouteWrapper
          path="/admin/customer-requests/:id(\d+)"
          component={CustomerRequestsView}
          layout={LayoutWithDrawer}
          exact
          permission={GetPermissionSlugWithArray(
            "shipping",
            "customer_request",
            [
              "pmnt",
              "rtrn",
              "mtrl"
            ],
            'list'
          )}
        />
        <RouteWrapper
          path="/admin/customer-requests/:type"
          exact
          key={props.history.location.key}
          component={CustomerRequestsList}
          layout={LayoutWithDrawer}
        // permission={GetCustomerReqPermissionSlugRoute('list')}
        />
        <RouteWrapper
          path="/admin/customer-requests/create/:type"
          exact
          key={props.history.location.key}
          component={CustomerRequestsForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "customer_request",
            [
              "pmnt",
              "rtrn",
              "mtrl"
            ],
            'create'
          )}
        />

        <RouteWrapper
          path="/admin/customer-requests/:id/edit"
          exact
          component={CustomerRequestsForm}
          layout={LayoutWithDrawer}
          permission={GetPermissionSlugWithArray(
            "shipping",
            "customer_request",
            [
              "pmnt",
              "rtrn",
              "mtrl"
            ],
            'update'
          )}
        />

        {/* --------------------------------- CRM --------------------------------- */}
        <RouteWrapper
          path="/admin/crm/categories"
          exact
          key={props.history.location.key}
          component={Categories}
          layout={LayoutWithDrawer}
          permission="crm.category.list"
        />
        <RouteWrapper
          path="/admin/crm/teams"
          exact
          key={props.history.location.key}
          component={Teams}
          layout={LayoutWithDrawer}
          permission="crm.team.list"
        />
        <RouteWrapper
          path="/admin/crm/tickets"
          exact
          key={props.history.location.key}
          component={Tickets}
          layout={LayoutWithDrawer}
        // permission="crm.ticket.list"
        />
        <RouteWrapper
          path="/admin/crm/tickets/:id"
          key={props.history.location.key}
          component={TicketsView}
          layout={LayoutWithDrawer}
        // permission="crm.ticket.list"
        />

        {/* --------------------------------- Banks --------------------------------- */}
        <RouteWrapper
          path="/admin/banks"
          exact
          key={props.history.location.key}
          component={BankList}
          layout={LayoutWithDrawer}
        // permission="crm.team.list"
        />
        {/* <RouteWrapper
          path="/admin/banks/create"
          exact
          key={props.history.location.key}
          component={BankForm}
          layout={LayoutWithDrawer}
        // permission="core.branch.create"
        />
        <RouteWrapper
          path="/admin/banks/:id/edit"
          key={props.history.location.key}
          component={BankForm}
          layout={LayoutWithDrawer}
        // permission="shipping.route.update"
        /> */}

        {/* --------------------------------- 404 --------------------------------- */}
        <RouteWrapper component={NotFound} layout={LayoutWithDrawer} />
      </Switch>
    </Fragment>
  );
};

export default Routes;

function RouteWrapper({
  component: Component,
  layout: Layout,
  permission,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout permission={permission} {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  permission: PropTypes.any,
};
