import React, { Fragment, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Add, Edit, Print, ViewColumn } from "@mui/icons-material";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import PrintComponent from "../Shipments/PrintComponent";
import { Globals } from "../HOC/Classes/Globals";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { printLink } from "../../helpers/defaultPrintList";
import ShipmentColumView from "../ShipmentSearch/shipmentColumView"
import config from "../../config.json";
import { defaultQueryFields, getShipmentsSumQuery, getShipmentsTableHeader, initialData, shipmentFieldsFun } from "../ShipmentSearch/ListShipmentTableCells";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IdleDialog from "./Component/IdleDialog";
import { useTranslation } from "react-i18next";
import { IDLE_SHIPMENT, LIST_FILTER_GROUPS, SUM_IDLE_SHIPMENT } from "./IdleGraphQl";
import { useQuery, gql } from "@apollo/client";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import {
  TableRow,
  Button,
  Checkbox
} from "@mui/material";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import GenerateShipmentCell from "../ShipmentSearch/GenerateShipmentCell";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import GenerateShipmentSumCell from "../ShipmentSearch/GenerateShipmentSumCell";
import ExportShipmentFilter from "./ExportShipmentFilter";

const PREFIX = "IdleShipments";

const classes = {
  customFilters: `${PREFIX}-customFilters`,
  checkbox: `${PREFIX}-checkbox`,
  shipmentTable_code: `shipmentTable_code`,
  shipmentTable_status: `shipmentTable_status`,
  bodyCheckbox: `bodyCheckbox`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  shipmentTable_bodyStatus: `shipmentTable_bodyStatus`,
  tableHeadFixed: `${PREFIX}-tableHeadFixed`,
  iconColor: `${PREFIX}-iconColor`,
  shipmentTable_description: `${PREFIX}-shipmentTable_description`,
  barcode: `${PREFIX}-barcode`,

};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.customFilters}`]: {
    display: "grid",
    gridAutoFlow: "column",
    overflowX: "auto",
    padding: theme.spacing(2),
    gridGap: theme.spacing(1),
    justifyItems: "start",
    justifyContent: "start",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  [`& .${classes.checkbox}`]: {
    minWidth: 60,
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      left: "0",
    },
  },
  [`& .${classes.shipmentTable_status}`]: {
    zIndex: "102",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      left: "141px",
    },
  },

  [`& .${classes.shipmentTable_code}`]: {
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      left: "60px",
    },
  },

  [`& .${classes.bodyCheckbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    minWidth: 60,
    [theme.breakpoints.up("sm")]: {
      left: 0,
    },
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    [theme.breakpoints.up("sm")]: {
      left: "60px",
    },
  },

  [`& .${classes.shipmentTable_bodyStatus}`]: {
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    [theme.breakpoints.up("sm")]: {
      left: "141px",
    },
  },
  [`& .${classes.tableHeadFixed}`]: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.shipmentTable_description}`]: {
    [`& div`]: {
      position: "relative",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
  },

  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },
}));

const IdleShipments = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const [keys, setKeys] = useState(localStorage.getItem("idle-shipments") ? JSON.parse(localStorage.getItem("idle-shipments")) : initialData["idle-shipments"])

  const user = Globals.user;
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
  const openColumView = () => {
    setOpenShipmentViewColum(true)
  };
  const [checkedIds, setCheckedIds] = useState([]);


  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);


  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };

  const [search, setSearch] = useState(initSearch());
  const resetPage = () => setPage(0);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: "/admin/idle-shipments",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    resetPage();
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  // const clearCheckBox = () => setCheckedIds([]);
  const defaultPrint = Globals.settings.waybillCode;

  const [filterGroupDataQuery, setFilterGroupDataQuery] = useState([]);
  const [openPrint, setOpenPrint] = useState(false);
  const [openExport, setOpenExport] = useState(false);

  const openPrintDialog = () => {
    defaultPrint ?
      printLink(defaultPrint, false, checkedIds) :
      setOpenPrint(true)
  };

  const openExportDialog = () => {
    setOpenExport(true)
  };

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const { t } = useTranslation()
  const [updateFiter, setupdate] = useState(true);
  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_FILTER_GROUPS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        input: {
          filterType: "SHIPMENT"
        },
      },
      onCompleted: ((data) => {
        updateFiter ? handleFilter(data.listFilterGroups?.[0]?.id) : handleFilter(urlQuery["filterId"])
        setFilterGroupDataQuery(data.listFilterGroups);
      })
    }
  );

  const filterGroupData = data?.listFilterGroups

  const [addNewDialog, setAddNewDialog] = useState(false);
  const [ID, setID] = useState();

  const onPrint = () => {
    const params = searchParams({
      filterId: search.filterId
    });
    const domain = `${window.location.origin}`;
    window.open(`${domain}/list-shipments-filter/print?listType=idle-shipments&${params}`);
    // window.open(`${domain}/shipment-list/print`);
  };

  const handleEdit = (i) => {
    setID(i)
    setAddNewDialog(true)
  }

  const handleFilter = (id) => {
    let handledData = {
      filterId: id,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }

    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));

    if (urlQuery["page"] || urlQuery["page"] === 0) { resetPage() }

    pushUrlSearch({
      ...handledData,
      ...((urlQuery["page"] || urlQuery["page"] === 0 || urlQuery["rowsPerPage"]) && {
        page: 0,
        rowsPerPage: rowsPerPage
      }),
    });
  };

  const customFilters = (
    <Stack className={classes.customFilters}>
      {
        filterGroupData?.map((filter, index) =>
          <Chip
            color="primary"
            key={index}
            variant={filter.id === parseInt(urlQuery?.filterId) ?? 0 ? "filled" : "outlined"}
            label={filter.name}
            deleteIcon={<Edit />}
            onDelete={() => handleEdit(filter.id)}
            onClick={() => handleFilter(filter.id)} />
        )
      }
      <Chip color="primary" label={<Add fontSize="small" />} sx={{ "span": { display: "flex" } }} variant="outlined" onClick={() => setAddNewDialog(true)} />
    </Stack>
  )

  const addCustomFilter = (
    <Box sx={{ mt: 2 }}>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        onClick={() => setAddNewDialog(true)}
        startIcon={<Add />}
      >
        {t("addNew")}
      </Button>
    </Box>
  )

  const filterLength = data?.listFilterGroups.length === 0


  const shipmentsSumQuery = keys && getShipmentsSumQuery(keys)
  const sumShipmentsSkip = keys && shipmentsSumQuery.queryFields.length === 0

  const { data: shipmentData, loading: shipmentLoading } = useQuery(
    gql`
      ${IDLE_SHIPMENT(defaultQueryFields(keys, adminNotesPermission)).query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: loading,
      skip: !data || filterLength || !search?.filterId,
      variables: {
        first: rowsPerPage,
        page: page,
        filterId: parseInt(search?.filterId)
      },
    }
  );

  const { data: sumShipments } = useQuery(
    gql`
      ${SUM_IDLE_SHIPMENT(shipmentsSumQuery?.queryFields).query}
    `,
    {
      skip: !shipmentData || sumShipmentsSkip,
      partialRefetch: loading,
      notifyOnNetworkStatusChange: true,
      variables: {
        filterId: parseInt(search?.filterId),
      },

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const listShipmentData = shipmentData?.listShipmentsWithFilterGroup?.data;
  const shipmentDataLength = listShipmentData?.length === 0

  const icons = [
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: checkedIds.length > 0 || !search.filterId || shipmentDataLength
    },
    {
      id: "print",
      title: "print",
      action: openPrintDialog,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: checkedIds.length === 0
    },
    {
      id: "printList",
      title: "printList",
      action: onPrint,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: !search.filterId || shipmentDataLength
    },
    {
      id: "ViewColumn",
      title: "viewColums",
      action: openColumView,
      icon: ViewColumn,
      disabled: !search.filterId || shipmentDataLength
    },
  ]

  const isChecked = (id) => {
    return checkedIds.includes(id);
  };

  const currentShipmentIds = listShipmentData?.map((i) => i.id);
  const shipmentsCount = currentShipmentIds?.length;

  const fieldSelected = currentShipmentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length;

  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < shipmentsCount;
  const selectAllChecked = () =>
    shipmentsCount > 0 && fieldSelected === shipmentsCount;

  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentShipmentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentShipmentIds.includes(i));
    }
    setCheckedIds(ids);
  };

  const checkAll = (
    <FixedTableCell className={classes.checkbox}>
      <Checkbox
        edge="start"
        id="check-all-shipment"
        indeterminate={indeterminate()}
        checked={selectAllChecked()}
        inputProps={{ "aria-label": "select all desserts" }}
        tabIndex={-1}
        onChange={(e) => onCheckAll(e)}
        disableRipple
      />
    </FixedTableCell>
  );

  const ifCode = keys?.find((e) => e === "code")
  const ifStatus = keys?.find((e) => e === "status")
  const defaultTableBody = (shipment, index) => (
    <TableRow key={index} className={classes.tableBodyRow}>
      <FixedTableCell key={`${index}-che`} className={classes.bodyCheckbox}>
        <Checkbox
          edge="start"
          checked={isChecked(shipment?.id)}
          onChange={(e) => toggleCheck(e, shipment?.id)}
          disableRipple
        />
      </FixedTableCell>
      {keys.map((ele, i) => {
        return <Fragment key={i}>
          <GenerateShipmentCell shipment={shipment} elementKey={ele} classes={classes} ifCode={ifCode} adminNotesPermission={adminNotesPermission} />
        </Fragment>
      })}
    </TableRow>
  );

  const sumRow = (
    <TableRow>
      <FixedTableCell className={classes.bodyCheckbox}> </FixedTableCell>
      {ifCode && <FixedTableCell className={classes.shipmentTable_bodyCode}> </FixedTableCell>}
      {ifStatus && <FixedTableCell className={!ifCode ? classes.shipmentTable_bodyCode : classes.shipmentTable_bodyStatus}> </FixedTableCell>}
      {
        sumShipments?.sumShipmentsWithFilterGroup && shipmentsSumQuery.selected.map((i, index) =>
          <Fragment key={index}>
            <GenerateShipmentSumCell
              shipmentSum={sumShipments?.sumShipmentsWithFilterGroup}
              elementKey={i}
            />
          </Fragment>
        )
      }
    </TableRow >
  );

  const memorizedTableBody = useMemo(
    () => defaultTableBody,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked, toggleCheck]
  );

  return (
    <Root>
      {addNewDialog && <IdleDialog
        setOpenDialog={setAddNewDialog}
        openDialog={addNewDialog}
        handleFilter={handleFilter}
        ID={ID}
        setID={setID}
        refetch={refetch}
        keyFilter={setupdate}
      />
      }
      <PrintComponent shipmentsId={checkedIds} openPrint={openPrint} setOpenPrint={setOpenPrint} />
      <ExportShipmentFilter
        filterId={search.filterId}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />
      <ShipmentColumView
        openViewColum={shipmentViewColum}
        setOpenViewColum={setOpenShipmentViewColum}
        localStorageName={"idle-shipments"}
        initialData={keys}
        setKeys={setKeys}
        shipmentFields={shipmentFieldsFun(adminNotesPermission)}
      />

      <ListWrapper
        icons={icons}
        path={props.match.path}
        empty={listShipmentData?.length === 0 || filterGroupData?.length === 0}
        loading={shipmentLoading || loading}
        customFilters={filterGroupDataQuery?.length > 0 && customFilters}
        addCustomFilter={filterGroupDataQuery?.length === 0 && addCustomFilter}
        tableHeaders={
          <ShipmentsTable
            stickyHeader
            className={classes.mainTableClass}
            classes={classes}
            data={shipmentData?.listShipmentsWithFilterGroup?.data ?? []}
            headCells={getShipmentsTableHeader(keys, adminNotesPermission)}
            parseBodyCell={memorizedTableBody}
            sumRow={shipmentsSumQuery.queryFields.length !== 0 && sumRow}
            checkAll={checkAll}
            getHeader={true}
          />
        }
        tableBody={
          <ShipmentsTable
            stickyHeader
            className={classes.mainTableClass}
            classes={classes}
            data={shipmentData?.listShipmentsWithFilterGroup?.data ?? []}
            parseBodyCell={memorizedTableBody}
            sumRow={shipmentsSumQuery.queryFields.length !== 0 && sumRow}
            checkAll={checkAll}
            getBody={true}
          />
        }
        pagination={
          <MUITablePagination
            count={shipmentData?.listShipmentsWithFilterGroup?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default IdleShipments;
