/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import {
  Button,
  TableBody,
  TableRow,
} from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import { Add, FilterList, Search } from "@mui/icons-material";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_SAFES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_VOUCHER_QUERY } from "./VouchersListQuary";
import { dateFormatLL } from "../../helpers/dateFunctions";
import formatMoney from "../../helpers/numbersDot";
import Grid from '@mui/material/Unstable_Grid2';
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import ExportVoucher from "./ExportVoucher";
const PREFIX = "VoucherList";


const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },


  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

//////////////////////////////////////////////////


/////////////////DIALOG///////////////

const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const VoucherList = (props) => {
  const { t } = useTranslation();
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0 && !Object.keys(urlQuery).includes("fromDate", "toDate");
  const [filterLoad, setFilterLoad] = useState(true);
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters ? null : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters ? null : initDate;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [refetch, setrefetch] = useState(true);
  const [queryVar, setQuaryVar] = useState(null);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    deliveryAgent: null,
    glAccount: null,
    safe: null,
    toSafe: null,
  });


  // const [quaryVar, setQuaryVar] = React.useState({
  //     fromDate: initDate,
  //     toDate: initDate,
  //     first: 10,
  //     page:0
  // });

  const screenWidth = useWidth();

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const paymentMethodId = props.match.path === "/admin/vouchers" ? 1 : 2;

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm();


  const history = useHistory();

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param) => {
    const params = searchParams(param);

    const url = history.createHref({
      pathname: paymentMethodId === 1 ? `/admin/vouchers` : `/admin/safes-transfer`,
      search: "?" + params,
    });
    windowUrl(url);
  };

  useEffect(() => {
    const { first, page, ...input } = urlQuery;
    if (input["code"]) input["code"] = input["code"].toString();
    setQuaryVar((prev) => ({
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
      ...prev,
      ...input,
    }));
    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);
    urlQuery["glApproved"] !== undefined &&
      setValue("glApproved", urlQuery["glApproved"]);
    urlQuery["first"] && setRowsPerPage(parseInt(urlQuery["first"]));
    urlQuery["page"] && setPage(parseInt(urlQuery["page"] - 1));
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());

    setDateRange([
      moment(urlQuery["fromDate"]).locale("en").format("YYYY-MM-DD"),
      moment(urlQuery["toDate"]).locale("en").format("YYYY-MM-DD"),
    ]);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    pushUrlSearch({ ...urlQuery, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);

    pushUrlSearch({ ...urlQuery, page: 1, first: +event.target.value });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  ////////////////////END DRAWER////////////////

  const onSubmit = (data) => {
    setFilterLoad(false)
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    (refetch) ? setrefetch(false) : setrefetch(true)
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    data["fromDate"] = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
    data["toDate"] = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
    pushUrlSearch({ ...data, first: rowsPerPage, page: 1 });
    setQuaryVar({
      ...data,
      ...(dateRange[0] && {
        fromDate
      }),
      ...(dateRange[1] && {
        toDate
      }),
    });
    setPage(0);
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_VOUCHER_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !queryVar,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...queryVar,
          paymentMethodId: paymentMethodId
        },
      },
    }
  );

  const voucherData =
    data?.listVouchers?.data !== null ? data?.listVouchers?.data : null;
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const parseData = (data) => {
    return data;
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const addURL = () => {
    paymentMethodId === 1 ?
      pushUrl(props, `/admin/vouchers/create`) : pushUrl(props, `/admin/safes-transfer/create`)
  }
  const [openExport, setOpenExport] = useState(false);
  const openExportDialog = () => {
    setOpenExport(true)
  };
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !state[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: paymentMethodId === 1 ? "cash.voucher.create" : "cash.cash_voucher.create",
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: !data || Boolean(voucherData?.length === 0)
    },
  ]




  const EnhancedTableToolbar = (props) => {
    const { exportFilters } = props;
    const filtersWithPaymentMethodId = {
      ...exportFilters,
      paymentMethodId: paymentMethodId
    };
    return (

      <ExportVoucher
        filters={filtersWithPaymentMethodId}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />

    );
  };

  EnhancedTableToolbar.propTypes = {};

  let voucherAmountTotal = 0;


  const payeeType = watch("payeeType");

  const clearPayeeFeild = () => {
    setValue("customerId", "");
    setValue("glAccountId", "");
    setValue("deliveryAgentId", "");
  };
  const payeeField = (type) => {
    if (type === "SHPCUST") {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            skip={!queryVar || (loading && filterLoad)}
            name={"customerId"}
            label={t("customer")}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["customerId"],
                "customer"
              )
            }
            parseData={(data) => parseData(data)}
            variables={{
              input: {
                active: true,
              },
            }}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    } else if (type === "SHPDLVBY") {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            skip={!queryVar || (loading && filterLoad)}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["deliveryAgentId"],
                "deliveryAgent"
              )
            }
            parseData={(data) => parseData(data)}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            variables={{
              input: {
                active: true,
              },
            }}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    } else {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            skip={!queryVar || (loading && filterLoad)}
            name={"glAccountId"}
            label={t("glAccount")}
            variables={{
              input: {
                typeCode: "SUB",
              },
            }}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["glAccountId"],
                "glAccount"
              )
            }
            parseData={(data) => parseData(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            defaultValue={autocompleteValues.glAccount}
          />
        </Grid>
      );
    }
  };

  let tableHeaders = [
    { name: "code" },
    { name: "date" },
    { name: "safe" },
    { name: "amount" },
    { name: "notes" },
    { name: "approved" },
    { name: "glApproved" },
  ];

  if (paymentMethodId === 1) {
    tableHeaders.splice(
      3,
      0,
      { name: "type" },
      { name: "payeeType" },
      { name: "payee" },
      { name: "branch" }
    );
  }

  if (paymentMethodId === 2) {
    tableHeaders.splice(
      3,
      0,
      { name: "toSafe" },
    );
  }
  return (
    <Root>
      {props.children}
      <EnhancedTableToolbar
        filter={toggleDrawer(filterAnchor, true)}
        exportFilters={queryVar}
        {...props}
      />
      <ListWrapper
        drawerState={state[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={voucherData?.length === 0}
        loading={!queryVar || loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} xs={12}>
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              {paymentMethodId === 1 &&
                <>
                  <Grid item sm={12} xs={12}>
                    <LookupDropdown
                      control={control}
                      errors={errors}
                      skip={!queryVar || (loading && filterLoad)}
                      name={"type"}
                      label={t("type")}
                      variables={{
                        input: { code: "CSH_VOUCHER_TYPES" },
                      }}
                      onCompleted={(data) =>
                        lookupsComplete(data, urlQuery["type"], "type")
                      }
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <LookupDropdown
                      control={control}
                      errors={errors}
                      skip={!queryVar || (loading && filterLoad)}
                      name={"payeeType"}
                      label={t("payeeType")}
                      variables={{
                        input: { code: "PAYEE_TYPE" },
                      }}
                      onChanges={clearPayeeFeild}
                      onCompleted={(data) =>
                        lookupsComplete(data, urlQuery["payeeType"], "payeeType")
                      }
                    />
                  </Grid>
                  {payeeField(payeeType)}
                  <Grid item sm={12}>
                    <CustomAutocomplete
                      name={"branchId"}
                      label={t("currentBranch")}
                      control={control}
                      skip={!queryVar || (loading && filterLoad)}
                      errors={errors}
                      parseData={(data) => parseData(data)}
                      onCompleted={(data) =>
                        handelAutocompleteDefaultValue(
                          data,
                          urlQuery["branchId"],
                          "branch"
                        )
                      }
                      query={LIST_BRANCHES_DROPDOWN.query}
                      defaultValue={autocompleteValues.branch}
                    />
                  </Grid>
                </>}
              <Grid item sm={12} xs={12}>
                <MUIselect
                  control={control}
                  errors={errors}
                  name={"approved"}
                  label={t("status")}
                  data={[
                    { key: t("approved"), value: true },
                    { key: t("notApproved"), value: false },
                  ]}
                  defaultValue={urlQuery?.approved}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <MUIselect
                  control={control}
                  errors={errors}
                  skip={!queryVar || loading}
                  name={"glApproved"}
                  label={t("glApprove")}
                  data={[
                    { key: t("glApproved"), value: true },
                    { key: t("notGlApproved"), value: false },
                  ]}
                  defaultValue={urlQuery?.approved}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"safeId"}
                  label={t("safe")}
                  skip={!queryVar || (loading && filterLoad)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["safeId"],
                      "safe"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_SAFES_DROPDOWN.query}
                  defaultValue={autocompleteValues.safe}
                />
              </Grid>
              {paymentMethodId === 2 &&
                <Grid item sm={12} xs={12}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"toSafe"}
                    label={t("toSafe")}
                    skip={!queryVar || (loading && filterLoad)}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["toSafe"],
                        "toSafe"
                      )
                    }
                    parseData={(data) => data?.filter((item) => item?.id !== watch("safeId"))}
                    query={LIST_SAFES_DROPDOWN.query}
                    defaultValue={autocompleteValues.toSafe}
                  />
                </Grid>}
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={tableHeaders}

        tableBody={
          <TableBody>
            {voucherData &&
              voucherData?.map((row, index) => {
                voucherAmountTotal += row.amount;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   pushUrl(props, `/admin/vouchers/${row?.id}/edit`);
                  // }}
                  >
                    <CellLink
                      pathname={paymentMethodId === 1 ? `/admin/vouchers/${row?.id}` : `/admin/safes-transfer/${row?.id}`}
                      component="th"
                      scope="row"
                    >
                      {row.code}
                    </CellLink>
                    <FixedTableCell>
                      {dateFormatLL(row?.date)}
                    </FixedTableCell>
                    <CellLinkChecker
                      data={row?.safe}
                      url="/admin/safes/"
                    />
                    {paymentMethodId === 1 ?
                      <>
                        <FixedTableCell>{row?.type?.name}</FixedTableCell>
                        <FixedTableCell>
                          {row?.payeeType?.name}
                        </FixedTableCell>

                        <CellLinkChecker
                          data={
                            row?.customer ??
                            row?.deliveryAgent ??
                            row?.glAccount
                          }
                          url={
                            row?.customer
                              ? "/admin/customers/"
                              : row?.deliveryAgent
                                ? "/admin/delivery-agents/"
                                : null
                          }
                        />

                        <CellLinkChecker
                          data={row?.branch}
                          url="/admin/branches/"
                        />
                      </>
                      : <CellLinkChecker
                        data={row?.toSafe}
                        url="/admin/safes/"
                      />}

                    <FixedTableCell>
                      {formatMoney(row?.amount)}
                    </FixedTableCell>
                    <FixedTableCell>{row?.notes}</FixedTableCell>
                    <FixedTableCell>
                      <CheckCrossIcons active={row?.approved} />
                    </FixedTableCell>
                    <FixedTableCell>
                      <CheckCrossIcons active={row?.glApproved} />
                    </FixedTableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <FixedTableCell colSpan={paymentMethodId === 1 ? 6 : 3} />
              <FixedTableCell variant="head">{t("total")}</FixedTableCell>
              <FixedTableCell variant="head">
                {formatMoney(voucherAmountTotal)}
              </FixedTableCell>
              <FixedTableCell colSpan={3} />
            </TableRow>
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.voucherData?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.voucherData ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />

    </Root>
  );
};

export default VoucherList;

const CellLinkChecker = (props) => {
  const { data, url } = props;
  return url ? (
    <CellLink pathname={`${url}${data?.id}`}>{data?.name}</CellLink>
  ) : (
    <FixedTableCell>{data?.name} </FixedTableCell>
  );
};
