import React from "react";
import {
    Breadcrumbs,
    Typography,
} from "@mui/material";
// import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import SpanLink from "../Component/HOC/CustomComponents/SpanLink";

const PREFIX = "WareHouse";

const classes = {
    spanLink: `${PREFIX}-spanLink`,

};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
    [`& .${classes.spanLink}`]: {
        fontSize: "0.9rem"
    },
    [`& .MuiBreadcrumbs-separator`]: {
        fontSize: "1.4rem",
        marginTop: "-4px"
    },
}));

const BreadcrumbsWidget = (props) => {
    const { t } = useTranslation();
    const { path, type, transferType } = props

    const URLArray = path?.split("/");
    URLArray?.shift()

    const URLObj = []

    const manifestName = {
        pkm: "pickupManifestLists",
        rits: "receivedInStore",
        bmt: "branchTransfareTo",
        bmr: "branchTransfareFrom",
        otd: "outForDeliveryLists",
        htr: "retryDeliveryLists",
        rts: "returnToCustomerLists",
        otr: "returnDeliveryLists",
        rtrn: "returnHandlingLists",
        prp: "packagingList",
        std: "sendingToAgentList",
        rcv: "receivingUndeliveredList",
        upkbl: "shipmentsReadyToUnpackLists",
        upkbd: "unpackShipmentsLists",
    };

    const warehouseManifestName = {
        padd: "receiveProductFromCustomer",
        prtrn: "returnProductToCustomer",
        pmbr: "receiveProductTransfer",
        pmbt: "sendProductTransfer",
    };

    const manifestTransferName = {
        rts: "returnTrips",
        dlv: "distributionTrips",
    };

    const collectionTitles = {
        dlvby: "collectFromDeliveryAgents",
        custm: "collectCashShipments",
    };

    const paymentTitles = {
        dlvby: "deliveryAgentPayment",
        custm: "customerPayment",
    };

    const customerRequestName = {
        pmnt: "paymentRequests",
        rtrn: "returnRequests",
        mtrl: "materialRequests",
    };

    const customerName = {
        merchant: "customersList",
        individual: "individualsList",
        concierge: "conciergesList"
    };
    const conciergeRequestsName = {
        top: "topupList",
        wdr: "withdrawList",
    };
    URLArray?.map((el) => {
        switch (el) {
            case "admin":
                URLObj.push(
                    {
                        name: 'home',
                        path: "/admin"
                    }
                );
                break;
            case "alert":
                URLObj.push(
                    {
                        name: "adminAlert",
                        path: `/admin/alert`
                    }
                );
                break;
            case "shipments":
                URLObj.push(
                    {
                        name: 'shipments',
                        path: "/admin/shipments"
                    }
                );
                if (URLArray.some((e) => e.includes('id'))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e.includes('import-messages'))) {
                    URLObj.push({
                        name: 'ImportShipmentsMessages',
                    });
                } else if (URLArray.some((e) => e.includes('import'))) {
                    URLObj.push({
                        name: 'importShipmentFromExcel',
                    });
                }
                break;
            case "shipments-transactions":
                URLObj.push(
                    {
                        name: 'shipmentsTransactions',
                        path: "/admin/shipments-transactions"
                    }
                );
                break;
            case "shipments-in-warehouse":
                URLObj.push(
                    {
                        name: 'shipmentsInWarehouse',
                        path: "/admin/shipments-in-warehouse"
                    }
                );
                break;
            case "delivery-list-actions":
                URLObj.push(
                    {
                        name: 'outToDeliver',
                        path: "/admin/delivery-list-actions"
                    }
                );
                break;
            case "receive-undelivered-shipments":
                URLObj.push(
                    {
                        name: 'receiveUndeliveredShipments',
                        path: "/admin/receive-undelivered-shipments"
                    }
                );
                break;
            case "unpacked-shipments":
                URLObj.push(
                    {
                        name: 'unpackShipments',
                        path: "/admin/unpacked-shipments"
                    }
                );
                break;
            case "concierge-request":
                URLObj.push(
                    {
                        name: conciergeRequestsName[type?.toLowerCase()],
                        path: `/admin/concierge-request/${type?.toLowerCase()}`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "barcode-batches":
                URLObj.push(
                    {
                        name: 'barcodebatches',
                        path: "/admin/barcode-batches"
                    }
                );
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e.includes('id'))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "pickups":
                URLObj.push(
                    {
                        name: 'pickupRequests',
                        path: "/admin/pickups"
                    }
                );
                if (URLArray.some((e) => e.includes('id'))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    // URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "manifests":
                if (transferType) {
                    URLObj.push(
                        {
                            name: manifestTransferName[transferType?.toLowerCase()],
                            path: `/admin/manifests/bmt/${transferType?.toLowerCase()}`
                        }
                    );
                } else {
                    URLObj.push(
                        {
                            name: manifestName[type?.toLowerCase()],
                            path: `/admin/manifests/${type?.toLowerCase()}`
                        }
                    );
                }
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "collections":
                URLObj.push(
                    {
                        name: collectionTitles[type?.toLowerCase()],
                        path: `/admin/collections/${type?.toLowerCase()}`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "payments":
                URLObj.push(
                    {
                        name: paymentTitles[type?.toLowerCase()],
                        path: `/admin/payments/${type?.toLowerCase()}`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "invoices":
                URLObj.push(
                    {
                        name: "postpaidInvoices",
                        path: `/admin/invoices`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "delivery-agents":
                URLObj.push(
                    {
                        name: "deliveryAgents",
                        path: `/admin/delivery-agents`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "commission-list":
                URLObj.push(
                    {
                        name: "commissionList",
                        path: `/admin/commission-list`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "routes":
                URLObj.push(
                    {
                        name: "deliveryAgentRoutes",
                        path: `/admin/routes`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "uncollected-from-delivery-agents":
                URLObj.push(
                    {
                        name: "uncollectedFromDeliveryAgents",
                        path: `/admin/uncollected-from-delivery-agents`
                    }
                );
                break;
            case "gl-account":
                URLObj.push(
                    {
                        name: "accountIndex",
                        path: `/admin/gl-account`
                    }
                );
                break;
            case "finance":
                if (URLArray.some((e) => e.includes('trialBalance'))) {
                    URLObj.push(
                        {
                            name: "trialBalance",
                            path: `/admin/finance/statement/trialBalance`
                        }
                    );
                }
                if (URLArray.some((e) => e.includes('main'))) {
                    URLObj.push(
                        {
                            name: "mainAccountStatement",
                            path: `/admin/finance/statement/main`
                        }
                    );
                }
                if (URLArray.some((e) => e.includes('sub'))) {
                    URLObj.push(
                        {
                            name: "accountStatement",
                            path: `/admin/finance/statement/sub`
                        }
                    );
                }
                if (URLArray.some((e) => e.includes('journal-entry'))) {
                    URLObj.push(
                        {
                            name: "journalEntries",
                            path: `/admin/finance/journal-entry`
                        }
                    );
                    if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                        URLObj.push(
                            {
                                name: 'details',
                            }
                        );
                    }
                    if (URLArray.some((e) => e === 'create')) {
                        URLObj.push(
                            {
                                name: 'create',
                            }
                        );
                    }
                    if (URLArray.some((e) => e === 'edit')) {
                        URLObj.pop()
                        URLObj.push(
                            {
                                name: 'update',
                            }
                        );
                    }
                }
                break;
            case "vouchers":
                URLObj.push(
                    {
                        name: "vouchers",
                        path: `/admin/vouchers`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "safes-transfer":
                URLObj.push(
                    {
                        name: "safesTransfer",
                        path: `/admin/safes-transfer`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "sacks":
                URLObj.push(
                    {
                        name: "sacksList",
                        path: `/admin/sacks`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "create-sheets":
                URLObj.push(
                    {
                        name: "createSheets",
                        path: `/admin/create-sheets`
                    }
                );
                break;
            case "products":
                URLObj.push(
                    {
                        name: "productList",
                        path: `/admin/products`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "products-transactions":
                URLObj.push(
                    {
                        name: "productsTransactions",
                        path: `/admin/products-transactions`
                    }
                );
                break;
            case "warehouse-manifests":
                URLObj.push(
                    {
                        name: warehouseManifestName[type?.toLowerCase()],
                        path: `/admin/warehouse-manifests/${type?.toLowerCase()}`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "warehouse-pickups":
                URLObj.push(
                    {
                        name: "pickupRequestsList",
                        path: `/admin/warehouse-pickups`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "organization":
                URLObj.push(
                    {
                        name: "organizationData",
                        path: `/admin/organization`
                    }
                );
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "branches":
                URLObj.push(
                    {
                        name: "branches",
                        path: `/admin/branches`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "banks":
                URLObj.push(
                    {
                        name: "banks",
                        path: `/admin/banks`
                    }
                );
                break;
            case "zones":
                URLObj.push(
                    {
                        name: "zonesAndSubzones",
                        path: `/admin/zones`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "shipping-services":
                URLObj.push(
                    {
                        name: "shippingServicesList",
                        path: `/admin/shipping-services`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "cancellation-reasons":
                URLObj.push(
                    {
                        name: "cancellationReasons",
                        path: `/admin/cancellation-reasons`
                    }
                );
                break;
            case "transaction-types":
                URLObj.push(
                    {
                        name: "transactionTypes",
                        path: `/admin/transaction-types`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "safes":
                URLObj.push(
                    {
                        name: "safe",
                        path: `/admin/safes`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "warehouse":
                URLObj.push(
                    {
                        name: "WareHouseSections",
                        path: `/admin/warehouse`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "subsidiaries":
                URLObj.push(
                    {
                        name: "subsidiaries",
                        path: `/admin/subsidiaries`
                    }
                );
                break;
            case "journal-types":
                URLObj.push(
                    {
                        name: "journalType",
                        path: `/admin/journal-types`
                    }
                );
                break;
            case "lookup":
                URLObj.push(
                    {
                        name: "lookup",
                        path: `/admin/lookup`
                    }
                );
                break;
            case "tickets":
                URLObj.push(
                    {
                        name: "complaints",
                        path: `/admin/crm/tickets`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                break;
            case "categories":
                URLObj.push(
                    {
                        name: "supportCategories",
                        path: `/admin/crm/categories`
                    }
                );
                break;
            case "teams":
                URLObj.push(
                    {
                        name: "supportTeams",
                        path: `/admin/crm/teams`
                    }
                );
                break;
            case "customer-requests":
                URLObj.push(
                    {
                        name: customerRequestName[type?.toLowerCase()],
                        path: `/admin/customer-requests/${type?.toLowerCase()}`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "users":
                URLObj.push(
                    {
                        name: "users",
                        path: `/admin/users`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "roles":
                URLObj.push(
                    {
                        name: "roles",
                        path: `/admin/roles`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "customers":
                URLObj.push(
                    {
                        name: customerName[type?.toLowerCase()],
                        path: `/admin/customers/${type?.toLowerCase()}`
                    }
                );
                if (URLArray.some((e) => e.includes(':id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "price-list":
                URLObj.push(
                    {
                        name: "listPriceLists",
                        path: `/admin/price-list/${type?.toLowerCase()}`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.pop()
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "registrations":
                URLObj.push(
                    {
                        name: "registeredAccounts",
                        path: `/admin/registrations`
                    }
                );
                break;
            case "not-paid-to-customer":
                URLObj.push(
                    {
                        name: "shipmentNotPaidToCustomer",
                        path: `/admin/not-paid-to-customer`
                    }
                );
                break;
            case "profile":
                URLObj.push(
                    {
                        name: "yourAccount",
                        path: `/admin/profile`
                    }
                );
                break;
            case "stocktake":
                URLObj.push(
                    {
                        name: "stocktake",
                    }
                );

                break;
            case "shipping-settings":
                URLObj.push({
                    name: "shippingSettings",
                    path: "/admin/shipping-settings",
                });
                break;
            case "accounting-settings":
                URLObj.push({
                    name: "accountingSettings",
                    path: "/admin/accounting-settings",
                });
                break;
            case "customer-new-shipment":
                URLObj.push({
                    name: "newSHipments",
                });
                break;

            case "delivery-agents-integration":
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'createDeliveryAgentsIntegration',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    // URLObj.pop()
                    URLObj.push(
                        {
                            name: 'updateDeliveryAgentsIntegration',
                        }
                    );
                }
                break;


            case "delivery-agent-clearance":
                URLObj.push(
                    {
                        name: "deliveryAgentClearance",
                        path: `/admin/delivery-agent-clearance`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                break;
            case "customer-due-inquiry":
                URLObj.push({
                    name: "customerDueInquiry",
                });
                break;
            case "currency":
                URLObj.push({
                    name: "currency",
                });
                break;
            case "financial-year":
                URLObj.push(
                    {
                        name: "financialYear",
                        path: `/admin/finance/financial-year`
                    }
                );
                if (URLArray.some((e) => e.includes('id') || !isNaN(e))) {
                    URLObj.push(
                        {
                            name: 'details',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'create')) {
                    URLObj.push(
                        {
                            name: 'create',
                        }
                    );
                }
                if (URLArray.some((e) => e === 'edit')) {
                    URLObj.push(
                        {
                            name: 'update',
                        }
                    );
                }
                break;
            case "concierge-providers":
                URLObj.push({
                    name: "conciergeProviders",
                });
                break;
            case "uncollected-from-delivery-agents-concierge":
                URLObj.push(
                    {
                        name: "uncollectedFromDeliveryAgents",
                        path: `/admin/uncollected-from-delivery-agents-concierge`
                    }
                );
                break;
            case "idle-shipments":
                URLObj.push(
                    {
                        name: "idleShipments",
                        path: `/admin/idle-shipments`
                    }
                );
                break;

            default:
                break;
        }
        return {};
    })

    return (
        <Root>
            <Breadcrumbs aria-label="breadcrumb" separator={"»"}>
                {URLObj.map((ele, i) =>
                    i < URLObj.length - 1 ?
                        <SpanLink
                            className={classes.spanLink}
                            pathname={ele.path}
                            key={`${i}-${ele}`}
                        >
                            {t(ele.name)}
                        </SpanLink> :
                        <Typography variant="body1" key={`${i}-${ele}`} color="text.primary">{t(ele.name)}</Typography>
                )}
            </Breadcrumbs>
        </Root>
    );
};

export default BreadcrumbsWidget;
