import { useQuery, gql, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { PRICE_LIST, SAVE_PRICE_LIST, SAVE_PRICE_LIST_PICKUP } from "./Graphql";
import PriceListTable from "./PriceListTables";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormHelperText,
  Paper,
  Collapse,
  InputAdornment,
} from "@mui/material";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import {
  LIST_SHIPPING_SERVICES_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { useHistory } from "react-router";
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
const PREFIX = "PriceListForm";

const classes = {
  mainForm: `${PREFIX}-mainForm`,
  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainForm}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },

}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const PriceListForm = (props) => {
  const { onSave, customerName, customerType, id } = props;
  const history = useHistory();

  const [pickupList, setPickupList] = useState();
  const [pickupIndex, setPickupIndex] = useState({
    index: 0,
    update: false,
    duplicate: false,
  });
  const [destinationIndex, setDestinationIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState({
    dialog: false,
    pickup: false,
    destination: false,
  });
  const [pickupErrorMessage, setPickupErrorMessage] = useState(false);
  const [destinationErrorMessage, setDestinationErrorMessage] = useState(false);
  const [selectedNames, setSelectedNames] = useState({
    service: [],
    zone: "",
    subzone: "",
  });
  const formType = customerType ?? props.match?.params?.type?.toUpperCase();

  const [priceListId, setPriceListId] = useState(props?.match?.params?.id === "0" ? 0 : parseInt(props?.match?.params?.id))
  useEffect(() => {
    id && setPriceListId(id === "0" ? 0 : parseInt(id))
    return () => { }
  }, [id])

  const priceListCopyId = parseInt(props?.match?.params?.copyId);
  const [priceListType, setPriceListType] = useState(formType ? formType : null)
  const { t } = useTranslation();

  const {
    control,
    formState,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      service: "",
      zone: "",
      subzone: "",
      weightUpTo: "",
      weightStartFees: "",
      weightExtraUnit: "",
      weightExtraUnitFees: "",
      returnFees: "",
      collectionStartFees: "",
      collectionUpTo: "",
      collectionExtraUnitFees: "",
      // collectionExtraUnit: "",
    },
  });
  const {
    control: mainControl,

    formState: { errors: mainErrors },
    handleSubmit: mainHandleSubmit,
    setValue: mainSetValue,
    setError: mainSetError,
  } = useForm({
    defaultValues: {
      name: customerName ?? "",
      active: true,
      default: false,
    },
  });
  const { errors } = formState;

  const { enqueueSnackbar } = useSnackbar();

  const [savePriceList, { loading: savePriceListLoad }] = useMutation(
    gql`
      ${SAVE_PRICE_LIST.query}
    `
  );
  const [savePriceListPickup] = useMutation(
    gql`
      ${SAVE_PRICE_LIST_PICKUP.query}
    `
  );

  const [disabledDefault, setDisabledDefault] = useState(false)

  const { data: pricListData, loading: priceListLoading } = useQuery(
    gql`
      ${PRICE_LIST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip:
        !priceListId &&
        priceListId !== 0 &&
        !priceListCopyId &&
        priceListCopyId !== 0,
      variables: { id: priceListId | priceListCopyId },
      onCompleted: (data) => {
        setPriceListType(data.priceList.customerType.code)
        data?.priceList?.pickups && setPickupList(data?.priceList.pickups);
        data?.priceList?.description &&
          mainSetValue("description", data?.priceList?.description);
        (priceListId || priceListId === 0) &&
          mainSetValue("code", data?.priceList.code);
        const nameValue =
          priceListCopyId || priceListCopyId === 0
            ? t("copy") + " " + data?.priceList.name
            : data?.priceList.name;
        mainSetValue("name", nameValue);
        mainSetValue("active", data.priceList.active);
        mainSetValue("default", data.priceList.default);
        setDisabledDefault(!data.priceList.active)
      },
    }
  );

  const parseData = (data) => {
    return data;
  };

  const handelCloseDialog = () => {
    setDialog({
      dialog: false,
      pickup: false,
      destination: false,
    });
    setPickupErrorMessage(false);
    setDestinationErrorMessage(false);
    setPickupIndex((prev) => ({ ...prev, update: false, duplicate: false }));
    setDestinationIndex((prev) => ({ ...prev, update: false }));
    reset();
  };

  const onChangeNames = (e, parameter, remove) => {
    const name = e?.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setPickupErrorMessage(false);
    setDestinationErrorMessage(false);
  };
  const onChangeServiceNames = (e, parameter) => {
    const names = []
    e && e?.map(ele => names.push(ele.name))
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: names,
    }));
    setPickupErrorMessage(false);
  };

  const addPickupDialog = (index, duplicate) => {
    reset();
    if (duplicate) {
      setPickupIndex((prev) => ({
        ...prev,
        index,
        duplicate: true,
      }));
    } else if (index || index === 0) {
      setPickupIndex((prev) => ({
        ...prev,
        index,
        update: true,
      }));
    } else {
      setPickupIndex({
        index,
        update: false,
        duplicate: false,
      });
    }
    setDialog({
      dialog: true,
      pickup: true,
      destination: false,
    });
  };
  const addDestinationDialog = (pickupIndex, destinationIndex) => {
    reset();

    setPickupIndex({
      index: pickupIndex,
      update: false,
    });

    if (destinationIndex || destinationIndex === 0) {
      setDestinationIndex({
        index: destinationIndex,
        update: true,
      });
    }

    setDialog({
      dialog: true,
      destination: true,
      pickup: false,
    });
  };

  const onSubmitPickup = (data) => {
    for (const key in data) {
      if ([undefined, "", null].includes(data[key]) || (Array.isArray(data[key]) && data[key].length === 0)) {
        delete data[key];
      }
    }
    const services = []
    for (let i = 0; i < data.service.length; i++) {
      services.push({ id: data.service[i], name: selectedNames.service[i] })
    }
    const newPickup = {
      services: services,
      zone: priceListType !== "CONCIERGE" && { id: data.zone, name: selectedNames.zone },
      subzone: data.subzone
        ? { id: data.subzone, name: selectedNames.subzone }
        : null,
      // destinations: [],
    };
    const updatePickups = pickupList ? [...pickupList] : [];
    const invalidPickup = priceListType !== "CONCIERGE" && updatePickups.some((i, index) =>
      pickupIndex.update && index === pickupIndex.index
        ? false
        : i.services.some(i => newPickup.services.some(x => i.id === x.id)) &&
        i.zone.id === newPickup.zone.id &&
        i.subzone?.id === newPickup.subzone?.id
    );
    const invalidConciergePickup = priceListType === "CONCIERGE" && updatePickups.some((i, index) =>
      pickupIndex.update && index === pickupIndex.index
        ? false
        : i.services.some(i => newPickup.services.some(x => i?.id === x?.id))
    );

    if (invalidConciergePickup) {
      setPickupErrorMessage(true);
      return;
    }
    if (invalidPickup) {
      setPickupErrorMessage(true);
      return;
    }
    if (pickupIndex.update || pickupIndex.duplicate) {
      const updatedPickup = {
        ...updatePickups[pickupIndex.index],
        ...newPickup,
      };
      if (pickupIndex.duplicate) {
        updatedPickup["id"] = updatedPickup["id"] + 1
        if (priceListType === "CONCIERGE") {
          updatedPickup["destinations"].map(i => {
            delete i["weightUpTo"];
            delete i["weightExtraUnit"];
            delete i["weightExtraUnitFees"];
            delete i["returnFees"];

            return i;
          })
        }
      }

      if (pickupIndex.duplicate) {
        updatePickups.splice(pickupIndex.index + 1, 0, updatedPickup);
      } else {
        updatePickups[pickupIndex.index] = updatedPickup;
      }
      const servicesIds = updatedPickup.services.map(item => item.id);
      const destinations = updatedPickup?.destinations?.length > 0
        ? [
          ...updatedPickup.destinations.map((i) => {
            for (const key in i) {
              if ([undefined, "", null].includes(i[key])) {
                delete i[key];
              }
            }
            const destination = {
              ...i,
              zoneId: i.zone.id,
              ...(i?.subzone?.id && { subzoneId: i.subzone.id }),
            };
            delete destination["zone"];
            delete destination["subzone"];
            return destination;
          }),
        ]
        : [];
      savePriceListPickup({
        variables: {
          input: {
            ...(pickupIndex.update && { id: updatedPickup.id }),
            priceListId: priceListId,
            serviceIds: servicesIds,
            zoneId: newPickup.zone.id,
            subzoneId: newPickup.subzone?.id ?? null,
            destinations: destinations
          },
        },
      })
      setPickupIndex({
        index: pickupIndex.duplicate ? pickupList.length : pickupIndex.index,
        update: false,
        duplicate: false,
      });
    } else {
      const servicesIds = newPickup.services.map(item => item.id);
      savePriceListPickup({
        variables: {
          input: {
            priceListId: priceListId,
            serviceIds: servicesIds,
            zoneId: newPickup.zone.id,
            ...(newPickup.subzone && { subzoneId: newPickup.subzone.id }),
            destinations: []
          },
        },
      })
        .then((data) => {
          newPickup.id = data.data.savePriceListPickup.id
        })
      updatePickups.unshift(newPickup);
      setPickupIndex({
        index: 0,
        update: false,
        duplicate: false,
      });
    }
    setPickupList(updatePickups);
    handelCloseDialog();
  };

  const onSubmitDestination = (data) => {
    for (const key in data) {
      if ([undefined, "", null].includes(data[key]) || (Array.isArray(data[key]) && data[key].length === 0)) {
        delete data[key];
      }
    }
    const newDestination = {
      zone: { id: data.zone, name: selectedNames.zone },
      subzone: data.subzone
        ? { id: data.subzone, name: selectedNames.subzone }
        : null,
      weightUpTo: parseFloat(data.weightUpTo),
      weightStartFees: parseFloat(data.weightStartFees),
      weightExtraUnit: parseFloat(data.weightExtraUnit),
      weightExtraUnitFees: parseFloat(data.weightExtraUnitFees),
      returnFees: parseFloat(data.returnFees),
      collectionStartFees: parseFloat(data.collectionStartFees),
      collectionUpTo: parseFloat(data.collectionUpTo),
      collectionExtraUnitFees: parseFloat(data.collectionExtraUnitFees),
      // collectionExtraUnit: parseFloat(data.collectionExtraUnit),
    };
    const updatePickups = pickupList ? [...pickupList] : [];
    const destinations =
      updatePickups[pickupIndex.index]?.["destinations"] ?? [];
    const upadteDsitination = [...destinations];

    const invalidPickup = upadteDsitination.some((i, index) =>
      destinationIndex.update && index === destinationIndex.index
        ? false
        : i.zone.id === newDestination.zone.id &&
        i.subzone?.id === newDestination.subzone?.id
    );

    if (invalidPickup) {
      setDestinationErrorMessage(true);
    } else {
      if (destinationIndex.update) {
        upadteDsitination[destinationIndex.index] = {
          ...upadteDsitination[destinationIndex.index],
          ...newDestination,
        };
      } else {
        upadteDsitination.unshift(newDestination);
        setDestinationIndex({
          index: 0,
          update: false,
        });
      }
      updatePickups[pickupIndex.index] = {
        ...updatePickups[pickupIndex.index],
        destinations: upadteDsitination,
      };
      const destinations = updatePickups[pickupIndex.index].destinations.length > 0
        ? [
          ...updatePickups[pickupIndex.index].destinations.map((i) => {
            const destination = {
              ...i,
              zoneId: i.zone.id,
              ...(i?.subzone?.id && { subzoneId: i.subzone.id }),
            };
            delete destination["zone"];
            delete destination["subzone"];
            return destination;
          }),
        ]
        : [];
      const servicesIds = updatePickups[pickupIndex.index].services.map(item => item.id);
      const input = {
        id: updatePickups[pickupIndex.index].id,
        priceListId: priceListId,
        serviceIds: servicesIds,
        destinations: destinations.map(i => {
          if (priceListType === "CONCIERGE") {
            delete i["weightUpTo"];
            delete i["weightExtraUnit"];
            delete i["weightExtraUnitFees"];
            delete i["returnFees"];
          }
          return i;
        }),
      };
      const zoneId = updatePickups[pickupIndex.index].zone?.id ?? null;

      if (zoneId !== null) {
        input.zoneId = zoneId;
      }
      if (updatePickups[pickupIndex.index].subzone) {
        input.subzoneId = updatePickups[pickupIndex.index].subzone.id;
      }
      savePriceListPickup({
        variables: {
          input
        },
      })

      setPickupList(updatePickups);
      handelCloseDialog();
    }
  };

  const submitMutation = (data) => {
    // const pickups = pickupList
    //   ? pickupList.map((i) => {
    //     const serviceIds = []
    //     i.services.map(ele => serviceIds.push(ele.id))
    //     const destinations = i?.destinations
    //       ? [
    //         ...i.destinations.map((i) => {
    //           const destination = {
    //             ...i,
    //             zoneId: i.zone.id,
    //             ...(i?.subzone?.id && { subzoneId: i.subzone.id }),
    //           };
    //           delete destination["zone"];
    //           delete destination["subzone"];
    //           return destination;
    //         }),
    //       ]
    //       : [];
    //     const pickup = {
    //       serviceIds: serviceIds,
    //       zoneId: i.zone.id,
    //       ...(i?.subzone?.id && { subzoneId: i.subzone.id }),
    //       ...(destinations.length >= 0 && { destinations }),
    //     };
    //     return pickup;
    //   })
    //   : [];
    savePriceList({
      variables: {
        input: {
          ...((priceListId || priceListId === 0) && { id: priceListId }),
          ...(data.code && { code: data.code }),
          ...(data.description && { description: data.description }),
          name: data.name,
          active: data.active,
          default: data.default,
          customerTypeCode: priceListType,
          // pickups,
        },
      },
    })
      .then((data) => {
        if (Boolean(onSave)) {
          onSave(data);
        } else {
          const url = history.createHref({
            pathname: `/admin/price-list/${data?.data?.savePriceList?.id}/edit`,
          });
          windowReplaceUrl(url);
          setPriceListId(data?.data?.savePriceList?.id)
        }
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, mainSetError);
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const pickupForm = (
    <PickupForm
      handleSubmit={handleSubmit}
      onSubmitPickup={onSubmitPickup}
      control={control}
      errors={errors}
      parseData={parseData}
      onChangeNames={onChangeNames}
      onChangeServiceNames={onChangeServiceNames}
      pickupIndex={pickupIndex}
      pickupList={pickupList}
      setValue={setValue}
      watch={watch}
      pickupErrorMessage={pickupErrorMessage}
      handelCloseDialog={handelCloseDialog}
      setSelectedNames={setSelectedNames}
      priceListType={priceListType}
    />
  );
  const destinationForm = (
    <DestinationForm
      handleSubmit={handleSubmit}
      onSubmitDestination={onSubmitDestination}
      control={control}
      errors={errors}
      parseData={parseData}
      setValue={setValue}
      onChangeNames={onChangeNames}
      destinationIndex={destinationIndex}
      pickupList={pickupList}
      pickupIndex={pickupIndex}
      watch={watch}
      destinationErrorMessage={destinationErrorMessage}
      handelCloseDialog={handelCloseDialog}
      setSelectedNames={setSelectedNames}
      getValues={getValues}
      priceListType={priceListType}
    />
  );

  return priceListLoading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !pricListData && priceListId ? (
      <NotFound />
    ) : (

      <Root>
        <Dialog
          fullWidth
          maxWidth="xs"
          open={dialog.dialog}
          onClose={handelCloseDialog}
        >
          {dialog.destination ? destinationForm : null}
          {dialog.pickup ? pickupForm : null}
        </Dialog>
        {props?.match?.path && <TitleAppBar path={props.match.path} type={priceListType} />}
        <Paper
          className={classes.paper}
          component="form"
          onSubmit={mainHandleSubmit(submitMutation)}
          sx={{ marginTop: !(props?.match?.path) && "68px !important" }}
        >
          <Grid container spacing={2} className={classes.mainForm}>
            <Grid container xs={12} sm={12} justifyContent="end">
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={mainControl}
                onChange={
                  (e) => {
                    if (e.target.checked) {
                      setDisabledDefault(false)
                    } else {
                      setDisabledDefault(true)
                      mainSetValue("default", false)
                    }
                  }
                }
              />
              <MuiSwitch
                edge="end"
                name="default"
                disabled={disabledDefault}
                label={t("default")}
                control={mainControl}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <ControlMUItextField
                control={mainControl}
                errors={mainErrors}
                name="code"
                label={t("code")}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <ControlMUItextField
                control={mainControl}
                errors={mainErrors}
                name="name"
                label={t("name")}
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>
            <Grid xs={12}>
              <ControlMUItextField
                control={mainControl}
                errors={mainErrors}
                name="description"
                label={t("description")}
              />
            </Grid>
            <Grid xs={12} justifyContent="flex-end">
              <FormButton
                className={classes.button}
                disabled={savePriceListLoad}
              >
                {savePriceListLoad ? <ButtonLoading /> : t("save")}
              </FormButton>
            </Grid>
          </Grid>
        </Paper>
        <PriceListTable
          priceListId={priceListId}
          pickups={pickupList}
          addPickup={addPickupDialog}
          addDestination={addDestinationDialog}
          setPickupList={setPickupList}
          priceListType={priceListType}
        />


      </Root>
    );
};

export default PriceListForm;

function DestinationForm({
  handleSubmit,
  onSubmitDestination,
  control,
  errors,
  parseData,
  setValue,
  onChangeNames,
  destinationIndex,
  pickupList,
  pickupIndex,
  destniationQueryWithIdComplete,
  watch,
  destinationErrorMessage,
  handelCloseDialog,
  setSelectedNames,
  getValues,
  priceListType
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    zone: null,
    subzone: null,
  });
  useEffect(() => {
    if (destinationIndex.update) {
      const update =
        pickupList[pickupIndex.index]["destinations"][destinationIndex.index];
      setAutocompleteValues({
        ...update,
      });
      setSelectedNames((prev) => ({
        ...prev,
        ...(update?.zone?.name && { zone: update?.zone?.name }),
        ...(update?.subzone?.name && { subzone: update?.subzone?.name }),
      }));
      for (const key in update) {
        if (key !== "zone" && key !== "subzone") {
          setValue(key, update[key]);
        }
      }
    } else {
      const update = pickupList[pickupIndex.index]?.["destinations"]?.[0];

      if (update) {
        for (const key in update) {
          if (key !== "zone" && key !== "subzone") {
            setValue(key, update[key]);
          }
        }
      }
      else {
        [
          "weightExtraUnitFees",
          "weightExtraUnit",
          "collectionStartFees",
          "collectionExtraUnitFees",
        ].forEach((i) => {
          setValue(i, 0);
        });
        setValue("collectionUpTo", 1000);
        // setValue("collectionExtraUnit", 1000);
        setValue("weightUpTo", 1);
      }
      // setValue("collectionExtraUnit",1000)
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmitDestination)}>
      <DialogTitle color={"text.primary"}>{t("addDestinationArea")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"zone"}
              label={t("zone")}
              parseData={(data) => parseData(data)}
              query={LIST_ZONES_DROPDOWN.query}
              variables={{
                input: {
                  active: true,
                  parentId: null,
                },
              }}
              onChangeValue={(e) => {
                setValue("subzone", "");
                onChangeNames(e, "zone", "subzone");
              }}
              defaultValue={autocompleteValues.zone}
            />
          </Grid>
          <Grid xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"subzone"}
              label={t("subzone")}
              parseData={(data) => parseData(data)}
              query={LIST_ZONES_DROPDOWN.query}
              skip={!watch("zone")}
              variables={{
                input: {
                  parentId: watch("zone"),
                  active: true,
                },
              }}
              onChangeValue={(e) => onChangeNames(e, "subzone")}
              defaultValue={autocompleteValues.subzone}
            />
          </Grid>
          {priceListType !== "CONCIERGE" &&
            <Grid xs={12} sm={6}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"weightUpTo"}
                label={t("weightUpTo")}
                type="number"
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>}
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"weightStartFees"}
              label={t("deliveryFees")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
              onBlur={(e) => {
                Boolean(!getValues().returnFees) &&
                  setValue("returnFees", getValues().weightStartFees, {
                    shouldDirty: true,
                  });
              }}
            />
          </Grid>
          {priceListType !== "CONCIERGE" &&
            <>
              <Grid xs={12} sm={6}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"weightExtraUnit"}
                  label={t("extraWeight")}
                  type="number"
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"weightExtraUnitFees"}
                  label={t("extraWeightCost")}
                  type="number"
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"returnFees"}
                  label={t("returnFees")}
                  type="number"
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
            </>}

          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"collectionStartFees"}
              label={t("collectionFees")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"collectionUpTo"}
              label={t("collectionUpTo")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"collectionExtraUnitFees"}
              label={t("collectionExtraUnitFees")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
              InputProps={{
                endAdornment: <InputAdornment position="end"><PercentRoundedIcon /></InputAdornment>,
              }}
            />
          </Grid>
          {/* <Grid xs={12}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"collectionExtraUnit"}
              label={t("collectionExtraUnit")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid> */}
        </Grid>
        {destinationErrorMessage && (
          <FormHelperText error>{t("thisZoneAlreadyExists")}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">{t("confirm")}</Button>
      </DialogActions>
    </form>
  );
}

function PickupForm({
  handleSubmit,
  onSubmitPickup,
  control,
  errors,
  parseData,
  onChangeNames,
  onChangeServiceNames,
  pickupIndex,
  pickupList,
  setValue,
  watch,
  pickupErrorMessage,
  handelCloseDialog,
  setSelectedNames,
  priceListType
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    service: [],
    zone: null,
    subzone: null,
  });
  useEffect(() => {
    if (pickupIndex.update) {
      const update = pickupList[pickupIndex.index];
      const serviceIds = []
      const serviceNames = []
      update.services.map(ele => {
        serviceIds.push(ele.id)
        serviceNames.push(ele.name)
        return ''
      })
      setAutocompleteValues({
        ...update,
        service: serviceIds,
      });
      setSelectedNames((prev) => ({
        ...prev,
        service: serviceNames,
        ...(update?.zone?.name && { zone: update?.zone?.name }),
        ...(update?.subzone?.name && { subzone: update?.subzone?.name }),
      }));
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitPickup)}>
      <DialogTitle color={"text.primary"}>{t("addPickupArea")}</DialogTitle>
      <DialogContent>
        <MultipleAutocomplete
          control={control}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"service"}
          label={t("service")}
          variables={{
            input: {
              active: true,
              customerTypeCode: priceListType
            },
          }}
          limitTags={1000}
          parseData={(data) => parseData(data)}
          query={LIST_SHIPPING_SERVICES_DROPDOWN.query}
          onChangeValue={(e) => onChangeServiceNames(e, "service")}
          margin="normal"
          defaultValue={autocompleteValues.service}
          multiple
          valueKey="id"
        />
        {priceListType !== "CONCIERGE" &&
          <>
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"zone"}
              label={t("zone")}
              parseData={(data) => parseData(data)}
              query={LIST_ZONES_DROPDOWN.query}
              variables={{
                input: {
                  active: true,
                  parentId: null,
                },
              }}
              onChangeValue={(e) => {
                setValue("subzone", "");
                onChangeNames(e, "zone", "subzone");
              }}
              margin="normal"
              defaultValue={autocompleteValues.zone}
            />
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"subzone"}
              label={t("subzone")}
              parseData={(data) => parseData(data)}
              query={LIST_ZONES_DROPDOWN.query}
              skip={!watch("zone")}
              variables={{
                input: {
                  parentId: watch("zone"),
                  active: true,
                },
              }}
              onChangeValue={(e) => onChangeNames(e, "subzone")}
              margin="normal"
              defaultValue={autocompleteValues.subzone}
            />
          </>
        }
        {pickupErrorMessage && (
          <FormHelperText error>{t("thisZoneAlreadyExists")}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">{t("confirm")}</Button>
      </DialogActions>
    </form>
  );
}
