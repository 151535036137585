import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React, { Fragment, useState } from "react";

import { DeleteOutline, Edit, Print } from "@mui/icons-material";
import clsx from "clsx";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { DELETE_MANIFEST_MUTATION, MANIFEST_TRANSFER_SHIPMENT_DATA_VIEW } from "./Graphql";

import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Can } from "../HOC/CustomComponents/Secured";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";

import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { dateTimeFormatA } from "../../helpers/dateFunctions";
import { GetManifestPermissionSlug } from "../../helpers/getPermissionSlug";
import useManifestTransferDetails from "./ManifestTransferDetails";
import { GetStatus } from "../../helpers/manifestStatus";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import config from "../../config.json"
import { gql, useQuery } from "@apollo/client";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CellLink from "../HOC/CustomComponents/CellLink";
import CustomTab from "../HOC/FunctionComponents/CustomTab";

const PREFIX = "ManifestView";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  viewPaper: `${PREFIX}-viewPaper`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  picker: `${PREFIX}-picker`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,
  overlay: `${PREFIX}-overlay`,
  field: `${PREFIX}-field`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(2, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
    alignContent: "space-between"
  },

  [`& .${classes.viewPaper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

export { };

const ManifestTransferView = (props) => {
  const { t } = useTranslation();

  const {
    disableForm,
    disableView,
    manifestType,
    data,
    manifestByIdLoad,
    shipmentListDetails,

    referredManifestsApprove,
    referredManifestData,
    manifestId,
    transferType,
    pageProps,
    setLocalDataKey
  } = props


  const sacksData = data?.manifest?.manifestSacks?.data
  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedShipment: []
  });

  const handleChangePage = (event, newPage) => {
    setShipmentData(prev => {
      return {
        ...prev,
        page: newPage
      }
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setShipmentData(prev => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: event.target.value
      }
    })
  };

  const MANIFEST_SHIPMENT_DATA_VIEW_BUILDER = MANIFEST_TRANSFER_SHIPMENT_DATA_VIEW(shipmentListDetails.shipmentQueryFields ? shipmentListDetails.shipmentQueryFields : [])
  const { loading: loadingShipmentData } = useQuery(
    gql`
        ${MANIFEST_SHIPMENT_DATA_VIEW_BUILDER.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(manifestId),
        page: shipmentData.page + 1,
        first: shipmentData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.manifest === null) return;
        const manifest = data?.manifest;
        const shipments = manifest.entries.data
        setShipmentData(prev => {
          return {
            ...prev,
            pickedShipment: shipments,
            total: manifest.entries.paginatorInfo.total
          }
        })
      },
    }
  );

  const transferTypeCondition = Globals.user.hasPermission(GetManifestPermissionSlug('bmt', 'choose_transfer_type'))
  const { details: manifestDetails } = useManifestTransferDetails({
    type: manifestType,
    referredManifestData,
    typeData: data.manifest,
  });
  const manifestStatus = manifestDetails;

  const user = Globals.user;
  const branchId = data?.manifest?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canAccessRefBranch = user.canAccessBranch(data?.manifest?.referenceBranch?.id);

  const canEdit =
    user.hasPermission(GetManifestPermissionSlug(manifestType?.toLowerCase(), 'update')) &&
    canAccessBranch &&
    !data?.manifest?.approved &&
    !manifestByIdLoad;

  const canCreateBmr =
    user.hasPermission(GetManifestPermissionSlug("bmr", 'create')) && canAccessRefBranch &&
    (!referredManifestsApprove ? true : (referredManifestsApprove && shipmentData.pickedShipment.some(e => e.shipment?.lastTransaction?.shipmentStatus?.code === "BMT"))) &&
    !manifestByIdLoad;

  const canDelete =
    user.hasPermission(GetManifestPermissionSlug(manifestType?.toLowerCase(), 'delete')) &&
    canAccessBranch &&
    !data?.manifest?.approved &&
    !manifestByIdLoad;

  /******************************************* End Manifest List ********************************************/
  let tableBody = null;
  let viewBody = null;
  const tapsArray = [];
  const shipments = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          selectedShipment={shipmentData.pickedShipment}
          manifestType={data?.manifest.transactionType?.type?.code}
          setLocalDataKey={setLocalDataKey}
          total={shipmentData.total}
        />

        {!loadingShipmentData && manifestDetails && (
          <TableFixedHeaderWraper>
            <ShipmentsTable
              data={shipmentData.pickedShipment}
              headCells={manifestStatus.tableHead}
              parseBodyCell={manifestStatus.tableBody}
              total={manifestStatus.tableTotal}
              sumRow={manifestStatus?.sum}
              sumTableNumber={manifestStatus?.sumTableNumber}
              ranking={true}
            />
          </TableFixedHeaderWraper>
        )}

        <Grid container justifyContent="center" alignItems="center">
          {loadingShipmentData ? <FullScreenLoading minHeight={"100px"} /> : shipmentData.total === 0 && manifestId && disableForm && (
            <EmptyTableMessage
              message={t("noResult")}
            />
          )}
        </Grid>

        {shipmentData.total > 0 && (
          <MUITablePagination
            count={shipmentData.total}
            rowsPerPage={shipmentData.rowsPerPage}
            page={shipmentData.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Grid>
  );
  tapsArray.push({
    tabHead:
      <Typography color="inherit" variant="subtitle1" component="div">
        {t("shipments")}
      </Typography>,
    panel: <Paper>{shipments}</Paper>,
  });
  const product = (
    <Grid
      container
      sx={{ m: 0 }}
      className={clsx(classes.table, classes.tabpanel)}
    >
      <TableFixedHeaderWraper component={Paper} className={classes.paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <FixedTableCell>{t("sack")}</FixedTableCell>
              <FixedTableCell align="left">{t("quantity")}</FixedTableCell>
            </TableRow>
          </TableHead>
          {sacksData && (
            <TableBody>
              {sacksData?.map((items, index) => {
                return (
                  <TableRow key={index}>
                    <CellLink
                      align="left"
                      pathname={"/admin/sacks/" + items.sack.id}
                    >
                      {items.sack.name}
                    </CellLink>
                    <FixedTableCell align="left">
                      {items?.quantity}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>

        {sacksData.length === 0 && (
          <EmptyTableMessage loading={false} message={t("noResult")} />
        )}
      </TableFixedHeaderWraper>
    </Grid>
  );
  tapsArray.push({
    tabHead:
      <Typography color="inherit" variant="subtitle1" component="div">
        {t("sacks")}
      </Typography>,
    panel: <Paper>{product}</Paper>,
  });
  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <CustomTab tapDetails={tapsArray} />
    </Grid>
  );

  const [openDelete, setOpenDelete] = useState(false);
  const openDeleteDialog = () => {
    setOpenDelete(true)
  };

  const editURL = () => {
    pushUrl(pageProps, `/admin/manifests/${transferType.toLowerCase()}/${manifestId}/edit`);
  }

  const icons = [
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/manifest/${manifestId}/${manifestType}/${transferType}`
        ),
      icon: Print,
      disabled: manifestByIdLoad
    },
    {
      id: "delete",
      title: "delete",
      action: openDeleteDialog,
      icon: DeleteOutline,
      permission: Boolean(canDelete),
    },
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: data?.manifest?.approved ? canCreateBmr : canEdit,
    },
  ]


  viewBody = (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <MutationWithDialog
          mutaion={DELETE_MANIFEST_MUTATION.query}
          setOpenDelete={setOpenDelete}
          openDelete={openDelete}
          dialogTitle={t("deleteManifest")}
          dialogContent={t("deleteManifestConfirmation")}
          mutaionProps={{ variables: { id: parseInt(manifestId) } }}
          onCompleted={() =>
            pushUrl(pageProps, `/admin/manifests/${manifestType.toLowerCase()}/${transferType.toLowerCase()}`)
          }
          onCompleteMessage={t("manifestDeleted")}
        />
        <TitleAppBar path={props.pageProps.match.path} type={manifestType} transferType={transferType}>
          <LongMenu icons={icons} />
        </TitleAppBar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        {manifestByIdLoad ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Paper
            container
            className={classes.viewPaper}
            sx={{ width: "100%", mt: 1 }}
            component={Grid}
          >
            <KeyValuePair
              title={t("manifestCode")}
              value={data?.manifest?.code}
            />
            <KeyValuePair
              title={t("date")}
              value={dateTimeFormatA(data?.manifest?.date)}
            />
            {transferTypeCondition && (data?.manifest?.transactionType?.type?.code === "BMT" || data?.manifest?.transactionType?.type?.code === "BMR")
              && <KeyValuePair
                title={t("transferType")}
                value={data?.manifest?.transferType?.name}
              />}
            {data?.manifest?.transactionType?.type?.code === "BMR"
              && <KeyValuePair
                title={t("referenceManifest")}
                value={
                  data?.manifest?.referenceManifest && (
                    <SpanLink
                      pathname={`/admin/manifests/${data?.manifest?.referenceManifest?.id}`}
                    >
                      {data?.manifest?.referenceManifest.code}
                    </SpanLink>
                  )
                }
              />}
            {/* {data?.manifest?.transactionType?.type?.code === "BMT"
              && <KeyValuePair
                title={t("referredManifests")}
                value={
                  data?.manifest?.referredManifests && (
                    <SpanLink
                      pathname={`/admin/manifests/${data?.manifest?.referredManifests[0]?.id}`}
                    >
                      {data?.manifest?.referredManifests[0]?.code}
                    </SpanLink>
                  )}
              />} */}
            <KeyValuePair
              title={t("branch")}
              value={
                data?.manifest?.branch?.name && (
                  <SpanLink
                    pathname={`/admin/branches/${data?.manifest?.branch?.id}`}
                  >
                    {data?.manifest?.branch?.name}
                  </SpanLink>
                )
              }
            />
            {data?.manifest?.deliveryAgent ? (
              <KeyValuePair
                title={t("shippingAgent")}
                value={
                  data?.manifest?.deliveryAgent?.name && (
                    <SpanLink
                      pathname={`/admin/delivery-agents/${data?.manifest?.deliveryAgent?.id}`}
                    >
                      {data?.manifest?.deliveryAgent?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            {data?.manifest?.route ? (
              <KeyValuePair
                title={t("route")}
                value={
                  data?.manifest?.route?.name && (
                    <SpanLink
                      pathname={`/admin/routes/${data?.manifest?.route?.id}`}
                    >
                      {data?.manifest?.route?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            <KeyValuePair
              title={t("transactionType")}
              value={
                data?.manifest?.transactionType?.name && (
                  <SpanLink
                    pathname={`/admin/transaction-types/${data?.manifest?.transactionType?.id}`}
                  >
                    {data?.manifest?.transactionType?.name}
                  </SpanLink>
                )
              }
            />
            {data?.manifest?.customer ? (
              <KeyValuePair
                title={t("customer")}
                value={
                  data?.manifest?.customer?.name && (
                    <SpanLink
                      pathname={`/admin/customers/${data?.manifest?.customer?.id}`}
                    >
                      {data?.manifest?.customer?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            {data?.manifest?.referenceBranch ? (
              <KeyValuePair
                title={
                  data?.manifest?.transactionType?.type?.code === "BMT"
                    ? t("toBranch")
                    : t("fromBranch")
                }
                value={
                  data?.manifest?.referenceBranch?.name && (
                    <SpanLink
                      pathname={`/admin/branches/${data?.manifest?.referenceBranch?.id}`}
                    >
                      {data?.manifest?.referenceBranch?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            {/* {data?.manifest?.transactionType?.type?.code === "BMT" ? (
              <KeyValuePair
                title={t("sacksCount")}
                value={data?.manifest?.sacksCount}
              />
            ) : null} */}
            <KeyValuePair title={t("notes")} value={data?.manifest?.notes} />
            <KeyValuePair
              title={t("status")}
              // value={t(GetStatus(data?.manifest?.approved, manifestShipments.length, data?.manifest?.referredManifests))}
              value={t(GetStatus(data?.manifest?.approved, data?.manifest?.entries?.paginatorInfo?.total, data?.manifest?.referredManifests))}
            />
            <KeyValuePair
              title={t("createdBy")}
              value={
                data?.manifest?.createdBy?.username && (
                  <SpanLink
                    pathname={`/admin/users/${data?.manifest?.createdBy?.id}`}
                  >
                    {data?.manifest?.createdBy?.username}
                  </SpanLink>
                )
              }
            />
            <KeyValuePair
              title={t("updatedBy")}
              value={
                data?.manifest?.updatedBy?.username && (
                  <SpanLink
                    pathname={`/admin/users/${data?.manifest?.updatedBy?.id}`}
                  >
                    {data?.manifest?.updatedBy?.username}
                  </SpanLink>
                )
              }
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );

  const tableCondition = Boolean(manifestId) && !manifestByIdLoad;
  return manifestByIdLoad ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading> :
    data?.manifest === null ? (
      <NotFound />
    ) : disableView ? (
      <Can permission="uneEditable" />
    ) : (
      <Root>
        {/* {open && <Dialog
        maxWidth={"xs"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{t("completeTransfer")}</DialogTitle>
        <DialogContent>
          {t('completeTransferMsg')}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button color="primary" disabled={finishLoad} onClick={onSubmit}>
            {finishLoad ? <ButtonLoading /> : t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>} */}
        {props.children}

        {viewBody}

        <Grid
          container
          spacing={3}
          justifyContent="center"
          className={classes.mainTracking}
        >
          {tableCondition && tableBody}
        </Grid>
      </Root>
    );
};

export default ManifestTransferView;
