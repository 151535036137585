import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { gql, useQuery } from "@apollo/client";
import { Add, FilterList, Search } from "@mui/icons-material";
import {
  Button,
  Icon,
  TableBody,
  TableRow,
  TableHead,
  Box,
  Chip,
  colors
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_PAYMENT_TYPES_DROPDOWN,
  LIST_PRICE_LIST_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { Can } from "../HOC/CustomComponents/Secured";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import {
  AutocompleteWithJson,
  CustomAutocomplete,
} from "../HOC/MUI/CustomAutocomplete";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { GetPaymentCycleDays, LIST_CUSTOMERS } from "./Graphql";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import ExportCustomerList from "./ExportCustomersList";
import {
  GetPermissionSlug,
  GetPaymentPermissionSlug,
} from "../../helpers/getPermissionSlug";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import formatMoney from "../../helpers/numbersDot";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";

const PREFIX = "CustomerList";

const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline"
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

const StyledFixedTableCell = styled(Chip)(({ theme, colorcode }) => ({
  color: theme.palette.getContrastText(colorcode),
  backgroundColor: colorcode + "!important",
}));

const CustomerList = (props) => {
  const listType = props.match?.params?.type.toUpperCase();
  const { t } = useTranslation();
  const [refetch, setrefetch] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const urlQuery = urlParameters(window.location.search);

  // const listName = {
  //   B2C: t("customersList"),
  //   C2C: t("individualsList"),
  // };
  const user = Globals.user;

  const paymentPermission = user.hasPermission(GetPaymentPermissionSlug("custm", "create")) || user.hasPermission(GetPaymentPermissionSlug("dlvby", "create"))
  // GetPaymentPermissionSlug("custm", "create"),
  // GetPaymentPermissionSlug("dlvby", "create"),


  const initFromDate = urlQuery["createdFrom"] ? urlQuery["createdFrom"] : null;
  const initToDate = urlQuery["createdTo"] ? urlQuery["createdTo"] : null;
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    zone: null,
    subzone: null,
    paymentTypes: [],
    paymentCycleDay: null,
  });

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const createdFrom = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const createdTo = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { createdFrom }),
      ...(dateRange[1] && { createdTo }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["phone"]) urlQuery["phone"] = urlQuery["phone"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["email"]) urlQuery["email"] = urlQuery["email"].toString();
      if (urlQuery["paymentTypes"])
        urlQuery["paymentTypes"] = urlQuery["paymentTypes"].split(",");
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    urlQuery["idle"] !== undefined && setValue("idle", urlQuery["idle"]);
    urlQuery["warehousing"] !== undefined &&
      setValue("warehousing", urlQuery["warehousing"]);
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["withDue"] && setValue("withDue", urlQuery["withDue"]);
    urlQuery["phone"] && setValue("phone", urlQuery["phone"]);
    urlQuery["email"] && setValue("email", urlQuery["email"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["paymentTypes"] && {
        paymentTypes: urlQuery["paymentTypes"],
      }),
    }));
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const handelAutocompleteWithJsonDefaultValue = (data, id, fieldName) => {
    const defaultValue = data.find((i) => i.id === parseInt(id));
    setAutocompleteValues((prev) => ({
      ...prev,
      [fieldName]: defaultValue,
    }));
  };

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: listType
        ? `/admin/customers/${listType.toLowerCase()}`
        : "/admin/customers",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    (refetch) ? setrefetch(false) : setrefetch(true)
    setFilterLoading(false)
    let handledData = {
      ...(dateRange[0] && {
        createdFrom,
      }),
      ...(dateRange[1] && {
        createdTo,
      }),
      active: data.active,
      warehousing: data.warehousing,
      idle: data.idle,
      zoneId: data.zoneId,
      subzoneId: data.subzoneId,
      paymentTypes: data.paymentTypes,
      branchId: data.branchId,
      priceListId: data.priceListId,
      name: data.name,
      phone: data.phone,
      email: data.email,
      withDue: data.withDue,
      code: data.code,
      paymentCycleCode: data.paymentCycleCode,
      paymentCycleDay: data.paymentCycleDay,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];
    data["warehousing"] === "" && delete handledData["warehousing"];
    data["idle"] === "" && delete handledData["idle"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const resetDate = () => {
    setDateRange([null, null]);
  };

  const codeValidation = Boolean(watch("code"));
  const paymentDue =
    user.hasPermission(GetPaymentPermissionSlug("dlvby", "create")) ||
    user.hasPermission(GetPaymentPermissionSlug("custm", "create"));

  const queryBody = LIST_CUSTOMERS(paymentDue).query;
  const { data, loading } = useQuery(
    gql`
      ${queryBody}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          active: search.active,
          warehousing: search.warehousing,
          idle: search.idle,
          ...(listType && { typeCode: listType }),
          ...(search.createdFrom && { createdFrom: search.createdFrom }),
          ...(search.createdTo && { createdTo: search.createdTo }),
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.phone && { phone: search.phone }),
          ...(search.email && { email: search.email }),
          ...(search.zoneId && { zoneId: search.zoneId }),
          ...(search.subzoneId && { subzoneId: search.subzoneId }),
          ...(search.paymentCycleCode && {
            paymentCycleCode: search.paymentCycleCode,
          }),
          ...(search.paymentCycleDay && {
            paymentCycleDay: search.paymentCycleDay,
          }),
          ...(search.paymentTypes && { paymentTypes: search.paymentTypes }),
          ...((search.priceListId || parseInt(search.priceListId) === 0) && {
            priceListId: parseInt(search.priceListId),
          }),
          ...(search.withDue && { withDue: search.withDue }),
          ...(search.branchId && {
            branchId: { value: search.branchId, includeNull: false },
          }),
        },
      },
      onCompleted: (data) => { },
    }
  );

  const customerList =
    data?.listCustomers?.data !== null ? data?.listCustomers?.data : null;

  const exportFilters = {
    active: search.active,
    warehousing: search.warehousing,
    ...(search.createdFrom && { createdFrom: search.createdFrom }),
    ...(search.createdTo && { createdTo: search.createdTo }),
    ...(search.code && { code: search.code }),
    ...(search.name && { name: search.name }),
    ...(search.phone && { phone: search.phone }),
    ...(search.email && { email: search.email }),
    ...(search.zoneId && { zoneId: search.zoneId }),
    // ...(search.PaymentCycleCode && { PaymentCycleCode: search.PaymentCycleCode }),
    ...(listType && { typeCode: listType }),
    // ...(search.paymentCycleDay && { paymentCycleDay: search.paymentCycleDay }),
    ...(search.subzoneId && { subzoneId: search.subzoneId }),
    ...(search.branchId && {
      branchId: { value: search.branchId, includeNull: false },
    }),
    ...(search.withDue && { withDue: search.withDue }),
  };

  const isWarehousing = Globals.settings.warehousing;

  function EnhancedTableHead(props) {
    const { listType } = props;
    const { t } = useTranslation();

    const headCells = [
      { id: "1", numeric: true, disablePadding: false, label: t("name") },
      { id: "2", numeric: true, disablePadding: false, label: t("code") },
      {
        id: "3",
        numeric: true,
        disablePadding: false,
        label: t("createdAt"),
      },
      { id: "4", numeric: true, disablePadding: false, label: t("source") },
      { id: "5", numeric: true, disablePadding: false, label: t("branch") },
      { id: "6", numeric: true, disablePadding: false, label: t("phone") },
      { id: "8", numeric: true, disablePadding: false, label: t("email") },
      { id: "9", numeric: true, disablePadding: false, label: t("paymentCycle") },
      { id: "10", numeric: true, disablePadding: false, label: t("active") },
    ];
    if (paymentPermission && listType !== "CONCIERGE") {
      headCells.splice(2, 0,
        { id: "11", numeric: true, disablePadding: false, label: t("customerDueAmount") },
      );
    }
    if (listType === "CONCIERGE") {
      headCells.splice(headCells.length - 2, 1);
      headCells.splice(3, 1);
    }
    if ((listType === "MERCHANT" || !listType)) {
      headCells.splice(0, 0,
        { id: "12", numeric: true, disablePadding: false, label: t("storeName") },
      );
    }
    if ((listType === "MERCHANT")) {
      headCells.splice(4, 0,
        { id: "13", numeric: true, disablePadding: false, label: t("idleStatus") },
      );
    }
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <FixedTableCell
                className={classes.headerTable}
                key={headCell.id}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </FixedTableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }


  const [openExport, setOpenExport] = useState(false);

  const openExportDialog = () => {
    setOpenExport(true)
  };
  const addURL = () => {
    pushUrl(props, `/admin/customers/create/${listType.toLowerCase()}`);
  }
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: GetPermissionSlug(
        "shipping",
        "customer",
        listType,
        "create"
      ),
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: Boolean(customerList?.length === 0)
    },
  ]

  return (
    <Root>
      <ExportCustomerList
        filters={exportFilters}
        openExport={openExport}
        setOpenExport={setOpenExport}
      // disabled={Boolean(customerList?.length === 0)}
      // filters={exportFilters}
      />
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        type={listType}
        empty={customerList?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm} >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("registrationDate")}
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  disabled={codeValidation}
                  resetDate={resetDate}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>

              {isWarehousing && listType === "MERCHANT" && (
                <Grid item sm={12} alignItems="flex-start">
                  <MUIselect
                    name={"warehousing"}
                    label={t("warehousing")}
                    control={control}
                    errors={errors}
                    data={[
                      { key: t("no"), value: false },
                      { key: t("yes"), value: true },
                    ]}
                  />
                </Grid>
              )}

              <Can permission={paymentPermission && listType !== "CONCIERGE"} showException>
                <Grid item sm={12} alignItems="flex-start">
                  <MUIselect
                    name={"withDue"}
                    label={t("customerDueAmount")}
                    control={control}
                    errors={errors}
                    data={[{ key: t("withDue"), value: "PAY" }]}
                  />
                </Grid>


                <Grid item sm={12} alignItems="flex-start">
                  <LookupDropdown
                    control={control}
                    errors={errors}
                    name={"paymentCycleCode"}
                    label={t("paymentCycle")}
                    skip={loading && filterLoading}
                    variables={{
                      input: { code: "PAYMENT_SCHEDULE_TYPE" },
                    }}
                    onCompleted={(data) =>
                      lookupsComplete(
                        data,
                        urlQuery["paymentCycleCode"],
                        "paymentCycleCode"
                      )
                    }
                    onChanges={(e) => {
                      setValue("paymentCycleDay", "");
                      setAutocompleteValues((prev) => ({
                        ...prev,
                        paymentCycleDay: [],
                      }));
                    }}
                  />
                </Grid></Can>

              {watch("paymentCycleCode") && (
                <Grid item sm={12} alignItems="flex-start">
                  <AutocompleteWithJson
                    name="paymentCycleDay"
                    label={t("paymentDay")}
                    control={control}
                    errors={errors}
                    hideCode={true}
                    valueKey={"id"}
                    data={GetPaymentCycleDays(t, watch("paymentCycleCode"))}
                    loading={loading}
                    defaultValue={autocompleteValues.paymentCycleDay}
                    onCompleted={(data) =>
                      handelAutocompleteWithJsonDefaultValue(
                        data,
                        urlQuery["paymentCycleDay"],
                        "paymentCycleDay"
                      )
                    }
                  />
                </Grid>
              )}
              <Can permission={listType !== "CONCIERGE"} showException>
                <Grid item sm={12} alignItems="flex-start">
                  <MultipleAutocomplete
                    name={"paymentTypes"}
                    label={t("paymentType")}
                    control={control}
                    errors={errors}
                    skip={loading && filterLoading}
                    // variables={}
                    query={LIST_PAYMENT_TYPES_DROPDOWN.query}
                    parseData={(data) => parseData(data)}
                    multiple
                    valueKey="code"
                    defaultValue={autocompleteValues.paymentTypes}
                  />
                </Grid></Can>

              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"phone"}
                  label={t("phone")}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"email"}
                  label={t("email")}
                />
              </Grid>
              <Can permission={listType !== "CONCIERGE"} showException>
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"zoneId"}
                    skip={loading && filterLoading}
                    label={t("zone")}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["zoneId"],
                        "zone"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    variables={{ input: { parentId: null } }}
                    query={LIST_ZONES_DROPDOWN.query}
                    defaultValue={autocompleteValues.zone}
                    onChangeValue={(e) => {
                      setValue("subzoneId", "");
                    }}
                  />
                </Grid>

                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"subzoneId"}
                    label={t("subzone")}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["subzoneId"],
                        "subzone"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_ZONES_DROPDOWN.query}
                    variables={{ input: { parentId: watch("zoneId") } }}
                    skip={!watch("zoneId")}
                    disabled={!watch("zoneId")}
                    defaultValue={autocompleteValues.subzone}
                  />
                </Grid>
              </Can>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  name={"branchId"}
                  label={t("branch")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
              <Can
                permission={GetPermissionSlug(
                  "shipping",
                  "price_list",
                  listType,
                  "list"
                )}
                showException
              >
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    skip={loading && filterLoading}
                    name={"priceListId"}
                    label={t("listPriceList")}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["priceListId"],
                        "priceList"
                      )
                    }
                    variables={{ input: { customerTypeCode: listType } }}
                    parseData={(data) => parseData(data)}
                    query={LIST_PRICE_LIST_DROPDOWN.query}
                    defaultValue={autocompleteValues.priceList}
                  />
                </Grid>
              </Can>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"idle"}
                  label={t("idleStatus")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notIdle"), value: false },
                    { key: t("idle"), value: true },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          <EnhancedTableHead
            listType={listType}
          />
        }
        tableBody={
          <TableBody>
            {customerList &&
              customerList?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    className={classes.tableBodyRow}
                  >
                    <CellLink pathname={`/admin/customers/${row?.id}`}>
                      {row?.name}
                    </CellLink>
                    {(listType === "MERCHANT" || !listType) && (
                      <FixedTableCell>
                        {row.representative}
                      </FixedTableCell>
                    )}
                    <FixedTableCell>{row.code}</FixedTableCell>
                    <Can permission={paymentPermission && listType !== "CONCIERGE"} showException>
                      <FixedTableCell>
                        {formatMoney(row.paymentDue)}
                      </FixedTableCell>
                    </Can>
                    {(listType === "MERCHANT" || !listType) && (
                      <FixedTableCell>
                        <StyledFixedTableCell
                          colorcode={row.idle ? colors["red"]["500"] : colors["green"]["500"]}
                          size="small"
                          label={row.idle ? t("idle") : t("notIdle")}
                        />
                      </FixedTableCell>
                    )}
                    <FixedTableCell>
                      {moment(row.createdAt).format("ll")}
                    </FixedTableCell>
                    <Can permission={listType !== "CONCIERGE"} showException>
                      <ShipmentRegion
                        pathname={`/admin/zones/${row?.zone?.id}`}
                        zone={row?.zone?.name}
                        subzone={row?.subzone?.name}
                      />
                    </Can>
                    {row?.branch ? (
                      <CellLink
                        pathname={`/admin/branches/${row?.branch?.id}`}
                      >
                        {row?.branch?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}
                    <FixedTableCell dir="ltr">

                      <Box>{row?.mobile}</Box>
                      <Box >{row?.phone}</Box>

                    </FixedTableCell>
                    <FixedTableCell>{row?.email}</FixedTableCell>
                    <Can permission={paymentPermission && listType !== "CONCIERGE"} showException>
                      <FixedTableCell>
                        {row?.paymentCycle?.name}
                      </FixedTableCell>
                    </Can>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listCustomers?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listCustomers ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default CustomerList;
