import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

import * as gqlb from "gql-query-builder";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
// import config from "../../config.json";
// import { Globals } from "../HOC/Classes/Globals";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
export const EXPOERT_PAYMENTS = gqlb.mutation({
  operation: "exportPayments",
  fields: [],
  variables: {
    input: {
      type: "ExportPaymentsInput",
      required: true,
    },
  },
});

export default function ExportPaymentList(props) {
  const { filters, openExport, setOpenExport } = props;

  const [paymentFieldsState, setPaymentFieldsState] = useState([]);
  const { t } = useTranslation();
  const type = filters?.typeCode;
  const paymentFields = [
    { name: "CODE", label: t("recordCode") },
    { name: "DATE", label: t("paymentDate") },
    {
      ...(type === "DLVBY" && {
        name: "DELIVERY_AGENT",
        label: t("shippingAgent"),
      }, {
        name: "SHIPMENT_COUNT",
        label: t("shipmentsNumber"),
      }),
      ...(type === "CUSTM" && { name: "CUSTOMER", label: t("customer") }),
    },
    { name: "SAFE", label: t("safe") },
    { name: "PAYMENT_AMOUNT", label: t("value") },
  ];
  if (type === "CUSTM") {
    const referenceBranch = {
      name: "DUE_FEES", label: t("returningDueFees")
    };
    paymentFields.push(referenceBranch);
    const representative = {
      name: "FINANCE_REPRESENTATIVE ", label: t("financialRepresentative")
    };
    paymentFields.push(representative);
  }

  useEffect(() => {
    if (!paymentFields[0].id)
      paymentFields.forEach((ele, index) => {
        ele["id"] = index + 1;
        ele["checked"] = true;
      });

    const localStorageExportPayments = localStorage.getItem("export-payments");
    const parsedExportPayment = localStorageExportPayments
      ? JSON.parse(localStorageExportPayments)
      : null;

    const notCheckedFields = paymentFields.filter((i) => {
      if (
        !parsedExportPayment?.includes(i.name) &&
        parsedExportPayment !== null
      ) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = paymentFields.filter((i) => {
      if (parsedExportPayment?.includes(i.name)) {
        i.checked = true;
        return true;
      }
      return false;
    });

    const sortedFields = checkedFields.sort((a, b) => {
      return parsedExportPayment?.indexOf(a.name) >
        parsedExportPayment?.indexOf(b.name)
        ? 1
        : -1;
    });

    setPaymentFieldsState(
      localStorageExportPayments
        ? [...sortedFields, ...notCheckedFields]
        : paymentFields
    );
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  // const [dialogState, setDialogState] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const fieldCount = paymentFieldsState.length;
  const fieldSelected = paymentFieldsState.filter((i) => i.checked).length;
  const onSelectAllClick = (e) => {
    const fileds = [...paymentFieldsState];
    fileds.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setPaymentFieldsState(fileds);
  };

  const onCloseDialog = () => {
    setOpenExport(false);
  };

  // const onOpenDialog = () => {
  //   setDialogState(true);
  // };

  // let getBackendUri = (imgPath) => {
  //   const domain = config.backend.domain
  //     ? config.backend.domain
  //     : window.location.hostname;

  //   return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  // };
  const [exportPayments, { loading }] = useMutation(
    gql`
      ${EXPOERT_PAYMENTS.query}
    `,
    {
      onCompleted: (data) => {
        onCloseDialog();
        if (data.exportPayments) {
          enqueueSnackbar(t("successExport"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        } else {
          enqueueSnackbar(t("errorExport"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(paymentFieldsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const isNotChecked = items[result.destination.index - 1]?.checked;
    if (isNotChecked === false) return;
    items.splice(result.destination.index, 0, reorderedItem);

    setPaymentFieldsState(items);
  }

  const handelExportPayment = () => {
    const updatedFields = paymentFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.name);
      }
      return previous;
    }, []);
    localStorage.setItem("export-payments", JSON.stringify(updatedFields));
    const fields = paymentFieldsState
      .filter((i) => i.checked)
      .map((i) => ({ label: i.label, name: i.name }));
    exportPayments({
      variables: {
        input: {
          filters,
          fields,
        },
      },
    });
  };

  return (
    <>
      {/* <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => onOpenDialog()}
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip> */}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={openExport}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            {t("exportExcel")}
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loading || fieldSelected === 0}
              onClick={handelExportPayment}
            >
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
        content={
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component="ul"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {/* <ListItem dense button>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      indeterminate={
                        fieldSelected > 0 && fieldSelected < fieldCount
                      }
                      checked={fieldCount > 0 && fieldSelected === fieldCount}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  
                  </ListItemIcon>
                  <ListItemText primary={t("selectAll")} />
                </ListItem> */}

                  {paymentFieldsState.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.id}`;
                    return (
                      <Draggable
                        isDragDisabled={!value.checked}
                        key={value.id}
                        draggableId={value.name}
                        index={index}
                      >
                        {(provided) => (
                          <CustomeItemList
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            itemDetails={value}
                            labelId={labelId}
                            state={paymentFieldsState}
                            setState={setPaymentFieldsState}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.label}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </>
  );
}

const CustomeItemList = (props) => {
  const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
    props;
  const { label, checked } = itemDetails;
  // const [checkboxState,setCheckboxState] = React.state(checked)
  const handleToggle = (e) => {
    const checked = e.target.checked;
    const update = [...state];
    const checkedItemLength = update.filter((i) => i.checked).length;
    const index = update.findIndex((i) => i.name === itemDetails.name);
    const [sortItem] = update.splice(index, 1);

    sortItem["checked"] = checked;
    update.splice(checkedItemLength, 0, sortItem);

    setState(update);
  };
  return (
    <ListItem {...restProps} ref={innerRef} dense button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          onChange={handleToggle}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};
