import React, { useEffect, useState } from "react";
import CustomDialog from "../../HOC/CustomComponents/CustomDialog";
import Stack from "@mui/material/Stack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  DELETE_FILTER_GROUP,
  FILTER_GROUP_ID,
  SAVE_SHIPMENT_FILTER_GROUP,
} from "../IdleGraphQl";
import MuiSwitch from "../../HOC/MUI/MUIswitch";
import MUIselect from "../../HOC/MUI/MUIselect";
import { MultipleAutocomplete } from "../../HOC/MUI/MultipleAutocomplete";
import { Globals } from "../../HOC/Classes/Globals";
import { setValidationError } from "../../HOC/CustomFunctions/setValidationError";
import CustomButton from "../../HOC/MUI/CustomButton";
import { useTheme } from "@emotion/react";
import { parseBool } from "../../HOC/CustomFunctions/urlParameters";
import { isBoolean } from "lodash";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { windowUrl } from "../../HOC/CustomFunctions/pushUrl";

function IdleDialog(props) {
  const { setOpenDialog, openDialog, ID, setID, refetch, keyFilter } = props;
  const history = useHistory();

  const [autocompleteValues, setAutocompleteValues] = useState({
    statusCode: [],
    recipientZone: [],
    recipientSubzone: [],
    deliveryAgent: null,
  });

  const [saveShipmentFilterGroup, { loading: saveShipmentFilterGroupLoading }] =
    useMutation(
      gql`
        ${SAVE_SHIPMENT_FILTER_GROUP.query}
      `
    );

  const [deleteFilterGroup, { loading: deleteFilterGroupLoading }] =
    useMutation(
      gql`
        ${DELETE_FILTER_GROUP.query}
      `
    );

  const canCreateGlobal = Globals.user.hasPermission(
    "core.filter_group.create_public"
  );
  const canUpdateGlobal = Globals.user.hasPermission(
    "core.filter_group.update_public"
  );
  const canDeleteGlobal = Globals.user.hasPermission(
    "core.filter_group.delete_public"
  );

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [rangeState, setRangeState] = useState({
    lastTransactionDate: "SINCE",
    deliveredOrReturnedDate: "SINCE",
  });

  const { handleSubmit, formState, control, setError, setValue, watch, reset } =
    useForm();
  const { t } = useTranslation();
  const { errors } = formState;
  const theme = useTheme();

  const closeDialog = () => {
    reset();
    setID();
    setOpenDialog(false);
  };
  const handleChangeTransactionDate = (event, newRangeState) => {
    setRangeState((prev) => ({
      ...prev,
      lastTransactionDate: newRangeState,
    }));
  };
  const handleChangeDeliveredDate = (event, newRangeState) => {
    setRangeState((prev) => ({
      ...prev,
      deliveredOrReturnedDate: newRangeState,
    }));
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const SubmitDeleteRecord = () => {
    deleteFilterGroup({
      variables: {
        id: parseInt(ID),
      },
    })
      .then((data) => {
        setDeleteDialog(false);
        closeDialog();
        keyFilter(true);
        refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const parseData = (data) => {
    const filteredData = data?.filter(
      (item) => item?.code !== "DTR" && item?.code !== "RTS"
    );
    return filteredData;
  };

  const onSubmit = (data) => {
    saveShipmentFilterGroup({
      variables: {
        input: {
          filterGroupInput: {
            ...(ID && { id: parseInt(ID) }),
            name: data.filterGroupInput.name,
            global: data.filterGroupInput.global,
          },
          listFilterInput: {
            statusCode: data.statusCode,
            ...(data.deliveredOrReturnedDate !== "" && {
              deliveredOrReturnedDate: {
                ...(rangeState.deliveredOrReturnedDate === "SINCE" && {
                  fromDays:
                    data.deliveredOrReturnedDateDay === "hour"
                      ? parseFloat(data.deliveredOrReturnedDate) / 24
                      : parseFloat(data.deliveredOrReturnedDate),
                }),
                ...(rangeState.deliveredOrReturnedDate === "UNTIL" && {
                  untilDays:
                    data.deliveredOrReturnedDateDay === "hour"
                      ? parseFloat(data.deliveredOrReturnedDate) / 24
                      : parseFloat(data.deliveredOrReturnedDate),
                }),
              },
            }),
            ...(data.lastTransactionDate !== "" && {
              lastTransactionDate: {
                ...(rangeState.lastTransactionDate === "SINCE" && {
                  fromDays:
                    data.lastTransactionDateDay === "hour"
                      ? parseFloat(data.lastTransactionDate) / 24
                      : parseFloat(data.lastTransactionDate),
                }),
                ...(rangeState.lastTransactionDate === "UNTIL" && {
                  untilDays:
                    data.lastTransactionDateDay === "hour"
                      ? parseFloat(data.lastTransactionDate) / 24
                      : parseFloat(data.lastTransactionDate),
                }),
              },
            }),

            ...(isBoolean(parseBool(data?.collected)) && {
              collected: data.collected,
            }),
            ...(data.lastDeliveryAgentId && {
              lastDeliveryAgentId: data.lastDeliveryAgentId,
            }),
            ...(data.recipientZoneId && {
              recipientZoneId: data.recipientZoneId,
            }),
            ...(data.recipientSubzoneId && {
              recipientSubzoneId: data.recipientSubzoneId,
            }),
          },
        },
      },
    })
      .then((data) => {
        ID && keyFilter(false);
        closeDialog();
        refetch();
        const url = history.createHref({
          pathname: "/admin/idle-shipments",
          search: "?filterId=" + data.data.saveShipmentFilterGroup.id,
        });
        windowUrl(url);
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError, "filterGroupInput.");
        console.log(graphQLErrors);
      });
  };

  const { data } = useQuery(
    gql`
      ${FILTER_GROUP_ID.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !ID,
      variables: {
        id: parseInt(ID),
      },
      onCompleted: (data) => {
        const filterGroup = data.filterGroup;
        const filterGroupFilters = JSON.parse(filterGroup?.filters);

        setAutocompleteValues({
          statusCode: filterGroupFilters.statusCode,
          // deliveryAgent: filterGroupFilters.lastDeliveryAgentId,
          recipientZone: filterGroupFilters.recipientZoneId,
          recipientSubzone: filterGroupFilters.recipientSubzoneId,
        });
        setValue("filterGroupInput.name", filterGroup.name);
        setValue("filterGroupInput.global", !!!filterGroup.user);
        // filterGroupFilters.lastTransactionDate && setValue("lastTransactionDate", filterGroupFilters.lastTransactionDate.fromDays)
        // filterGroupFilters.deliveredOrReturnedDate && setValue("deliveredOrReturnedDate", filterGroupFilters.deliveredOrReturnedDate.fromDays * 24)
        setValue("collected", filterGroupFilters.collected);

        if (filterGroupFilters.lastTransactionDate) {
          if (filterGroupFilters.lastTransactionDate.fromDays !== undefined) {
            if (
              Number.isInteger(filterGroupFilters.lastTransactionDate.fromDays)
            ) {
              setValue(
                "lastTransactionDate",
                filterGroupFilters.lastTransactionDate.fromDays
              );
              setRangeState((prev) => ({
                ...prev,
                lastTransactionDate: "SINCE",
              }));
              setValue("lastTransactionDateDay", "day");
            } else {
              setValue(
                "lastTransactionDate",
                filterGroupFilters.lastTransactionDate.fromDays * 24
              );
              setRangeState((prev) => ({
                ...prev,
                lastTransactionDate: "SINCE",
              }));
              setValue("lastTransactionDateDay", "hour");
            }
          }

          if (filterGroupFilters.lastTransactionDate.untilDays !== undefined) {
            if (
              Number.isInteger(filterGroupFilters.lastTransactionDate.untilDays)
            ) {
              setValue(
                "lastTransactionDate",
                filterGroupFilters.lastTransactionDate.untilDays
              );
              setRangeState((prev) => ({
                ...prev,
                lastTransactionDate: "UNTIL",
              }));
              setValue("lastTransactionDateDay", "day");
            } else {
              setValue(
                "lastTransactionDate",
                filterGroupFilters.lastTransactionDate.untilDays * 24
              );
              setRangeState((prev) => ({
                ...prev,
                lastTransactionDate: "UNTIL",
              }));
              setValue("lastTransactionDateDay", "hour");
            }
          }
        }
        if (filterGroupFilters.deliveredOrReturnedDate) {
          // Handle the "SINCE" state
          if (
            filterGroupFilters.deliveredOrReturnedDate.fromDays !== undefined
          ) {
            if (
              Number.isInteger(
                filterGroupFilters.deliveredOrReturnedDate.fromDays
              )
            ) {
              setValue(
                "deliveredOrReturnedDate",
                filterGroupFilters.deliveredOrReturnedDate.fromDays
              );
              setRangeState((prev) => ({
                ...prev,
                deliveredOrReturnedDate: "SINCE",
              }));
              setValue("deliveredOrReturnedDateDay", "day");
            } else {
              setValue(
                "deliveredOrReturnedDate",
                filterGroupFilters.deliveredOrReturnedDate.fromDays * 24
              );
              setRangeState((prev) => ({
                ...prev,
                deliveredOrReturnedDate: "SINCE",
              }));
              setValue("deliveredOrReturnedDateDay", "hour");
            }
          }

          // Handle the "UNTIL" state
          if (
            filterGroupFilters.deliveredOrReturnedDate.untilDays !== undefined
          ) {
            if (
              Number.isInteger(
                filterGroupFilters.deliveredOrReturnedDate.untilDays
              )
            ) {
              setValue(
                "deliveredOrReturnedDate",
                filterGroupFilters.deliveredOrReturnedDate.untilDays
              );
              setRangeState((prev) => ({
                ...prev,
                deliveredOrReturnedDate: "UNTIL",
              }));
              setValue("deliveredOrReturnedDateDay", "day");
            } else {
              setValue(
                "deliveredOrReturnedDate",
                filterGroupFilters.deliveredOrReturnedDate.untilDays * 24
              );
              setRangeState((prev) => ({
                ...prev,
                deliveredOrReturnedDate: "UNTIL",
              }));
              setValue("deliveredOrReturnedDateDay", "hour");
            }
          }
        }
      },
    }
  );
  // const dialogData = JSON.parse(data.filterGroup.filters);
  useEffect(() => {
    const filterGroupFilters = data && JSON.parse(data?.filterGroup?.filters);
    if (filterGroupFilters) {
      setAutocompleteValues({
        statusCode: filterGroupFilters?.statusCode,
        deliveryAgent: filterGroupFilters?.lastDeliveryAgentId,
        recipientZone: filterGroupFilters?.recipientZoneId,
        recipientSubzone: filterGroupFilters?.recipientSubzoneId,
      });
    }
  }, [data]);
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const createdBy = data?.filterGroup.user;
  const inputSubzone = watch("recipientZoneId") ?? watch("recipientZoneId");
  const formCondition = ID && !(!!createdBy ? true : canUpdateGlobal);
  const formConditionDelete = ID && !(!!createdBy ? true : canDeleteGlobal);

  return (
    <CustomDialog
      title={ID ? t("update") : t("addNew")}
      fullWidth
      //   maxWidth="xs"
      onClose={closeDialog}
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"filterGroupInput.name"}
                label={t("name")}
                rules={{ required: t("fieldIsRequired") }}
                disabled={formCondition}
              />
            </Grid>
            <Grid item xs={12}>
              <MultipleAutocomplete
                name={"statusCode"}
                label={t("shipmentStatus")}
                control={control}
                errors={errors}
                variables={{ input: { code: "SHP_REQUEST_STATUS" } }}
                query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                parseData={(data) => parseData(data)}
                multiple
                valueKey="code"
                defaultValue={autocompleteValues.statusCode}
                disabled={formCondition}
              />
            </Grid>
            <Grid item xs={12}>
              <MUIselect
                name={"collected"}
                label={t("collection")}
                control={control}
                errors={errors}
                data={[
                  { key: t("notCollected"), value: false },
                  { key: t("collected"), value: true },
                ]}
                disabled={formCondition}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomAutocomplete
                name={"lastDeliveryAgentId"}
                label={t("shippingAgent")}
                control={control}
                errors={errors}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    autocompleteValues[`${"deliveryAgent"}`],
                    "deliveryAgent"
                  )
                }
                query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                variables={{
                  input: {
                    active: true,
                  },
                }}
                defaultValue={autocompleteValues.deliveryAgent}
              />
            </Grid>
            <Grid item xs={12}>
              <MultipleAutocomplete
                name={"recipientZoneId"}
                label={t("toZone")}
                control={control}
                errors={errors}
                query={LIST_ZONES_DROPDOWN.query}
                variables={{ input: { parentId: null } }}
                parseData={(data) => parseData(data)}
                onChangeValue={(e) => {
                  setValue("recipientSubzoneId", "");
                }}
                multiple
                valueKey="id"
                defaultValue={autocompleteValues.recipientZone}
              />
            </Grid>
            <Grid item xs={12}>
              <MultipleAutocomplete
                name={"recipientSubzoneId"}
                label={t("toSubzone")}
                control={control}
                errors={errors}
                variables={{
                  input: { parentId: inputSubzone && inputSubzone[0] },
                }}
                query={LIST_ZONES_DROPDOWN.query}
                parseData={(data) => parseData(data)}
                skip={!watch("recipientZoneId")}
                disabled={
                  !watch("recipientZoneId") ||
                  watch("recipientZoneId").length > 1 ||
                  watch("recipientZoneId").length === 0
                }
                multiple
                valueKey="id"
                defaultValue={autocompleteValues.recipientSubzone}
              />
            </Grid>
            {/* <Grid container spacing={1}> */}
            <Grid item xs={6}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"lastTransactionDate"}
                label={t("lastTransactionDateFrom")}
                type={"number"}
                sx={{
                  [`& input[type=number]::-webkit-inner-spin-button`]: {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  [`& input[type=number]::-webkit-outer-spin-button`]: {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                }}
                disabled={formCondition}
              />
            </Grid>
            <Grid item xs={3}>
              <ToggleButtonGroup
                color="primary"
                value={rangeState.lastTransactionDate}
                exclusive
                sx={{ width: "100%" }}
                onChange={handleChangeTransactionDate}
                aria-label="Platform"
              >
                <ToggleButton sx={{ width: "50%" }} value="SINCE">
                  {t("since")}
                </ToggleButton>
                <ToggleButton sx={{ width: "50%" }} value="UNTIL">
                  {t("until")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={3}>
              <MUIselect
                name={"lastTransactionDateDay"}
                label={t("fromTime")}
                control={control}
                errors={errors}
                data={[
                  { key: t("day"), value: "day" },
                  { key: t("hour"), value: "hour" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"deliveredOrReturnedDate"}
                label={t("deliveredCancelledFrom")}
                type={"number"}
                sx={{
                  [`& input[type=number]::-webkit-inner-spin-button`]: {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  [`& input[type=number]::-webkit-outer-spin-button`]: {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                }}
                disabled={formCondition}
              />
            </Grid>
            <Grid item xs={3} justifyContent={"flex-end"}>
              <ToggleButtonGroup
                color="primary"
                value={rangeState.deliveredOrReturnedDate}
                exclusive
                sx={{ width: "100%" }}
                onChange={handleChangeDeliveredDate}
                aria-label="Platform"
              >
                <ToggleButton sx={{ width: "50%" }} value="SINCE">
                  {t("since")}
                </ToggleButton>
                <ToggleButton sx={{ width: "50%" }} value="UNTIL">
                  {t("until")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={3}>
              <MUIselect
                name={"deliveredOrReturnedDateDay"}
                label={t("fromTime")}
                control={control}
                errors={errors}
                data={[
                  { key: t("day"), value: "day" },
                  { key: t("hour"), value: "hour" },
                ]}
              />
            </Grid>

            {/* </Grid> */}
            {/* <Grid
              container
              // sx={12}
              // justifyContent={"space-between"}
              // direction={"row"}
              spacing={1}
            > */}
            <CustomDialog
              title={t("confirmation")}
              fullWidth
              maxWidth="xs"
              onClose={closeDeleteDialog}
              content={
                <Stack my={1} spacing={2}>
                  <Stack>
                    <Typography paragraph>{t("confirmationStatus")}</Typography>
                  </Stack>
                  <Stack justifyContent={"flex-end"} direction={"row"}>
                    <CustomButton
                      customcolor={theme.palette.error.main}
                      disabled={deleteFilterGroupLoading || formConditionDelete}
                      onClick={SubmitDeleteRecord}
                      variant="contained"
                      size="medium"
                      loading={deleteFilterGroupLoading}
                    >
                      {!deleteFilterGroupLoading && t("delete")}
                    </CustomButton>
                  </Stack>
                </Stack>
              }
              open={deleteDialog}
            />
            <Grid item xs={6}>
              {canCreateGlobal && (
                <MuiSwitch
                  edge="end"
                  name="filterGroupInput.global"
                  label={t("forAllUser")}
                  control={control}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Stack justifyContent={"flex-end"} direction={"row"} spacing={1}>
                <CustomButton
                  customcolor={theme.palette.success.main}
                  type="submit"
                  disabled={
                    !(
                      watch("statusCode") ||
                      watch("collected") ||
                      watch("lastTransactionDate") ||
                      watch("lastTransactionDate") === 0 ||
                      watch("deliveredOrReturnedDate") ||
                      watch("deliveredOrReturnedDate") === 0
                    ) ||
                    formCondition ||
                    saveShipmentFilterGroupLoading
                  }
                  variant="contained"
                  size="medium"
                  loading={saveShipmentFilterGroupLoading}
                >
                  {!saveShipmentFilterGroupLoading && t("save")}
                </CustomButton>
                {ID && (
                  <CustomButton
                    customcolor={theme.palette.error.main}
                    disabled={deleteFilterGroupLoading || formConditionDelete}
                    onClick={() => setDeleteDialog(true)}
                    variant="contained"
                    size="medium"
                    loading={deleteFilterGroupLoading}
                  >
                    {!deleteFilterGroupLoading && t("delete")}
                  </CustomButton>
                )}
              </Stack>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </form>
      }
      open={openDialog}
    />
  );
}

export default IdleDialog;
