import * as gqlb from "gql-query-builder";

export const LIST_PAYMENT_QUERY = (ALLOWED_CONCIERGE, ALLOWED_SHIPMENT, ALLOWED_PICKUP) => gqlb.query([
  {
    operation: "listPayments",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "total",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          "code",
          "id",
          "date",
          "approved",
          "glApproved",
          "notes",
          {
            representative: ["id", "name", "active"],
          },
          {
            operation: "transactionType",
            fields: ["id", "name"],
            variables: {},
          },
          {
            operation: "safe",
            fields: ["id", "name"],
            variables: {},
          },
          {
            operation: "deliveryAgent",
            fields: ["id", "name"],
            variables: {},
          },
          {
            customer: ["id", "name"],
          },
          {
            updatedBy: ["username", "id"],
          },
          {
            createdBy: ["username", "id"],
          },
          {
            operation: `sumEntries`,
            fields: [
              "deliveredAmount",
              "piecesCount",
              "collectedFees",
              "dueFees",
              "weight",
              "paymentAmount",
            ],
            variables: {},
          },
          {
            operation: `entriesShipment:entries(typeCode: SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
            fields: [
              {
                paginatorInfo: ["total"]
              }
            ],
            variables: {},
          },
          {
            operation: `entriesConcierge:entries(typeCode: CONCIERGE_REQUEST) @include(if: ${ALLOWED_CONCIERGE})`,
            fields: [
              {
                paginatorInfo: ["total"]
              }
            ],
            variables: {},
          },
          {
            operation: `entriesPickup :entries(typeCode: PICKUP) @include(if: ${ALLOWED_PICKUP})`,
            fields: [
              {
                paginatorInfo: ["total"]
              }
            ],
            variables: {},
          },
        ],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListPaymentFilterInput",
        required: true,
      },
      first: {
        type: "Int",
        required: true,
        value: 15,
      },
      page: {
        type: "Int",
        value: 1,
      },
    },
  },
  {
    operation: "sumPayments",
    fields: ["paymentAmount", "dueFees", "shipmentCount", "pickupCount", "conciergeRequestCount"],
    variables: {
      input: {
        type: "ListPaymentFilterInput",
        required: true,
      },
    },
  },
]);



export const paymentFields = [
  "id",
  "date",
  "code",
  "approved",
  "glApproved",
  "notes",
  // "collectedFees",
  // "deliveredAmount",
  // "dueFees",
  // "weight",
  // "piecesCount",
  // "paymentAmount",
  {
    type: ["code"],
  },
  {
    representative: ["id", "name"],
  },
  {
    images: ["path"],
  },
  {
    createdBy: ["username", "id"],
  },
  {
    updatedBy: ["username", "id"],
  },
  {
    customer: [
      "id",
      "code",
      "name",
      "mobile",
      "phone",
      "address",
      // "representative",
      "vodafoneCash",
      "bankAccount",
      {
        paymentMethod: ["name", "id"],
      },
      {
        bank: ["name", "id"],
      },
      { zone: ["name"] },
      { subzone: ["name"] },
    ],
  },
  {
    operation: "branch",
    fields: ["id", "name"],
    variables: {},
  },
  {
    transactionType: ["id", "code", "name", { type: ["name", "code"] }],
  },
  {
    operation: "deliveryAgent",
    fields: ["id", "name", "code", "pickupCommission"],
    variables: {},
  },
  {
    operation: "safe",
    fields: ["id", "name"],
    variables: {},
  },
  {
    operation: `sumEntriesShipment:sumEntries(typeCode: SHIPMENT)`,
    fields: [
      "deliveredAmount",
      "piecesCount",
      "collectedFees",
      "dueFees",
      "weight",
      "paymentAmount",
    ],
    variables: {},
  },
  {
    operation: `sumEntriesConcierge:sumEntries(typeCode: CONCIERGE_REQUEST)`,
    fields: [
      "deliveredAmount",
      "piecesCount",
      "collectedFees",
      "dueFees",
      "weight",
      "paymentAmount",
    ],
    variables: {},
  },
  {
    operation: `sumEntriesPickup:sumEntries(typeCode: PICKUP)`,
    fields: [
      "deliveredAmount",
      "piecesCount",
      "collectedFees",
      "dueFees",
      "weight",
      "paymentAmount",
    ],
    variables: {},
  },
  {
    operation: `sumEntries`,
    fields: [
      "deliveredAmount",
      "paymentAmount",
    ],
    variables: {},
  },
];
export const PAYMENT_BY_ID_QUERY = (ALLOWED_CONCIERGE, ALLOWED_SHIPMENT, ALLOWED_PICKUP) => gqlb.query({
  operation: "payment",
  fields: [
    "id",
    "date",
    "code",
    "approved",
    "glApproved",
    "notes",
    {
      type: ["code"],
    },
    {
      customer: ["id", "name", "code", "address"],
    },
    {
      representative: ["id", "name", "active"],
    },
    {
      images: ["path"],
    },
    {
      operation: "branch",
      fields: ["id", "name"],
      variables: {},
    },
    {
      transactionType: ["id", "code", "name", { type: ["name", "code"] }],
    },
    {
      operation: "deliveryAgent",
      fields: ["id", "name", "code", "autoPayCommission", "pickupCommission"],
      variables: {},
    },
    {
      operation: "safe",
      fields: ["id", "name"],
      variables: {},
    },

    {
      operation: `entriesConcierge:entries(typeCode: CONCIERGE_REQUEST) @include(if: ${ALLOWED_CONCIERGE})`,
      fields: [
        {
          paginatorInfo: ["total"]
        },
      ],
      variables: {},
    },
    {
      operation: `entriesShipment:entries(typeCode: SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
      fields: [
        {
          paginatorInfo: ["total"]
        },
      ],
      variables: {},
    },
    {
      operation: `entriesPickup:entries(typeCode: PICKUP) @include(if: ${ALLOWED_PICKUP})`,
      fields: [
        {
          paginatorInfo: ["total"]
        },
      ],
      variables: {},
    },
    {
      operation: `sumEntriesShipment:sumEntries(typeCode: SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
      fields: [
        "deliveredAmount",
        "piecesCount",
        "collectedFees",
        "dueFees",
        "weight",
        "paymentAmount",

      ],
      variables: {},
    },
    {
      operation: `sumEntriesConcierge:sumEntries(typeCode: CONCIERGE_REQUEST) @include(if: ${ALLOWED_CONCIERGE})`,
      fields: [
        "deliveredAmount",
        "piecesCount",
        "collectedFees",
        "dueFees",
        "weight",
        "paymentAmount",
      ],
      variables: {},
    },
    {
      operation: `sumEntriesPickup:sumEntries(typeCode: PICKUP) @include(if: ${ALLOWED_PICKUP})`,
      fields: [
        "deliveredAmount",
        "piecesCount",
        "collectedFees",
        "dueFees",
        "weight",
        "paymentAmount",
      ],
      variables: {},
    },
    {
      operation: `sumEntries`,
      fields: [
        "deliveredAmount",
        "piecesCount",
        "collectedFees",
        "dueFees",
        "weight",
        "paymentAmount",
      ],
      variables: {},
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const PAYMENT_VIEW = (permission, ALLOWED_CONCIERGE, ALLOWED_SHIPMENT, ALLOWED_PICKUP) =>
  gqlb.query([
    {
      operation: "payment",
      fields: [
        "id",
        "date",
        "code",
        "approved",
        "glApproved",
        "notes",
        // "collectedFees",
        // "deliveredAmount",
        // "dueFees",
        "weight",
        // "piecesCount",
        // "paymentAmount",
        {
          type: ["code"],
        },
        {
          images: ["path"]
        },
        {
          createdBy: ["username", "id"],
        },
        {
          updatedBy: ["username", "id"],
        },
        {
          representative: ["id", "name", "active", "typeCode"],
        },
        {
          operation: `journalEntry @include(if: ${permission})`,
          fields: ["code", "id"],
          variables: {},
        },
        {
          customer: [
            "id",
            "code",
            "name",
            "mobile",
            "phone",
            "address",
            { zone: ["name"] },
            { subzone: ["name"] },
          ],
        },
        {
          operation: "branch",
          fields: ["id", "name"],
          variables: {},
        },
        {
          transactionType: ["id", "code", "name", { type: ["name", "code"] }],
        },
        {
          operation: "deliveryAgent",
          fields: ["id", "name", "code", "pickupCommission"],
          variables: {},
        },
        {
          operation: "safe",
          fields: ["id", "name"],
          variables: {},
        },

        {
          operation: `sumEntries`,
          fields: [
            "deliveredAmount",
            "piecesCount",
            "collectedFees",
            "dueFees",
            "weight",
            "paymentAmount",
          ],
          variables: {},
        },
        {
          operation: `entriesPickup:entries(typeCode: PICKUP) @include(if: ${ALLOWED_PICKUP})`,
          fields: [
            {
              paginatorInfo: ["total"]
            },
          ],
          variables: {},
        },
        {
          operation: `entriesConcierge:entries(typeCode: CONCIERGE_REQUEST) @include(if: ${ALLOWED_CONCIERGE})`,
          fields: [
            {
              paginatorInfo: ["total"]
            },
          ],
          variables: {},
        },
        {
          operation: `entriesShipment:entries(typeCode: SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
          fields: [
            {
              paginatorInfo: ["total"]
            },
          ],
          variables: {},
        },
        {
          operation: `sumEntriesShipment:sumEntries(typeCode: SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
          fields: [
            "deliveredAmount",
            "piecesCount",
            "collectedFees",
            "dueFees",
            "weight",
            "paymentAmount",
          ],
          variables: {},
        },
        {
          operation: `sumEntriesConcierge:sumEntries(typeCode: CONCIERGE_REQUEST)  @include(if: ${ALLOWED_CONCIERGE})`,
          fields: [
            "deliveredAmount",
            "piecesCount",
            "collectedFees",
            "dueFees",
            "weight",
            "paymentAmount",
          ],
          variables: {},
        },
        {
          operation: `sumEntriesPickup:sumEntries(typeCode: PICKUP) @include(if: ${ALLOWED_PICKUP})`,
          fields: [
            "deliveredAmount",
            "piecesCount",
            "collectedFees",
            "dueFees",
            "weight",
            "paymentAmount",
          ],
          variables: {},
        },

      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  ]);
export const PAYMENT_VIEW_CONCIERGE_DATA =
  gqlb.query(
    {
      operation: "payment",
      fields: [
        {
          operation: "entries",
          fields: [
            {
              operation: "paginatorInfo",
              fields: [
                "count",
                "currentPage",
                "total",
                "firstItem",
                "hasMorePages",
                "lastItem",
                "lastPage",
                "perPage",
              ],
              variables: {},
            },
            {
              operation: "data",
              fields: [
                "paidAmount",
                {
                  operation: "conciergeRequest",
                  fields: [
                    "id",
                    "code",
                    "accountNumber",
                    {
                      branch: ["id", "name"]
                    },
                    {
                      customer: ["id", "name"]
                    },
                  ],
                  variables: {}
                }],
              variables: {},
            },
          ],
          variables: {
            typeCode: {
              type: "PaymentEntryTypeCode",
            },
            first: {
              type: "Int",
            },
            page: {
              type: "Int",
            },
          },
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  );

export const PAYMENT_VIEW_PICKUP_DATA =
  gqlb.query(
    {
      operation: "payment",
      fields: [
        {
          operation: "entries",
          fields: [
            {
              operation: "paginatorInfo",
              fields: [
                "count",
                "currentPage",
                "total",
                "firstItem",
                "hasMorePages",
                "lastItem",
                "lastPage",
                "perPage",
              ],
              variables: {},
            },
            {
              operation: "data",
              fields: [
                "paidAmount",
                {
                  operation: "pickup",
                  fields: [
                    "id",
                    "code",
                    {
                      branch: ["id", "name"]
                    },
                    {
                      customer: ["id", "name"]
                    },
                    "notes",
                    "date"

                  ],
                  variables: {}
                }
              ],
              variables: {},
            },
          ],
          variables: {
            typeCode: {
              type: "PaymentEntryTypeCode",
            },
            first: {
              type: "Int",
            },
            page: {
              type: "Int",
            },
          },
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  );


export const GET_PAYMENT_TYPE = gqlb.query({
  operation: "payment",
  fields: [{
    type: ["code"],
  },],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});


export const PAYMENT_PRINT = gqlb.query([
  {
    operation: "payment",
    fields: paymentFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
    ],
    variables: {},
  },
]);

export const PAYMENT_VIEW_SHIPMENT_DATA = (shipmentQueryFields) =>
  gqlb.query([
    {
      operation: "payment",
      fields: [
        {
          operation: `entries`,
          fields: [
            {
              operation: `paginatorInfo`,
              fields: [
                "count",
                "currentPage",
                "total",
                "firstItem",
                "hasMorePages",
                "lastItem",
                "lastPage",
                "perPage"
              ],
              variables: {}
            },
            {
              operation: `data`,
              fields: [
                "paidAmount",
                {
                  operation: `shipment`,
                  fields: [
                    ...(shipmentQueryFields ? shipmentQueryFields : ["recipientName",
                      "recipientMobile",
                      "recipientPhone",
                      "description",
                      "recipientAddress",
                      "deliveredAmount",
                      "collectedFees",
                      "returningDueFees",
                      "piecesCount",
                      "weight",
                      "date",
                      "senderName",
                      "senderAddress",
                      "senderMobile",
                      "senderPhone",
                      "deliveredOrReturnedDate",
                      {
                        senderZone: ["id", "name"],
                      },
                      {
                        recipientZone: ["id", "name"],
                      },
                      {
                        senderSubzone: ["name"],
                      },
                      {
                        recipientSubzone: ["name"],
                      },
                      {
                        deliveryType: ["name"],
                      },
                      { returnType: ["code", "name"], },
                      {
                        paymentType: ["name"],
                      },
                      {
                        status: ["name"],
                      },
                      { type: ["name", "code"], }]),
                    "id",
                    "code",
                    "notes",
                    "refNumber",
                    {
                      originBranch: ["id", "name"],
                    },
                  ],
                  variables: {}
                }
              ],
              variables: {}
            }
          ],
          variables: {
            typeCode: {
              type: "PaymentEntryTypeCode",
            },
            first: {
              type: "Int",
            },
            page: {
              type: "Int",
            },
          },
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  ]);
export const SAVE_PAYMENT_ENTRIES_MUTATION = gqlb.mutation({
  operation: "createPaymentEntries",
  fields: [{
    payment: ["id"]
  }],
  variables: {
    input: {
      type: "PaymentEntriesInput",
      required: true,
    },
  },
});
export const DELETE_PAYMENT_ENTRIES_MUTATION = gqlb.mutation({
  operation: "deletePaymentEntries",
  fields: [],
  variables: {
    input: {
      type: "DeletePaymentEntriesInput",
      required: true,
    },
  },
});

export const SAVE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "savePayment",
  fields: paymentFields,
  variables: {
    input: {
      type: "PaymentInput",
      required: true,
    },
  },
});

export const DELETE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "deletePayment",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "approvePayment",
  fields: paymentFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DISAPPROVE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "disapprovePayment",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const GL_APPROVE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "glApprovePayment",
  fields: paymentFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const CUSTOMER_ID = gqlb.query({
  operation: "customer",
  fields: [
    "vodafoneCash",
    "bankAccount",
    {
      bank: ["id", "name"],
    },
    {
      paymentMethod: ["code", "name"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const paymentEntriesFieldsPrint = [
  "paidAmount",
  {
    operation: "conciergeRequest",
    fields: [
      "id",
      "code",
      "accountNumber",
      {
        branch: ["id", "name"]
      },
      {
        customer: ["id", "name"]
      },
    ],
    variables: {}
  },
  {
    operation: `shipment`,
    fields: [
      "recipientName",
      "recipientMobile",
      "recipientPhone",
      "description",
      "recipientAddress",
      "deliveredAmount",
      "collectedFees",
      "returningDueFees",
      "piecesCount",
      "weight",
      "date",
      "senderName",
      "senderAddress",
      "senderMobile",
      "senderPhone",
      "deliveredOrReturnedDate",
      {
        senderZone: ["id", "name"],
      },
      {
        recipientZone: ["id", "name"],
      },
      {
        senderSubzone: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        deliveryType: ["name"],
      },
      { returnType: ["code", "name"], },
      {
        paymentType: ["name"],
      },
      {
        status: ["name"],
      },
      { type: ["name", "code"], },
      "id",
      "code",
      "notes",
      "refNumber",
      {
        originBranch: ["id", "name"],
      },
    ],
    variables: {}
  },
  {
    operation: "pickup",
    fields: [
      "id",
      "code",
      {
        branch: ["id", "name"]
      },
      {
        customer: ["id", "name"]
      },
      "notes",
      "date"

    ],
    variables: {}
  }
]

// with print only
export const paymentAllEntries = {
  allEntries: paymentEntriesFieldsPrint
}


export const shipmentPayment = {
  DLVBY: [
    { key: "code", name: "code", label: "requestCode" },
    { name: "senderName", key: "senderName", label: "senderName" },
    { name: { status: ["name", "code"] }, key: "status", label: "shipmentStatus" },
    { name: "id", key: "paidAmount", label: "deservedAmount" },
    { name: "recipientName", key: "recipientName", label: "recipient" },
    { name: "recipientAddress", key: "recipientAddress", label: "recipientAddress" },
    { name: "deliveredOrReturnedDate", key: "deliveredOrReturnedDate", label: "deliveryCanclingDate" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: { deliveryType: ["name"], }, key: "deliveryType", label: "deliveryType" },
    { key: "description", name: "description", label: "description" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
  ],
  CUSTM: [
    { key: "code", name: "code", label: "requestCode" },
    { name: "date", key: "date", label: "date" },
    { name: "deliveredOrReturnedDate", key: "deliveredOrReturnedDate", label: "deliveryCanclingDate" },
    { name: "recipientName", key: "recipientName", label: "recipient" },
    { name: "recipientPhone", key: "recipientPhone", label: "recipientPhone" },
    { name: "senderName", key: "senderName", label: "senderName" },
    { name: "senderPhone", key: "senderPhone", label: "senderPhone" },
    { name: "senderAddress", key: "senderAddress", label: "senderAddress" },
    { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
    { name: { returnType: ["code", "name"], }, key: "returnType", label: "returnType" },
    { name: { deliveryType: ["name"], }, key: "deliveryType", label: "deliveryType" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: { paymentType: ["name"] }, key: "paymentType", label: "paymentType" },
    { name: { status: ["name", "code"] }, key: "status", label: "shipmentStatus" },
    { name: "weight", key: "weight", label: "weight" },
    { name: "piecesCount", key: "piecesCount", label: "pieceCount" },
    { name: "deliveredAmount", key: "deliveredAmount", label: "deliveredAmount" },
    { name: "id", key: "customerDueAmount", label: "customerDueAmount" },
    { name: "collectedFees", key: "collectedFees", label: "collectedFees" },
    { name: "returningDueFees", key: "returningDueFees", label: "returningDueFees" },
    { name: "notes", key: "note", label: "notes" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
    { name: "", key: "barcode", label: "barcode" },
  ],
}

export const initialData = {
  DLVBY: [
    "code",
    "deliveredOrReturnedDate",
    "senderName",
    "recipientName",
    "recipientZone",
    "recipientAddress",
    "status",
    "deliveryType",
    "paidAmount",
  ],
  CUSTM: [
    "code",
    "date",
    "deliveredOrReturnedDate",
    "recipientName",
    "recipientPhone",
    "recipientZone",
    "paymentType",
    "status",
    "weight",
    "piecesCount",
    "deliveredAmount",
    "collectedFees",
    "returningDueFees",
    "customerDueAmount",
  ],



}