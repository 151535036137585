import * as gqlb from "gql-query-builder";

export const LIST_SACKS = (id) => gqlb.query({
  operation: "listSacks",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "name",
        "active",
        "quantity",
        id ? `availableQuantity(branchId:${id})` : "availableQuantity"
      ],
      variables: {},
    },
  ],

  variables: {
    input: {
      type: "ListSacksFilterInput",
      required: true
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
      required: true
    },
  },
});

export const SAVE_SACK = gqlb.mutation({
  operation: "saveSack",
  fields: [
    "id",
    "active",
    "quantity",

  ],
  variables: {
    input: {
      type: "SackInput",
      required: true,
    },
  },
});

export const SACK_ID = gqlb.query([
  {
    operation: "sack",
    fields: [
      "id",
      "name",
      "active",
      "quantity",
      "createdAt",
      "updatedAt",
      // "availableQuantity(branchId:3)"
      // {
      //   operation: "availableQuantity(branchId:)",
      //   fields: [],
      //   variables: {},
      // }
      // ,
      {
        createdBy: ["id", "username"]
      },
      {
        updatedBy: ["id", "username"]
      },
      {
        sackBranches: ["quantity", { branch: ["id", "name"] }]
      },

    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },

]);
export const SAVE_BRANCH = gqlb.mutation({
  operation: "saveSackBranches",
  fields: [{ branch: ["id", "code", "name"] }, "quantity"],
  variables: {
    input: {
      type: "SackBranchInput",
      required: true,
    },
  },
});

export const DELETE_SACK_BRANCHES = gqlb.mutation({
  operation: "deleteSackBranches",
  fields: [],
  variables: {
    input: {
      type: "DeleteSackBranchInput",
      required: true,
    },
  },
});