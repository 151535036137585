import * as gqlb from "gql-query-builder";
export const LIST_PICKUPS = gqlb.query([{
  operation: "listPickups",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "date",
        "shipmentsCount",
        "receivedCount",
        "pickedCount",
        "deliveryAgentNotes",
        "notes",
        { acceptance: ["code", "name"] },
        {
          branch: ["id", "name"],
        },
        {
          deliveryAgent: ["id", "name"],
        },
        {
          transactionType: ["id", "name"],
        },
        {
          customer: ["id", "name"],
        },
        {
          status: ["code", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListPickupsFilterInput",
    },
  },
}, {
  operation: "sumPickups",
  fields: [
    "pickedCount",
    "shipmentsCount",
    "receivedCount"
  ],
  variables: {
    input: {
      type: "ListPickupsFilterInput",
    },
  }
}]);

// this pickup without customer
const pickup = [
  "id",
  "code",
  "timeFrom",
  "timeTo",
  "notes",
  "shipmentsCount",
  "receivedCount",
  "pickedCount",
  "editable",
  "deletable",
  "createdAt",
  "deliveryAgentNotes",
  "date",
  { acceptance: ["code", "name"] },
  { status: ["code", "name"] },
  { branch: ["id", "name"] },
  { deliveryAgent: ["id", "name"] },
  { vehicle: ["code", "name"] },
  { transactionType: ["id", "name"] },
  { createdBy: ["id", "username"] },
  { updatedBy: ["id", "username"] },
  {
    entries: [
      {
        operation: "...on Shipment",
        fields: [
          "__typename",
          "id",
          "code",
          "date",
          "recipientName",
          "description",
          "piecesCount",
          "recipientAddress",
          "amount",
          "totalAmount",
          "allDueFees",
          {
            recipientZone: ["id", "name"],
          },
          {
            warehouseSection: ["id", "name"],
          },
          {
            recipientSubzone: ["id", "name"],
          },
        ],
        variables: {},
      },
      {
        operation: "...on Barcode",
        fields: [
          "__typename",
          "id",
          "code",
        ],
        variables: {},
      }
    ],
  },
];

export const PICKUP_VIEW = (paymentPermission) => gqlb.query({
  operation: "pickup",
  fields: [
    "id",
    "code",
    "timeFrom",
    "timeTo",
    "notes",
    "shipmentsCount",
    "receivedCount",
    "pickedCount",
    "editable",
    "deletable",
    "createdAt",
    "deliveryAgentNotes",
    "date",
    { acceptance: ["code", "name"] },
    { status: ["code", "name"] },
    { branch: ["id", "name"] },
    { deliveryAgent: ["id", "name"] },
    { vehicle: ["code", "name"] },
    { transactionType: ["id", "name"] },
    { createdBy: ["id", "username"] },
    { updatedBy: ["id", "username"] },
    {
      customer: [
        "id",
        "name",
        "address",
        { zone: ["id", "name"] },
        { subzone: ["id", "name"] },
      ],
    },
    {
      operation: `payment @include(if:${paymentPermission})`,
      fields: ["id", "code"],
      variables: {},
    },
    {
      entries: [
        {
          operation: "...on Shipment",
          fields: [
            "__typename",
            "id",
            "code",
            "date",
            "recipientName",
            "description",
            "piecesCount",
            "recipientAddress",
            "amount",
            "totalAmount",
            "allDueFees",
            {
              recipientZone: ["id", "name"],
            },
            {
              warehouseSection: ["id", "name"],
            },
            {
              recipientSubzone: ["id", "name"],
            },
          ],
          variables: {},
        },
        {
          operation: "...on Barcode",
          fields: [
            "__typename",
            "id",
            "code",
          ],
          variables: {},
        }
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

// this for adding customer for pickup array
pickup.push({ customer: ["id", "name"] });
export const PICKUP = gqlb.query({
  operation: "pickup",
  fields: pickup,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_PICKUP_MUTATION = gqlb.mutation({
  operation: "savePickup",
  fields: pickup,
  variables: {
    input: {
      type: "PickupInput",
      required: true,
    },
  },
});

export const DELETE_PICKUP_MUTATION = gqlb.mutation({
  operation: "deletePickup",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const UPDATE_PICKUP_STATUS = gqlb.mutation({
  operation: "updatePickupStatus",
  fields: pickup,
  variables: {
    input: {
      type: "UpdatePickupStatusInput",
      required: true,
    },
  },
});

export const DELETE_SHIPMENT = gqlb.mutation({
  operation: "deleteShipment",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
