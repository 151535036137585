import { Paper, TableRow, TableHead, Table, TableBody } from "@mui/material"
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell"
import CellLink from "../../HOC/CustomComponents/CellLink";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
export const ProductTableForm = (props) => {
  const {
    classes,
    trackingData,
    productList,
    inProduct
  } = props
  const { t } = useTranslation();
  return (
    <Grid xs={12} justifyContent="center">
      <Paper container className={classes.paper} component={Grid}>
        <TableFixedHeaderWraper>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <TableHead>
              <TableRow>
                <FixedTableCell>#</FixedTableCell>
                <FixedTableCell>{t("name")}</FixedTableCell>
                <FixedTableCell>{t("quantity")}</FixedTableCell>
                {(["DTR", "DTRUC", "DTRCP", "UPKBL", "UPKBD"].includes(trackingData?.status?.code) && !inProduct) && (
                  <FixedTableCell>{t("delivered")}</FixedTableCell>
                )}
                {(["DTR", "DTRUC", "DTRCP", "UPKBL", "UPKBD"].includes(trackingData?.status?.code) && !inProduct) && (
                  <FixedTableCell>{t("returned")}</FixedTableCell>
                )}
                {(!inProduct || trackingData?.type?.code === "RTS") && (<FixedTableCell>{t("price")}</FixedTableCell>)}
                {(!inProduct || trackingData?.type?.code === "RTS") && (<FixedTableCell>{t("weight")}</FixedTableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {productList.map((product, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  // className={classes.tableBodyRow}
                  >
                    <FixedTableCell>{index + 1}</FixedTableCell>
                    <CellLink
                      align="left"
                      pathname={`/admin/products/${product?.product?.id}`}
                    >
                      {product.product.name}
                    </CellLink>
                    <FixedTableCell>{product.quantity}</FixedTableCell>
                    {console.log(trackingData?.status?.code)}
                    {(["DTR", "DTRUC", "DTRCP", "UPKBL", "UPKBD"].includes(trackingData?.status?.code) && !inProduct) && (
                      <FixedTableCell className={clsx(classes.delivered)}>
                        {product?.delivered}
                      </FixedTableCell>
                    )}
                    {(["DTR", "DTRUC", "DTRCP", "UPKBL", "UPKBD"].includes(trackingData?.status?.code) && !inProduct) && (
                      <FixedTableCell className={clsx(classes.returned)}>
                        {product?.returned ? product?.returned : 0}
                      </FixedTableCell>
                    )}
                    {(!inProduct || trackingData?.type?.code === "RTS") && (<FixedTableCell>{product.price}</FixedTableCell>)}
                    {(!inProduct || trackingData?.type?.code === "RTS") && (<FixedTableCell>
                      {product.product.weight}
                    </FixedTableCell>)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
      </Paper>
    </Grid>
  )
}



